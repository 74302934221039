import React, { Component } from "react";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { RouterProps } from "react-router";
import { Redirect } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
//@ts-ignore
import { gapi } from "gapi-script";
export const configJSON = require("../config");
// @ts-ignore
import { baseURL } from '../../../../framework/src/config.js';
import { Select } from "antd";
const axios = require("axios");
const { Option } = Select;
import {
  Row,
  Col,
  Button,
  Modal,
  message
} from "antd";
import { isEmpty } from "lodash";
import { removeStorageData, setStorageData, getStorageData } from "../../../../framework/src/Utilities";
import { appId, api_Token } from "../../../SelfdestructMessaging/src/ChatScreenController.web";
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
export interface AllData {
  contact: [
    {
      name: string | null;
      contact: string | null;

    },
  ];
  information: string;
}
interface S {
  isSubmitting: boolean;
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  contact: any[];
  general: string;
  token: string | null;
  pre_data: any;
  all_data: AllData;
  msg_id: string;
  isImportContactVisible: boolean;
  employment_type: string | null;
  isErrorModalShow: boolean;
  errorContent: any;
  isModalVisible: any;
  modalIndex: number;
  modalString: string;
  prev_data: any;
  isSuccess: boolean;
  redirect: boolean;
  profileId: any;
  editNum: boolean;
  contactNumberError: any[];
  contactNameError: any[];
  disableNextButton: boolean
}
interface SS {
  id: any;
}
const contact_props = ["name", "email", "tel", "address", "icon"];
const opts = { multiple: true };
var clientId =
  "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com"; // clientId received from API
var apiKey = "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4"; // client secret key from API
var scopes = "https://www.google.com/m8/feeds"; // enables read/write access to contacts
const gapiClientId =
  "480277687056-0hj34so227lmagot48vc6o6gk1a1re37.apps.googleusercontent.com";
const gapiApiKey = "UiF1Vp3RJXh6ZuVAjJeMHNrQ";
var SCOPES = "https://www.googleapis.com/auth/contacts.readonly";
// Customizable Area Start
export default class EditGeneralInformationControllers extends BlockComponent<
  Props,
  S,
  SS
> {
  createAccountApiCallId: any;
  GetProfielInformationId: any;
  postUploadProfileImageApiCallId: any;
  contact_formref = React.createRef<FormInstance>();
  general_formref = React.createRef<FormInstance>();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      contactNameError: [],
      contactNumberError: [],
      editNum: false,
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      data: {},
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      pre_data: localStorage.getItem("data")
        ? localStorage.getItem("data")
        : [],
      contact: [],
      general: "",
      msg_id: "",
      isImportContactVisible: false,
      employment_type: localStorage.getItem("employment_type")
        ? localStorage.getItem("employment_type")
        : "",
      isErrorModalShow: false,
      errorContent: {},
      isModalVisible: false,
      modalIndex: 0,
      modalString: '',
      prev_data: {},
      isSuccess: false,
      profileId: '',
      isSubmitting: false,
      all_data: {
        contact: [
          {
            name: null,
            contact: null
          },
        ],
        information: ''
      },
      disableNextButton: false,

      redirect: false
    };
    // this.alertModal = this.alertModal.bind(this);
  }
  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    console.log("hello ========== ", this.props.history.location.state);
    let contactDetails: any = this.props.history.location.state;
    if (contactDetails && contactDetails != null) {
      let newData = Object.assign(
        {},
        { experience: contactDetails && contactDetails.experience },
        { profile: contactDetails && contactDetails.profile },
        { graduation: contactDetails && contactDetails.graduation },
        { language: contactDetails && contactDetails.language },
        { certificate: contactDetails && contactDetails.certificate }
      );
      this.setState({
        prev_data: newData,
      });
      if (contactDetails && contactDetails.contact) {
        // console.log(contactDetails.contact,'jhdbbwefubuwebfubwubehujebwfuhb')
        this.setState({
          all_data: contactDetails && contactDetails.contact,
        });
      }
    }
    const profileInfo = await getStorageData('userProfile');
    if (profileInfo) {
      this.setState({ profileId: profileInfo?.attributes?.profile.data?.attributes?.account_id });
    } else {
      const profileInfo = await getStorageData('loginUser');
      if (profileInfo) {
        this.setState({ profileId: profileInfo?.id });
      }
    }

    this.setState({ loading: false });
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  // =================== @@handleChange =========================
  handleInputChange = (e: any, index: any, data: string) => {
    const { name, value } = e.target;
    if (data === 'information') {
      const list: any = [...this.state.all_data.contact];
      list[index][name] = value;
      this.setState(list);
    } else {
      console.log('error while handiling data');
    }
  };

  handleAddContact = (values: any) => {
    let all_data = this.state.all_data;
    if (all_data.contact) {
      all_data.contact.push(values);
    } else {
      Object.assign(all_data, { contact: [values] });
    }
    console.log(
      '@@@ contact data=====',
      values,
      '@@@ contact data=====',
      all_data
    ),
      this.setState({ all_data: all_data }, () => {
        this.contact_formref.current!.resetFields();
      });
  };
  handleGeneralInfo = (data: any) => {
    console.log('working =>', data)
    this.setState((state) => ((state.all_data.information = data.information), state), () => {
      console.log('call back state', this.state.all_data)
      let newData = Object.assign(
        {},
        { experience: this.state.prev_data && this.state.prev_data.experience },
        { profile: this.state.prev_data && this.state.prev_data.profile },
        { graduation: this.state.prev_data && this.state.prev_data.graduation },
        { language: this.state.prev_data && this.state.prev_data.language },
        { certificate: this.state.prev_data && this.state.prev_data.certificate },
        { contact: this.state.all_data }
      );
      console.log('push data', newData);
      // this.props.history.replace({
      //   state: newData,
      // });
      // this.props.history.push({
      //   pathname: "/setup/profile/personal/about",
      //   state: newData,
      // });
    });

  };

  //=================== @@show modal =========================
  showModal = (index: number, data: string) => {
    this.setState({
      modalIndex: index,
      modalString: data,
      isModalVisible: true,
    });
  };

  // ====================@@delete all_data values=======================
  deleteData = (value: any) => {
    if (
      this.state.all_data.contact != null &&
      this.state.modalString === 'Reference'
    ) {
      const list: any = [...this.state.all_data.contact];
      list.splice(value, 1);
      this.setState((state) => ((state.all_data.contact = list), state));
    } else {
      console.log('error while deleting');
    }
    this.setState({
      isModalVisible: false,
    });
  };


  validateOnSubmit = () => {
    console.log(this.state.all_data);
    // if (this.contact_formref && this.contact_formref?.current?.getFieldValue("name") !== '') {
    //   this.contact_formref && this.contact_formref.current?.submit();
    // } else {
    //   this.contact_formref.current?.resetFields()
    // }
    // if (this.general_formref && this.general_formref?.current?.getFieldValue("information") !== '') {
    //   this.general_formref && this.general_formref.current?.submit();
    // }
    // if (this.general_formref && this.general_formref?.current?.getFieldValue("information") !== '') {
    //   console.log('information is checked')
    //   this.general_formref && this.general_formref.current?.submit();
    // }
    // this.setState({ isSuccess: true });
    const awardError = this.state.contactNameError?.filter(item => item?.error?.length > 10)
    const acheivementError = this.state.contactNumberError?.filter(item => item?.error?.length > 10)
    if (awardError?.length > 0 || acheivementError?.length > 0) {
      message.error("Please Enter valid details of contact name and number", 1.4)
      this.setState({
        disableNextButton: true
      })
      setTimeout(() => {
        this.setState({
          disableNextButton: false
        })
      }, 1450)
    } else {
      // setTimeout(()=>{

      // },1500)
      if (
        this.state.all_data?.information?.length === 0 || this.state.all_data?.information === null) {
        setTimeout(() => {
          this.handleSubmit()
        }, 500)
      } else {
        if (this.state.all_data?.information?.length >= 15) {
          setTimeout(() => {
            this.handleSubmit()
          }, 500)
        } else {
          // alert("General Information take minimum 15 character")
        }
      }

    }
  }
  handleSubmit = async () => {

    //  this.alertModal();

    // this.addPersonalProfileData(all_data);
    this.addPersonalProfileData();
    // }
  };
  handlePrevious = () => {
    // if (this.general_formref && this.general_formref?.current?.getFieldValue("information") !== '') {
    //   console.log('information is checked')
    //   this.general_formref && this.general_formref.current?.submit();
    // } else {
    let all_data = this.state.all_data;
    let newData = Object.assign(
      {},
      { profile: this.state.prev_data && this.state.prev_data.profile },
      { experience: this.state.prev_data && this.state.prev_data.experience },
      { graduation: this.state.prev_data && this.state.prev_data.graduation },
      { certificate: this.state.prev_data && this.state.prev_data.certificate },
      { language: this.state.prev_data && this.state.prev_data.language },
      { contact: this.state.prev_data?.contact ? this.state.prev_data.contact : all_data }
    );
    this.props.history.replace({
      state: newData,
    });
    this.props.history.push({
      pathname: "/edit/profile/personal/about",
      state: newData,
    });
    // }
  }
  readContacts() {
    //@ts-ignore
    var api = navigator.contacts || navigator.mozContacts;

    if (api && !!api.select) {
      // new Chrome API
      api
        .select(["name", "email"], { multiple: true })
        .then(function (contacts: any) {
          // console.log("Found " + contacts.length + " contacts.");
          if (contacts.length) {
            console.log(
              "First contact: " +
              contacts[0].name +
              " (" +
              contacts[0].email +
              ")"
            );
          }
        })
        .catch(function (err: any) {
          console.log("Fetching contacts failed: " + err.name);
        });
    } else if (api && !!api.find) {
      // old Firefox OS API
      var criteria = {
        sortBy: "familyName",
        sortOrder: "ascending",
      };

      api
        .find(criteria)
        .then(function (contacts: any) {
          console.log("Found " + contacts.length + " contacts.");
          if (contacts.length) {
            console.log(
              "First contact: " +
              contacts[0].givenName[0] +
              " " +
              contacts[0].familyName[0]
            );
          }
        })
        .catch(function (err: any) {
          console.log("Fetching contacts failed: " + err.name);
        });
    } else {
      console.log("Contacts API not supported.");
    }
  }

  async getContacts() {
    //@ts-ignore
    if ("contacts" in navigator && "select" in navigator.contacts) {
      try {
        //@ts-ignore
        const contacts = await navigator.contacts.select(["name", "tel"], {
          multiple: true,
        });

        console.log(
          "Your first contact: " + contacts[0].name + " " + contacts[0].tel
        );
      } catch (e) {
        console.log("Unexpected error happened in Contact Picker API", e);
      }
    } else {
      console.log("Your browser doesn't support Contact Picker API");
    }
  }
  contactsTemp() {
    gapi.client.setApiKey(gapiApiKey); //sets apiKey for application
    window.stop();
    this.checkAuth();
  }
  checkAuth() {
    gapi.auth.authorize(
      {
        //authorizes the client using api key fields and calls handleAuthResult method
        client_id: gapiClientId,
        scope: scopes,
        immediate: false,
      },
      this.handleAuthResult
    );
  }

  handleAuthResult(authResult: any) {
    window.stop();
    if (authResult && !authResult.error) {
      //if no authorization error, then it will show the login popup
      axios.get(
        "https://www.google.com/m8/feeds/contacts/default/full?alt=json&access_token=" +
        authResult.access_token +
        "&max-results=700&v=3.0",
        function (response: any) {
          //Handle Response as per requirement
          window.stop();
        }
      );
    }
  }

  // BEGIN-NOSCAN
  addPersonalProfileData = async () => {
    this.setState({ isSubmitting: true })
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    console.log("data", this.state.prev_data);
    let allGraduations = this.state.prev_data?.graduation && [
      ...this.state.prev_data?.graduation?.post,
      ...this.state.prev_data?.graduation?.graduation,
      ...this.state.prev_data?.graduation?.srSecondary,
    ];
    let filterGraduationData = allGraduations && allGraduations.filter((item: any) => {
      if (item.year || item.score || item.degree || item.university || item.stream || item.board) {
        return item
      }
    })
    // console.log(filterGraduationData)
    // console.log(allGraduations);
    let updated_data = Object.assign({},
      // {information:this.state.all_data.information},
      {
        contact_references: this.state?.all_data?.contact[0]?.name != null
          && this.state?.all_data?.contact[0]?.name != ""
          ? this.state?.all_data?.contact : []
      },
      { description: this.state.all_data?.information },
      this.state.prev_data?.profile,
      { experiences: this.state.prev_data?.experience },
      { graduations: filterGraduationData?.length === 0 ? null : filterGraduationData === null ? null : filterGraduationData },
      {
        certifications: this.state.prev_data?.certificate?.certificate?.filter((item: any) =>
          item.name !== null &&
          item.name !== "" &&
          item.name !== undefined &&
          item.description !== null &&
          item.description !== "" &&
          item.description !== undefined
        )
      },
      { awards: this.state.prev_data?.certificate?.award },
      { achievements: this.state.prev_data?.certificate?.acheivement },
      {
        patents: this.state.prev_data?.language?.patents?.filter((item: any) => item.name !== null &&
          item.name !== "" &&
          item.name !== undefined &&
          item.description !== null &&
          item.description !== "" &&
          item.description !== undefined)
      },
      {
        interests: this.state.prev_data?.language?.other?.filter((item: any) => item.name !== null &&
          item.name !== "" &&
          item.name !== undefined)
      },
      { languages: this.state.prev_data?.language?.language === undefined ? null : this.state.prev_data?.language?.language });

    console.log("update data", updated_data.contact_references)

    if (this.state.prev_data?.profile?.picFile && this.state.prev_data?.profile?.profileUrl?.length > 0) {
      await this.uploadProfileImage(this.state.prev_data?.profile?.picFile);
    }
    delete updated_data["profileUrl"];
    delete updated_data["primary_email"];
    delete updated_data["picFile"];

    // validation
    if (
      updated_data.contact_references &&
      updated_data.contact_references.length != 0 &&
      (!updated_data.contact_references[0])

    ) {
      // if (!updated_data.contact_references[0].name) {
      delete updated_data.contact_references;
    }

    const json_data = {
      data: { attributes: updated_data },
    };

    // // ===============================================
    // if (this.state.prev_data?.graduation) {
    //   let allGraduations = [
    //     ...json_data.data.attributes.graduations,
    //     ...json_data.data.attributes.post_graduations,
    //     ...json_data.data.attributes.senior_secondary,
    //   ];
    //   if (json_data.data.attributes.post_graduations) {
    //     let newArr: any = [];
    //     json_data.data.attributes.post_graduations.forEach((item: any) => {
    //       let itemHere = item;
    //       itemHere.type = "post_graduations";
    //       newArr.push(itemHere);
    //     });
    //     json_data.data.attributes.post_graduations = newArr;
    //   }

    //   if (json_data.data.attributes.senior_secondary) {
    //     let newArr: any = [];
    //     json_data.data.attributes.senior_secondary.forEach((item: any) => {
    //       let itemHere = item;
    //       itemHere.type = "senior_secondary";
    //       newArr.push(itemHere);
    //     });
    //     json_data.data.attributes.senior_secondary = newArr;
    //   }

    //   // console.log("allGraduations = ", allGraduations);
    //   if (!isEmpty(allGraduations)) {
    //     allGraduations.filter(
    //       (item: any) =>
    //         item.degree && item.score && item.university && item.year
    //     );
    //   }
    //   json_data.data.attributes.graduations = [];
    //   json_data.data.attributes.graduations = allGraduations;
    //   if (json_data.data.attributes.hasOwnProperty("post_graduations"))
    //     delete json_data.data.attributes.post_graduations;
    //   if (json_data.data.attributes.hasOwnProperty("senior_secondary"))
    //     delete json_data.data.attributes.senior_secondary;
    // }

    // if (updated_data.graduations && !updated_data.graduations[0]?.year) {
    //   delete updated_data.graduations;
    // }

    const httpBody = json_data;

    // console.log(httpBody, 'httpBody')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addPersonalProfileAPIEndPoint}/${this.state?.profileId || localStorage.getItem('account_id')}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "put"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      msg_id: requestMessage.id,
    });
  };
  // END-NOSCAN
  // Edit User for chat Module
  EditUser = async (UpdateuserId?: string, nickname?: string, profile_url?: string, designation?: any) => {
    const formData = new FormData();
    const formMetaData = new FormData();
    if (nickname) {
      formData.append('nickname', nickname);
    }
    if (profile_url) {
      formData.append('profile_url', profile_url);
    }
    if (designation) {
      const metadata = { designation }
      formMetaData.append("metadata", JSON.stringify(metadata));
    }

    await fetch("https://api-" + appId + ".sendbird.com/v3/users/" + UpdateuserId,
      {
        method: 'PUT',
        body: formData,
        headers: {
          "Accept": "application/json",
          "Api-Token": api_Token,
          Authorization:
            "Bearer " +
            api_Token
        }
      },
    ).then((data) => data.json()).then((friendRes) => {
      // console.log(friendRes)
    })
    await axios(
      {
        url: "https://api-" + appId + ".sendbird.com/v3/users/" + UpdateuserId + "/metadata",
        method: 'PUT',
        data: {
          "metadata": { "designation": designation }
        },
        headers: {
          "Accept": "application/json",
          "Api-Token": api_Token,
          Authorization:
            "Bearer " +
            api_Token
        }
      }
    ).then((data: any) => console.log(data)).catch((error: any) => { })

  }
  // get user Profile
  getProfileUser = async () => {
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_profile/profiles`,
        method: "get",
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        }
      });
      // console.log(response)
      if (response) {
        const loginUser = await getStorageData('loginUser')
        const setData = {
          ...loginUser,
          name: response?.data?.data?.attributes?.profile?.data?.attributes?.full_name,
          profile_pic: response?.data?.data?.attributes?.profile?.data?.attributes?.photo
        }
        const designation = response?.data?.data?.attributes?.profile?.data?.attributes.domain_skills.join(",")
        console.log(response?.data, designation, "asdklajdsklga")
        await this.EditUser((setData.id).toString(), setData?.name, setData?.profile_pic, designation)
        await setStorageData("loginUser", setData)
        setStorageData("userProfile", response?.data?.data);
        setStorageData("accountData", response?.data?.data);
        // if (!this.state.isErrorModalShow) {
        //   this.props.history.push("/MyPersonalProfile/Profile");
        // }
      }
      // this.setState({ communityList: response.data.communities });
    } catch (error) {
      console.error(error, 'error while calling all community');
    }
  }

  // receive
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        console.log(responseJson);
        if (apiRequestCallId === this.GetProfielInformationId) {
          setStorageData("userProfile", responseJson.data);
        }
        if (apiRequestCallId == this.createAccountApiCallId) {
          // this.setState({
          //   isSubmitting: false
          // })
          if (responseJson && responseJson?.data?.type !== 'error' && !responseJson.errors && !responseJson.error) {
            // this.getProfileUser();
            // console.log(responseJson, 'responseJson__')
            // await setStorageData('userProfile', responseJson?.data)
            await setStorageData('userProfile', responseJson?.data?.attributes)
            await removeStorageData("signUpUserResponse");
            await setStorageData("signUpUserID", { id: responseJson?.data?.attributes?.account_id });
            await this.getProfileUser();
            this.setState({
              isErrorModalShow: true,
              isSubmitting: false,
              errorContent: {
                title: "Success",
                isError: false,
                body: "Personal Profile is Updated",
              },
            });
            this.GetProfileInformation();
            // console.log('testdata__', responseJson)
          }
        } else
          if (responseJson && responseJson.errors) {
            if (responseJson && responseJson.errors[0].profile) {
              this.setState({
                isErrorModalShow: true,
                errorContent: {
                  title: "Error",
                  isError: true,
                  isSubmitting: false,
                  body: responseJson.errors[0].profile,
                },
              });
            }
          } else
            if (responseJson.data && responseJson.data?.attributes && responseJson.data?.attributes?.errors) {
              if (responseJson.data?.attributes?.errors.name) {
                this.setState({
                  isErrorModalShow: true,
                  isSubmitting: false,
                  errorContent: {
                    title: "Error",
                    isError: true,
                    body: responseJson.data?.attributes?.errors.name,
                  },
                });
              } else
                if (responseJson.data?.attributes?.errors.end_date) {
                  this.setState({
                    isErrorModalShow: true,
                    isSubmitting: false,
                    errorContent: {
                      title: "Error",
                      isError: true,
                      body: responseJson.data?.attributes?.errors.end_date,
                    },
                  });
                } else
                  if (responseJson.data?.attributes?.errors.experiences) {
                    this.setState({
                      isErrorModalShow: true,
                      isSubmitting: false,
                      errorContent: {
                        title: "Error",
                        isError: true,
                        body: responseJson.data?.attributes?.errors.experiences,
                      },
                    });
                    // console.log(responseJson.data?.attributes?.errors.experiences)
                  } else
                    if (responseJson.data?.attributes?.errors.date) {
                      this.setState({
                        isErrorModalShow: true,
                        isSubmitting: false,
                        errorContent: {
                          title: "Error",
                          isError: true,
                          body: responseJson.data?.attributes?.errors.date,
                        },
                      });
                    }
                    else {
                      this.setState({
                        isErrorModalShow: true,
                        isSubmitting: false,
                        errorContent: {
                          title: "Error",
                          isError: true,
                          body: "Profile creation failed.",
                        },
                      });
                    }
            } else {
              // this.setState({
              //   isSubmitting: false,
              // })
              console.log("something went wrong");
            }
      } else {
        console.log("hello");
      }
    }
  }

  // BEGIN-NOSCAN
  // fetch google contacts 
  getUserContacts = async (data: any) => {
    const host = "https://people.googleapis.com";
    const endPoint =
      "/v1/people/me/connections?personFields=names,phoneNumbers";

    console.log("loading contact");
    try {
      const request = await fetch(`${host}${endPoint}`, {
        method: 'get',
        headers: {
          Authorization: `${data.token_type} ${data.access_token}`,
          Accept: 'application/json',
        }
      }
      ).then(function (response) {

        return response.json();
      }).then((data) => {
        this.setState({
          isImportContactVisible: false
        })
        if (data?.connections?.length > 0) {
          let newValues: any = []
          data?.connections?.map((c: any) => {
            console.log("contact", c?.names ? c.names[0].displayName : 'Unknown', c?.phoneNumbers ? c?.phoneNumbers : "000")
            if (c?.names != undefined && c?.names[0])
              newValues.push({
                name: c?.names ? c.names[0].displayName : 'Unknown', number: c?.phoneNumbers ?
                  c?.phoneNumbers[0]?.canonicalForm ? c.phoneNumbers[0].canonicalForm : c.phoneNumbers[0].value :
                  ''
              })

          });

          // console.log(newValues, 'new data')
          this.setState((state) => ((state.all_data.contact = newValues), state));
        } else {
          message.error("contacts are unavailable")
        }
      });
      console.log("Loading completed");
    } catch (error) {
      console.log(error)
      message.error("something went wrong")
      this.setState({
        isImportContactVisible: false
      })
    }
  }
  // END-NOSCAN
  // google auth 
  onLoginSuccess = (res: any) => {
    console.log('Login Success:', res.profileObj);
    console.log('Login Success:', res);

    if (typeof res?.profileObj?.email === typeof 'test') {
      this.getUserContacts(res.tokenObj);
    }
  };


  onLoginFailure = (res: any) => {
    console.log('Login Failed:', res);
    this.setState({
      isImportContactVisible: false
    })
  };

  uploadProfileImage = (img: any) => {
    const header = {
      // "Content-Type": "multipart/form-data",
      token: this.state.token,
    };

    console.log("this.state.profileUrl", img);

    const formData = new FormData();
    formData.append("profile_pic", img);

    console.log("@@@ upload==", formData);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postUploadProfileImageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/upload_profile_pic"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    console.log(
      "@@@ Request ===========",
      requestMessage,
      this.postUploadProfileImageApiCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async GetProfileInformation() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetProfielInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetProfileInfoEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SearchAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("GetProfileInfo--->" + JSON.stringify(requestMessage));
    return true;
  }

}
// Customizable Area End