import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import confirm from "../../alert/src/alert.web";
import AlertError from "../../alert/src/alertError.web";
export const configJSON = require("./config");
import { RouterProps } from "react-router";
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  isErrorModalShow: boolean;
  errorContent: any;
  resetPasswordBannerImageId: string | null;
  resetPasswordBannerImage: string;
}
interface SS {
  id: any;
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAccountApiCallId: any;
  getResetPasswordBannerImageApiCallId = '';
  formref = React.createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: false,
      isRegistration: true,
      data: this.props.history ? this.props.history.location.state : "",
      isErrorModalShow: false,
      errorContent: {},
      resetPasswordBannerImageId: '',
      resetPasswordBannerImage: ''
    };
  }
  async componentDidMount() {
    this.getResetPasswordBannerImage();
  }
  handlePassword = (data: any) => {
    console.log(data);
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
    };
    console.log(this.state.data);
    let updated_data = {};
    Object.assign(updated_data, { token: this.state.data.messages[0].token });
    Object.assign(updated_data, { new_password: data.new_password });
    const json_data = {
      data: updated_data,
    };
    const httpBody = json_data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    console.log("hello");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createAccountApiCallId) {
          console.log(responseJson);
          this.setState({ loading: false });
          if (responseJson.data) {
            this.props.history.push("/reset/password/success");
          } else if (responseJson.errors) {
            console.log(responseJson.errors[0].password);
            if (
              responseJson.errors &&
              responseJson.errors[0]?.password
            ){
              this.setState({
                isErrorModalShow: true,
                errorContent: {
                  title: "error",
                  isError: true,
                  body: responseJson.errors[0].password,
                },
              });
            }else if(responseJson.errors[0].otp){
              this.setState({
                isErrorModalShow: true,
                errorContent: {
                  title: "error",
                  isError: true,
                  body: responseJson.errors[0].otp,
                },
              });
            }else if(responseJson.errors[0].pin){
              this.setState({
                isErrorModalShow: true,
                errorContent: {
                  title: "error",
                  isError: true,
                  body: responseJson.errors[0].pin,
                },
              });
            }else{
              this.setState({
                isErrorModalShow: true,
                errorContent: {
                  title: "error",
                  isError: true,
                  body: responseJson.errors[0].otp,
                },
              });
              this.props.history.push("/reset_password_error");
            }
          } else {
            console.log("something went wrong");
          }
        } else if (
          apiRequestCallId == this.getResetPasswordBannerImageApiCallId
        ) {
          if (responseJson.data) {
            this.setState({
              resetPasswordBannerImage: responseJson.data[0].attributes.image,
            });
          } else {
            this.setState({
              isErrorModalShow: true,
              errorContent: {
                title: "Error",
                isError: true,
                body: "Unable to load reset password banner image!",
              },
            });
          }
        }
      } else {
        console.log("hello");
      }
    }
  }
  getResetPasswordBannerImage = () => {
    const header = {
      'Content-Type': 'application/json',
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getResetPasswordBannerImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getForgotPasswordBannerImageAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.setState({
      resetPasswordBannerImageId: requestMessage.id,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
