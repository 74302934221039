import React, { Component } from 'react';
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Select,
  Spin
} from 'antd';
import { logo } from '../assets';
const style = require(`../../../social-media-account/src/login.module.css`);
import EditBusinessOverviewController from '../EditBusinessOverviewController.web';
import '../profile.css';
import { phoneRegExp } from '../CommonUI/CommonExported.web'
const { Title, Text, Link } = Typography;
//Customizable Area End
export default class EditBusinessProfileOverview extends EditBusinessOverviewController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div>

        <Row
          style={{
            minHeight: '90vh',
            alignItems: 'center',
            backgroundColor: '#eff8fe',
          }}
        >
          <Col xs={0} sm={0} md={3} lg={6} xl={4} />
          <Col xs={23} sm={21} md={18} lg={15} xl={17}>
            <Title
              level={2}
              style={{
                textAlign: 'center',
                marginBottom: '15px',
                marginTop: '50px',
              }}
            >
              Edit Your Basic Profile
            </Title>
            <Col span={24} offset={1} className='basic_profile_card'>
              <div style={{ backgroundColor: '#e0eaf4', height: '35px' }}>
                <Title
                  level={4}
                  style={{
                    marginLeft: '15px',
                    color: 'steelblue',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  About
                </Title>
              </div>
              {this.state?.profileLoading ? (
                <Spin />
              ) : (
                <Form
                  initialValues={{
                    company_overview: this.state.data.company_overview ? this.state.data.company_overview : this.state.prevData?.company_overview,
                    company_mission: this.state.data.company_mission ? this.state.data.company_mission : this.state.prevData?.company_mission,
                    company_vision: this.state.data.company_vision ? this.state.data.company_vision : this.state.prevData?.company_vision,
                    company_core_values: this.state.data.company_core_values ? this.state.data.company_core_values : this.state.prevData?.company_core_values,
                    health_safety_environment: this.state.data.health_safety_environment ? this.state.data.health_safety_environment : this.state.prevData?.health_safety_environment,
                    products_services: this.state.data.products_services ? this.state.data.products_services : this.state.prevData?.products_services,
                    contact_phone: this.state.data.contact_phone ? this.state.data.contact_phone : this.state.prevData?.contact_phone,
                    contact_email: this.state.data.contact_email ? this.state.data.contact_email : this.state.prevData?.contact_email,
                  }}
                  onFinish={this.handleAddProfileOverviewData}
                >
                  <Card>
                    <Row gutter={12}>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[{ required: true, message: 'Required field' }]}
                          name='company_overview'
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            className={style.input_fields_TextArea}
                            name='company_overview'
                            placeholder='Company Overview'
                            style={{ borderWidth: 1 }}
                            value={this.state.companyOverView}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[{ required: true, message: 'Required field' }]}
                          name='company_mission'
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            className={style.input_fields_TextArea}
                            name='company_mission'
                            placeholder='Company Mission'
                            style={{ borderWidth: 1 }}
                            value={this.state.companyMission}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[{ required: true, message: 'Required field' }]}
                          name='company_vision'
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            className={style.input_fields_TextArea}
                            name='company_vision'
                            placeholder='Company Vision'
                            style={{ borderWidth: 1 }}
                            value={this.state.companyVision}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[{ required: true, message: 'Required field' }]}
                          name='company_core_values'
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            className={style.input_fields_TextArea}
                            name='company_core_values'
                            placeholder='Company Core Values'
                            style={{ borderWidth: 1 }}
                            value={this.state.companyCoreValues}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[{ required: true, message: 'Required field' }]}
                          name='health_safety_environment'
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            className={style.input_fields_TextArea}
                            name='health_safety_environment'
                            placeholder='Health,Safety & Environment'
                            style={{ borderWidth: 1 }}
                            value={this.state.healthSafetyEnviroment}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[{ required: true, message: 'Required field' }]}
                          name='products_services'
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            className={style.input_fields_TextArea}
                            name='products_services'
                            placeholder='Product & Services'
                            style={{ borderWidth: 1 }}
                            value={this.state.productServices}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <div style={{ backgroundColor: '#e0eaf4', height: '35px' }}>
                    <Title
                      level={4}
                      style={{
                        marginLeft: '15px',
                        color: 'steelblue',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      Contact Details
                    </Title>
                  </div>
                  <Card>
                    <Row gutter={12}>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Required field',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue('contact_phone').match(
                                    phoneRegExp
                                  )
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error('Incorrect Phone Number')
                                );
                              },
                            }),
                          ]}
                          name='contact_phone'
                        >
                          <Input
                            className={style.input_fields}
                            name='contact_phone'
                            placeholder='Telephone'
                            style={{ borderWidth: 1 }}
                            value={this.state.contactPhone}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            { required: true, message: 'Required field' },
                            { type: 'email', message: 'Invalid Email' },
                          ]}
                          name='contact_email'
                        >
                          <Input
                            className={style.input_fields}
                            name='contact_email'
                            placeholder='Email'
                            style={{ borderWidth: 1 }}
                            value={this.state.contactEmail}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <Row style={{ background: "#f8f8f8" }} >
                    <Col lg={12} xl={12} md={12} sm={12} xs={24} className="EditProfile_action_buttons" style={{ padding: "15px", }}>
                      <Button
                        className={style.skip_add_btn}
                        type="primary"
                        shape="round"
                        size={"large"}
                        onClick={() => this.onHistoryScreen()}
                      >
                        Skip Profile Setup
                      </Button>
                    </Col>
                    <Col lg={12} xl={12} md={12} sm={12} xs={24}
                      // className="Previous_Next_Button_Scope"
                      style={{
                        padding: "15px 0px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end"
                      }}
                      className={style.responsive_bottom_action_button}
                    >
                      <Button
                        onClick={() => this.props.history.push({
                          pathname: "/edit/profile/business",
                          state: { data: this.state?.data, oldData: this.state?.prevData },
                        })}
                        className={style.previous_btn}
                        type="primary"
                        shape="round"
                        size={"large"}
                      >
                        Previous
                      </Button>
                      <Button
                        className={style.btn_colors}
                        type='primary'
                        htmlType='submit'
                        shape='round'
                        size={'large'}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Col>
          </Col>
          <Col xs={0} sm={0} md={3} lg={7} xl={7} />
        </Row>
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
  // Customizable Area End
