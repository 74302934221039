import React, { useEffect, useState } from "react";

interface Props {
    showToast: boolean;
    content: string;
    bgColor?: string;
    textColor?: string;
}

const ToastNotification: React.FC<Props> = ({ showToast, content, bgColor = "#ffffff", textColor = "#000000" }) => {
    const [fadeAnim, setFadeAnim] = useState(0);

    useEffect(() => {
        if (showToast) {
            setFadeAnim(1);
            const fadeOutTimeout = setTimeout(() => {
                setFadeAnim(0);
            }, 223000);

            return () => clearTimeout(fadeOutTimeout);
        }
    }, [showToast]);

    if (!showToast && fadeAnim === 0) return null;

    return (
        <div
            style={{
                ...styles.toastContainer,
                opacity: fadeAnim,
                backgroundColor: bgColor,
            }}
        >
            <span style={{ ...styles.text, color: textColor }}>{content}</span>
        </div>
    );
};

export default ToastNotification;

const styles: { [key: string]: React.CSSProperties } = {
    toastContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
        margin: '20px auto',
        position: 'fixed',
        bottom: '30px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        transition: 'opacity 0.5s ease',
    },
    icon: {
        width: '20px',
        height: '20px',
        marginRight: '10px',
    },
    text: {
        color: '#000',
        fontSize: '16px',
        fontWeight: 500,
        letterSpacing: "0.8px"
    },
};
