/* App/Lib/GeneralHelpers.js */
import React from "react";
import { Platform, PermissionsAndroid, PixelRatio, Dimensions } from 'react-native';
import Contacts from 'react-native-contacts';
import {check, PERMISSIONS,request, RESULTS} from 'react-native-permissions';
import StorageProvider from '../../framework/src/StorageProvider';
import NetInfo from "@react-native-community/netinfo";


export const phoneRegExp = /^\d{4,15}$/;

export enum Status {
  idle = 'idle',
  pending = 'pending',
  resolved = 'resolved',
  rejected = 'rejected'
}

export async function setStorageData(key:string, data:any){
  if (key && data) {
    await StorageProvider.set(key, data);
  }
}

export async function getStorageData(key:string, parseToJson:boolean = false){
  if (StorageProvider && key) {
    const data = await StorageProvider.get(key) || null;
    if(parseToJson && data){
      return JSON.parse(data)
    }else{
      return data;
    }
  }
  return null;
}

export async function removeStorageData(key:string){
  if (StorageProvider && key) {
    await StorageProvider.remove(key);
  }
}
export function getOS(): string {
  return Platform.OS;
}


export const requestPermission=()=>{
  if(Platform.OS=='android'){
 return request(PERMISSIONS.ANDROID.READ_CONTACTS).then((result: any) => {
if(result===RESULTS.GRANTED) return true
  else  return false
});
  }
  else{
    return request(PERMISSIONS.IOS.CONTACTS).then((result: any) => {
   if(result===RESULTS.GRANTED) return true
     else  return false
   });
  }
}

export const checkInternet = async () => {
  let result = await NetInfo.fetch();
  return result.isConnected;
}

export const checkPermission = () => {
  if(Platform.OS=='android'){
 return check(PERMISSIONS.ANDROID.READ_CONTACTS)
  .then((result: any) => {
  if(result===RESULTS.GRANTED) return true
  else  return false
})
  .catch((error: any) => { });
}
else{
  return check(PERMISSIONS.IOS.CONTACTS)
   .then((result: any) => {
   if(result===RESULTS.GRANTED) return true
   else  return false
 })
   .catch((error: any) => { });
}
};

export const contacts = async () => {
  return await Contacts.getAll().then((contacts) => {
    console.log("contacts response",contacts)
    return contacts.map((contact, i) => ({
      name: (contact?.displayName || contact?.givenName+` ${contact?.middleName}`+` ${contact?.familyName}`).replace('  ',' '),
      hasThumbnail: contact?.hasThumbnail,
      thumbnailPath: contact?.thumbnailPath,
      mobile: contact?.phoneNumbers[0]?.number.replace(/\s/g, '').replace('+', ''),
      isPhoneContact: true,
    }));
  })
  .catch(error=> console.log("contacts error",error))
};

export const dateIsWithIin24Hours = async() => {
  let latestData = await getStorageData("cancelDate");
  console.log('latestData dateIsWithIin24Hours= ',latestData)
  if (!latestData) return false;
  const yesterday = new Date().getTime() - 1 *  24 * 60 * 60  *1000;
  let date = await parseInt(latestData);
  console.log('yesterday dateIsWithIin24Hours ===',yesterday)
  console.log('date dateIsWithIin24Hours ===',date)
  return !(yesterday > date);
}


let screenWidth = Dimensions.get("window").width;
let screenHeight = Dimensions.get("window").height;

//Artboard Dimension
let artBoardHeightOrg = 640;
let artBoardWidthOrg = 360;
//Re calculated Artboard Dimension
let artBoardWidth = isSameRatio() ? artBoardWidthOrg : screenWidth;
let artBoardHeight = isSameRatio() ? artBoardHeightOrg : screenHeight;
// To check if Artboard and Device screen has same ratio
function isSameRatio(): boolean {
  return (
    artBoardWidthOrg / artBoardHeightOrg < 1 && screenWidth / screenHeight < 1
  );
}

//Top or Bottom nav spaces or any extra space occupied by os e.g Status bar, Notch

let extraSpace = 0;

export function deviceBasedDynamicDimension(
  originalDimen: number,
  compareWithWidth: boolean,
  resizeFactor: number
): number {
  if (originalDimen != null) {
    if (resizeFactor != null) {
      originalDimen *= resizeFactor;
    }
    const compareArtBoardDimenValue = compareWithWidth
      ? artBoardWidth
      : artBoardHeight;
    const artBoardScreenDimenRatio =
      (originalDimen * 100) / compareArtBoardDimenValue;
    let compareCurrentScreenDimenValue = compareWithWidth
      ? screenWidth
      : screenHeight - extraSpace;
    return PixelRatio.roundToNearestPixel(
      (artBoardScreenDimenRatio * compareCurrentScreenDimenValue) / 100
    );
  }
  return originalDimen;
}