import React from "react";
import "./../userFeed.css";
import "./myprofilestyling.css";
import {
    Typography,
    LinearProgress,
    Box,
} from "@material-ui/core";
import { Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default function ProfileRatingAppliedProgressBar(props: any) {

    const { title, value, color, classes } = props;

    return (
        <React.Fragment>
            <div className="profile_rating_flow_for_rating_view" style={{ background: 'white', padding: "15px" }}>
                <div className="rating_applied_l_for_rating_view">
                    <span style={{ fontSize: 14 }}>{title} {" "}
                        {props?.description &&
                            <Tooltip
                        
                                title={ <div
                                    dangerouslySetInnerHTML={{
                                      __html: props?.description.replace(/\n/g, '<br />')
                                    }}
                                  />} >
                                <ExclamationCircleOutlined
                                    style={{ marginLeft: "0.4rem", cursor: "pointer", color: "#919191"}}
                                />
                            </Tooltip>}

                    </span></div>
                <div className="rating_applied_r_for_rating_view">
                    <Box>
                        <LinearProgress
                            variant="determinate"
                            value={value}
                            color={color}
                            // size={250}
                            // thickness={3}
                            style={{
                                color: color,
                                background: color,
                               
                               
                            }}
                        // className={classes.bottom}
                        />
                        <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                           
                            style={{
                                marginLeft: '-1.5%',
                                background: `#dfdfdf`,
                                left: `${(value)}%`
                            }}
                        >
                            <Typography
                                variant="caption"
                                component="div"
                                className="rating_applied_r_line_css_for_rating_view"
                               
                              
                                style={{
                                    background: color,
                                    width: "32px",
                                    height: "32px",
                                    padding: "2px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
              

                    

                                    
                                   
                                }}
                            // color="textSecondary"
                            >
                                <span
                                    className="rating_applied_r_number_css_for_rating_view"
                                    style={{}}>{value}%</span>
                            </Typography>
                        </Box>
                    </Box>
                </div>
            </div>


        </React.Fragment>
    )

}
// Customizable Area Start
// Customizable Area End
