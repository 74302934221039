import React, { Component } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Select,
  Spin,
} from "antd";
import { logo } from "../assets";
const style = require(`../../../social-media-account/src/login.module.css`);
import CreateProfileOverviewControllerV2 from "../ProfileSetupOverviewControllerV2.web";
import "../profile.css";
import { phoneRegExp } from "../CommonUI/CommonExported.web";
const { Title, Text, Link } = Typography;
// Customizable Area End
export default class ProfileSetupBasicBusinessOverviewV2 extends CreateProfileOverviewControllerV2 {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div>
        {this.redirectUser()}
        <PageHeader
          className="site-page-header"
          title={
            <span style={{ color: "white" }}>
              <img style={{ height: "32px", marginRight: "8px" }} src={logo} />
              applied
            </span>
          }
          style={{
            backgroundImage: "linear-gradient(180deg, rgba(60,96,134,1) 0%, rgba(53,135,196,1) 100%)",
            color: "white",
          }}
        />
        <Row
          style={{
            minHeight: "90vh",
            alignItems: "center",
            backgroundColor: "#eff8fe",
          }}
        >
          <Col xs={0} sm={0} md={3} lg={6} xl={4} />
          <Col xs={23} sm={21} md={18} lg={15} xl={17}>
            <Title
              level={2}
              style={{
                textAlign: "center",
                marginBottom: "15px",
                marginTop: "50px",
              }}
            >
              Setup Your Business Profile
            </Title>
            <Col span={24} offset={1} className="basic_profile_card">
              <div style={{ backgroundColor: "#e0eaf4", height: "35px" }}>
                <Title
                  level={4}
                  style={{
                    marginLeft: "15px",
                    color: "steelblue",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  About
                </Title>
              </div>
              {this.state.loading ? (
                <Spin />
              ) : (
                <Form
                  initialValues={{
                    company_overview: this.state.prev_data
                      ? this.state.prev_data.company_overview
                      : "",
                    company_mission: this.state.prev_data
                      ? this.state.prev_data.company_mission
                      : "",
                    company_vision: this.state.prev_data
                      ? this.state.prev_data.company_vision
                      : "",
                    company_core_values: this.state.prev_data
                      ? this.state.prev_data.company_core_values
                      : "",
                    health_safety_environment: this.state.prev_data
                      ? this.state.prev_data.health_safety_environment
                      : "",
                    products_services: this.state.prev_data
                      ? this.state.prev_data.products_services
                      : "",
                    contact_phone: this.state.prev_data
                      ? this.state.prev_data.contact_phone
                      : "",
                    contact_email: this.state.prev_data
                      ? this.state.prev_data.contact_email
                      : "",
                  }}
                  onFinish={this.handleAddProfileOverviewData}
                >
                  <Card>
                    <Row gutter={12}>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            { required: true, message: "Required field" },
                          ]}
                          name="company_overview"
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            className={style.input_fields_TextArea}
                            name="company_overview"
                            placeholder="Company Overview"
                            style={{ borderWidth: 1, color: "#a9bac8" }}
                            value={this.state.companyOverView}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            { required: true, message: "Required field" },
                          ]}
                          name="company_mission"
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            className={style.input_fields_TextArea}
                            name="company_mission"
                            placeholder="Company Mission"
                            style={{ borderWidth: 1, color: "#a9bac8" }}
                            value={this.state.companyMission}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            { required: true, message: "Required field" },
                          ]}
                          name="company_vision"
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            className={style.input_fields_TextArea}
                            name="company_vision"
                            placeholder="Company Vision"
                            style={{ borderWidth: 1, color: "#a9bac8" }}
                            value={this.state.companyVision}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            { required: true, message: "Required field" },
                          ]}
                          name="company_core_values"
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            className={style.input_fields_TextArea}
                            name="company_core_values"
                            placeholder="Company Core Values"
                            style={{ borderWidth: 1, color: "#a9bac8" }}
                            value={this.state.companyCoreValues}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            { required: true, message: "Required field" },
                          ]}
                          name="health_safety_environment"
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            className={style.input_fields_TextArea}
                            name="health_safety_environment"
                            placeholder="Health,Safety & Environment"
                            style={{ borderWidth: 1, color: "#a9bac8" }}
                            value={this.state.healthSafetyEnviroment}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            { required: true, message: "Required field" },
                          ]}
                          name="products_services"
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            className={style.input_fields_TextArea}
                            name="products_services"
                            placeholder="Product & Services"
                            style={{ borderWidth: 1, color: "#a9bac8" }}
                            value={this.state.productServices}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <div style={{ backgroundColor: "#e0eaf4", height: "35px" }}>
                    <Title
                      level={4}
                      style={{
                        marginLeft: "15px",
                        color: "steelblue",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Contact Details
                    </Title>
                  </div>
                  <Card>
                    <Row gutter={12}>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Required field",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("contact_phone").match(
                                    phoneRegExp
                                  )
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("Incorrect Phone Number")
                                );
                              },
                            }),
                          ]}
                          name="contact_phone"
                        >
                          <Input
                            className={style.input_fields}
                            name="contact_phone"
                            placeholder="Telephone"
                            type={"number"}
                            style={{ borderWidth: 1, color: "#a9bac8" }}
                            size="large"
                            value={this.state.contactPhone}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <Form.Item
                          rules={[
                            { required: true, message: "Required field" },
                            { type: "email", message: "Invalid Email" },
                          ]}
                          name="contact_email"
                        >
                          <Input
                            className={style.input_fields}
                            name="contact_email"
                            type={"email"}
                            placeholder="Email"
                            style={{ borderWidth: 1, color: "#a9bac8" }}
                            size="large"
                            value={this.state.contactEmail}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <Row style={{ background: "#f8f8f8" }}>
                    <Col lg={12} xl={12} md={12} sm={12} xs={24} style={{ padding: "15px", }}>
                      <Button
                        onClick={this.onSkipScreen}
                        className={style.skip_add_btn}
                        type="primary"
                        htmlType="submit"
                        shape="round"
                        size={"large"}
                        style={{ width: "100%" }}
                      >
                        Skip Profile Setup
                      </Button>
                    </Col>
                    <Col
                      lg={12} xl={12} md={12} sm={12} xs={24}
                      style={{
                        padding: "15px 0px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end"
                      }}
                      className={style.responsive_bottom_action_button}
                    >
                      <Button
                        onClick={() => this.handlePrevious()}
                        className={style.previous_btn}
                        type="primary"
                        shape="round"
                        size={"large"}
                      >
                        Previous
                      </Button>
                      <Button
                        className={style.btn_colors}
                        type='primary'
                        htmlType='submit'
                        shape='round'
                        size={'large'}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Col>
          </Col>
          <Col xs={0} sm={0} md={3} lg={7} xl={7} />
        </Row>
      </div>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
// Customizable Area End