Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Groups";
exports.labelBodyText = "Groups Body";
// Delete Community
exports.deleteCommunityApiEndPoint = "bx_block_groups/community_forums";
exports.deleteCommunityApiContentType = "application/json";
exports.deleteCommunityApiMethod = "DELETE";
// Get All Community End Point With Method
exports.getMyCommunityForumApiEndPoint = "bx_block_groups/community_forums/?admin=true&joined=true";
exports.getCommunityForumApiEndPoint = "bx_block_groups/community_forums/?admin=false&joined=true";
exports.getAllCommunityForumApiEndPoint = "bx_block_groups/community_forums";
exports.getCommunityPeopleAlsoFollowApiEndPoint = "bx_block_communityforum/community_forums";
exports.getCommunityApiMethodType="GET";
exports.getCommunityApiContentType = "application/json";
// Create and Edit Community
exports.createCommunityEndPoint="bx_block_groups/community_forums"
exports.EditCommunityEndPoint="bx_block_groups/community_forums"
exports.createCommunityPostApiMethodType="POST"
exports.createCommunityPutApiMethodType="PUT"
exports.btnExampleTitle = "CLICK ME";

// Mobile Changes
exports.GETMethod = "GET";
exports.POSTMethod = "POST";
exports.PUTMethod = "PUT";
exports.DELETEMethod = "DELETE";
exports.APPLICATIONJSONContentType = "application/json";
exports.MULTIPARTFormData = "multipart/form-data";

exports.GetProfileInfoEndPoint = "bx_block_profile/profiles";

exports.GetCategoriesEndPoint = "bx_block_categories/categories";
exports.postsApiEndpoint = "bx_block_posts/posts";
exports.GetCommunityPostsEndPoint =
  "bx_block_posts/posts/fetch_community_posts";
exports.commentsApiEndpoint = "bx_block_comments/comments";
exports.GetNetConnectionEndPoint = "bx_block_request_management/requests";
exports.getCommunitiesApiEndpoint = "bx_block_groups/community_forums";
exports.createEditCommunityApiEndPoint = "bx_block_groups/community_forums";
exports.getCommunityDataApiEndpoint = "bx_block_groups/community_forums";
exports.getSetttingsFlagsApiEndpoint = "bx_block_groups/community_forums";

exports.joinCommunityApiEndpoint = "bx_block_follow/community_joins";
exports.followCommunityApiEndpoint = "bx_block_follow/community_joins/follow";

exports.blockUserApiEndpoint = "bx_block_follow/community_blocks";

exports.getNetConnectionEndPoint = "bx_block_request_management/requests";
exports.manageInvitedFriendsEndPoint = "bx_block_follow/community_joins/";
exports.communityEndPoint = "bx_block_communityforum/community_forums/";
// Customizable Area End