import React from "react";
import PostRatingController, { Props } from "./PostRatingController.web";
import { imgPasswordInVisible, user, workStation, tag, } from "./../assets";
import CommonListItem from "./../commonListItem.web";
import { EnvironmentOutlined, CloseOutlined, PaperClipOutlined, PictureOutlined, SendOutlined, SmileOutlined, } from "@ant-design/icons";
import "./../userFeed.css";
import moment from 'moment';
import {
  Card, Row, Col, List, Input, Typography, Avatar, Button, Upload, Image, Badge, Radio,
  Tabs, Space, Modal
} from "antd";
import CustomPost from "./../CustomPost.web";
import Picker from 'emoji-picker-react';
import ReactPlayer from 'react-player'
import { Dimensions, PixelRatio } from "react-native";
import { Divider } from "react-native-elements/dist/divider/Divider";
const { Title, Text } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;
const postData = [
  //   {
  //     key: "posts",
  //     count: "500",
  //     image: imgPasswordInVisible,
  //     description: "UI/Ux Designer Community",
  //   },
  {
    key: "connection",
    count: "500",
    image: imgPasswordInVisible,
    description: "UI/Ux Designer Community",
  },
  {
    key: "followers",
    count: "2k",
    image: imgPasswordInVisible,
    description: "UI/Ux Designer Community",
  },
  {
    key: "following",
    count: "1100",
    image: imgPasswordInVisible,
    description: "UI/Ux Designer Community",
  },
];
let screenWidth = Dimensions.get('window').width;
let screenHeight = Dimensions.get('window').height;
//Artboard Dimension 
let artBoardHeightOrg = 1024;
let artBoardWidthOrg = 1440;
//Re calculated Artboard Dimension 
let artBoardWidth = isSameRatio() ? artBoardWidthOrg : screenWidth;
let artBoardHeight = isSameRatio() ? artBoardHeightOrg : screenHeight;
// To check if Artboard and Device screen has same ratio 
function isSameRatio(): boolean {
  return artBoardWidthOrg / artBoardHeightOrg < 1 && screenWidth / screenHeight < 1
};

//Top or Bottom nav spaces or any extra space occupied by os e.g Status bar, Notch 
let extraSpace = 0;


export default class PostRating extends PostRatingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    const { } = this.state;
    // Customizable Area End
  }

  // Customizable Area Start

  renderUSerProfile = () => {
    var startDate = moment("2018-10-10", "YYYY.MM.DD"), // $('[name="date-start"]').val() === "13.04.2016"
      endDate = moment("2020-11-12", "YYYY.MM.DD"); // $('[name="date-end"]').val() === "28.04.2016"

    var diff = startDate.diff(endDate);

    // console.log( 'date-->',moment(diff).format('E') );
    const { userProfile } = this.state
    return (
      <Card bordered={false}>
        <Row style={{ alignItems: 'center' }} >
          <Col>
            <Avatar src={this.state.loginUser.profile_pic ? this.state.loginUser.profile_pic : require("../../assets/image_profile.png")} size={60} style={{ backgroundColor: "grey" }} />
          </Col>
          <Col style={{ marginLeft: 12 }}>
            <p>
              {" "}
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>{userProfile?.attributes?.full_name}</Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>{this.state.loginUser.first_name + ' ' + this.state.loginUser.last_name}</Text>
            </p>
            {/* <p>
          {this.state.loginUser.role === "personal" && userProfile && userProfile.attributes && userProfile.attributes.designation && userProfile.attributes.designation.title ? (
              <>
              <Text>{userProfile.attributes.designation.title}</Text>
              <Text style={{ color: "#757575" }}>  " at " </Text> 
              <Text>{userProfile.attributes.designation.company}</Text>
              </>
            ) : null}
          {this.state.loginUser.role && this.state.loginUser.role !== "personal" && userProfile && userProfile.attributes && userProfile.attributes.business ? (
              <Text >{userProfile.attributes.business + ", " + userProfile.attributes.business_industry}</Text>
            ) : null}
            <br/>
          {this.state.loginUser.role && this.state.loginUser.role === "personal" && userProfile ? (
              <Text style={{ color: "#757575" }}>{this.getDomainSkills(userProfile)}</Text>
            ) : null}
          {this.state.loginUser.role && this.state.loginUser.role !== "personal" && userProfile && userProfile.attributes && userProfile.attributes.location ? (
              <Text style={{ color: "#757575" }}>{userProfile.attributes.location}</Text>
            ) : null}
        </p> */}

            <p>
              {" "}
              <Text style={{ fontSize: 16 }}>3+ years of experience</Text>
            </p>
          </Col>
        </Row>
      </Card>
    );
  };

  renderAboutContainer = () => {
    return (
      <Card bordered={false}>
        <Divider />
        <Col style={{ marginLeft: 12, marginTop: 12 }} >
          <p><Text style={{ fontSize: 14, fontWeight: "bold" }}>About</Text></p>
          <p><Text style={{ fontSize: 12, marginBottom: 12 }}>Göppert was educated at the Höhere Technische in Göttingen, a school for middle-class girls who aspired to higher education.[5] In 1921, she entered the Frauenstudium, a private high school run by suffragettes that aimed to prepare girls for university. She took the abitur, the university entrance examination, at age 17, a year early, with three or four girls from her school and thirty boys. All the girls passed, but only one of the boys did</Text></p>
        </Col>
      </Card>
    )
  };

  renderPostConnectionDetails = () => {
    const postData = [
      {
        key: "posts",
        count: this.state.accountDetails?.attributes?.posts_count,
        image: imgPasswordInVisible,
        description: "UI/Ux Designer Community",
      },
      {
        key: "connection",
        count: this.state.accountDetails?.attributes?.friends_count,
        image: imgPasswordInVisible,
        description: "UI/Ux Designer Community",
      },
      {
        key: "followers",
        count: this.state.accountDetails?.attributes?.followers_count,
        image: imgPasswordInVisible,
        description: "UI/Ux Designer Community",
      },
      {
        key: "following",
        count: this.state.accountDetails?.attributes?.following_count,
        image: imgPasswordInVisible,
        description: "UI/Ux Designer Community",
      },
      {
        key: "Rating Received",
        count: this.state.accountDetails?.attributes?.ratings_received,
        image: imgPasswordInVisible,
        description: "UI/Ux Designer Community",
      },
      {
        key: "Rating Sent",
        count: this.state.accountDetails?.attributes?.ratings_given,
        image: imgPasswordInVisible,
        description: "UI/Ux Designer Community",
      },

    ];
    return (
      <Card style={{ borderRadius: 4 }}>
        <Text>Stats</Text>
        <List
          grid={{ column: 2 }}
          dataSource={postData}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <div className={"post-connection-content ant-row-middle"}>
                    <Avatar style={{ padding: "5px" }} src={item.image} size={40} />
                  </div>
                }
                title={<h4 className="post-connection-title">{item.key}</h4>}
                description={
                  <h2 className="post-connection-count">
                    <b>{item.count}</b>
                  </h2>
                }
                style={{ height: 64, justifyContent: 'center', alignItems: "center", backgroundColor: "rgb(221,230,235)", marginRight: "5px", borderRadius: "4px" }}
              />
            </List.Item>
          )}
        />
      </Card>
    );
  };

  showReportModal = () => {
    return (
      <Modal
        width={350}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        // placement="bottomRight"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px" }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Report Post
          </div>
        }
        visible={this.state.isReportIssueVisible}
        onCancel={this.handleCancel}
        footer={[
          <Button size="large" className="report-button" style={{ width: "100%" }} key="report" onClick={this.handleOk}>
            Report
          </Button>,
        ]}
      >
        <Radio.Group onChange={this.onChangeValue} value={this.state.value} buttonStyle="outline">
          <Space direction="vertical">
            <Radio style={{ color: "grey" }} value={1}>
              Fraude or pyramid Marketing
            </Radio>
            <Radio style={{ color: "grey" }} value={2}>
              Promoting/Selling illegal or regulated goods
            </Radio>
            <Radio style={{ color: "grey" }} value={3}>
              Promotes Terrorism
            </Radio>
            <Radio style={{ color: "grey" }} value={4}>
              Discrimination, Harrassment or Bullying
            </Radio>
            <Radio style={{ color: "grey" }} value={5}>
              Child Abuse
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    )
  };

  renderPost = (item: any) => {
    return (
      <CustomPost
        menu={[
          { key: "save", label: "Save Post" },
          { key: "hide", label: "Hide Post" },
          { key: "unfollow", label: "Unfollow" },
          { key: "report", label: "Report this post" },
        ]}
        handlePostList={this.handlePostList}
        loginUserId='hello'
        data={item?.attributes}
        handleMenuClick={(e: any) => this.handleMenuClick(e, item.id)}
        isFollowed={this.state.followedItems.includes(item.attributes?.account?.data?.id)}
        toggleFollow={() => this.onClickFollow(item.attributes?.account?.data?.id, this.state.followedItems.includes(item.attributes?.account?.data?.id))}
        handleEditPostOpen={() => console.log('handleEditPostOpen method')}
      />
    );
  };

  renderRating = () => {
    return (
      <div>
        <Row style={{ justifyContent: 'space-between', marginLeft: 20, marginRight: 20 }}>
          <Text>Ratings Received</Text>
          <Col>
            <Text style={{ marginRight: 20 }}>Ratings Received</Text>
            <Text>Rating Send</Text>
          </Col>
        </Row>
        <Row style={{ alignItems: 'center', paddingLeft: "20px", paddingTop: 20 }}>
          <Col>
            <Avatar src={this.state.loginUser.profile_pic ? this.state.loginUser.profile_pic : require("../../assets/image_profile.png")} size={40} style={{ backgroundColor: "grey" }} />
          </Col>
          <Row justify="center" style={{ paddingTop: "0px", paddingLeft: 10, paddingBottom: 10 }}>
            <p style={{ width: "100%", marginBottom: "0", fontSize: 12, fontWeight: 600 }}>Sr. Python Developer <Text type="secondary"> has rated you as</Text> Google</p>
            <br />
            <Text style={{ width: "100%", fontSize: 12 }} type="secondary">13 days ago</Text>
          </Row>
        </Row>
        <Divider />
      </div>
    )
  };

  renderReviews = () => {
    return (
      <div>
        <div style={{ paddingLeft: "20px", paddingTop: 10, backgroundColor: 'white', paddingBottom: 10 }}>
          <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Row style={{ alignItems: 'center' }}>
              <Col>
                <Avatar src={this.state.loginUser.profile_pic ? this.state.loginUser.profile_pic : require("../../assets/image_profile.png")} size={40} style={{ backgroundColor: "grey" }} />
              </Col>
              <Row justify="center" style={{ paddingTop: "10px", paddingLeft: 10, paddingBottom: 10 }}>
                <p style={{ width: "100%", marginBottom: "0", fontSize: 12, fontWeight: 600 }}>Sr. Python Developer <Text type="secondary"> has rated you as</Text> Google</p>
                <br />
                <Text style={{ width: "100%", fontSize: 12 }} type="secondary">13 days ago</Text>
              </Row></Row>
            <Col style={{ marginRight: 20 }}>
              <Text style={{ width: "100%", fontSize: 12 }} type="secondary">13th March, 2011</Text>
            </Col>
          </Row>
          <Row>
            <Text style={{ fontSize: 14, fontWeight: 500 }}>creative and innovative</Text>
            <Text>Göppert was educated at the Höhere Technische in Göttingen, a school for middle-class girls who aspired to higher education.[5] In 1921, she entered the Frauenstudium, a private high school run by suffragettes that aimed to prepare girls for university. She took the abitur, the university entrance examination, at age 17, a year early, with three or four girls from her school and thirty boys. All the girls passed, but only one of the boys did</Text>
          </Row>
        </div>
        <div style={{ paddingLeft: "20px", marginTop: 10, backgroundColor: 'white', paddingBottom: 10 }}>
          <Row style={{ alignItems: 'center', paddingTop: 10, justifyContent: 'space-between' }}>
            <Row style={{ alignItems: 'center' }}>
              <Col>
                <Avatar src={this.state.loginUser.profile_pic ? this.state.loginUser.profile_pic : require("../../assets/image_profile.png")} size={40} style={{ backgroundColor: "grey" }} />
              </Col>
              <Row justify="center" style={{ paddingTop: "10px", paddingLeft: 10, paddingBottom: 10 }}>
                <p style={{ width: "100%", marginBottom: "0", fontSize: 12, fontWeight: 600 }}>Sr. Python Developer <Text type="secondary"> has rated you as</Text> Google</p>
                <br />
                <Text style={{ width: "100%", fontSize: 12 }} type="secondary">13 days ago</Text>
              </Row>
            </Row>
            <Col style={{ marginRight: 20 }}>
              <Text style={{ width: "100%", fontSize: 12 }} type="secondary">14th March, 2011</Text>
            </Col>
          </Row>
          <Row>
            <Text style={{ fontSize: 14, fontWeight: 500 }}>creative and innovative</Text>
            <Text>Göppert was educated at the Höhere Technische in Göttingen, a school for middle-class girls who aspired to higher education.[5] In 1921, she entered the Frauenstudium, a private high school run by suffragettes that aimed to prepare girls for university. She took the abitur, the university entrance examination, at age 17, a year early, with three or four girls from her school and thirty boys. All the girls passed, but only one of the boys did</Text>
          </Row>
        </div>
      </div>
    )
  };

  renderImage = (id: any, isFullHeight: boolean) => {
    return (
      <Card style={{ width: '100%', padding: 0, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px' }} >
        <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.onRemoveImage(id)} icon={<CloseOutlined />} />
        <Image src={this.state.AllMedia[id].filePath} height={isFullHeight ? 380 : 180} width={'100%'}
          style={{ borderRadius: '4px', backgroundColor: 'grey', width: '100%' }}
          wrapperStyle={{ backgroundSize: 'cover', width: '100%' }} />
      </Card>
    )
  };

  renderVideo = (id: any, isFullHeight: boolean) => {
    return (
      <Card style={{ width: '100%', padding: 0, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px', backgroundColor: "#FAFAFA" }} >
        <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.onRemoveImage(id)} icon={<CloseOutlined />} />
        <ReactPlayer url={this.state.AllMedia[id].filePath} c
          ontrols
          height={isFullHeight ? 380 : 180}
          width={'98%'}
          style={{ borderRadius: '4px', backgroundColor: "#FAFAFA" }} />
      </Card>
    )
  };

  checkMedia = (id: any, isFullHeight: boolean) => {
    return this.state.AllMedia[id].type.slice(0, 5) == "video" ? this.renderVideo(id, isFullHeight) : this.renderImage(id, isFullHeight)
  };

  renderMedia = (imgArray: any) => {
    switch (imgArray.length) {
      case 0:
        return null;
      case 1:
        return (
          <Row style={{ padding: 5, width: '100%' }} >
            {/* <Col span={24}  > */}
            {this.checkMedia(0, false)}
            {/* </Col> */}
          </Row>
        )
      case 2:
        return (
          <Row className="flex-row">
            <Col span={12} >{this.checkMedia(0, false)}</Col>
            <Col span={12} >{this.checkMedia(1, false)}</Col>
          </Row>
        )
      case 3:
        return (
          <Row className="flex-row"  >
            <Col span={12}>{this.checkMedia(0, true)}</Col>
            <Col style={{ flexDirection: 'column' }} span={12} >
              {this.checkMedia(1, false)}
              {this.checkMedia(2, false)}
            </Col>
          </Row>
        )
      case 4:
        return (
          <div >
            <Row style={{ width: '100%' }}>
              <Col span={12} >{this.checkMedia(0, false)}</Col>
              <Col span={12} >{this.checkMedia(1, false)}</Col>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col span={12} >{this.checkMedia(2, false)}</Col>
              <Col span={12} > {this.checkMedia(3, false)}</Col>
            </Row>
          </div>
        )
      default:
        return (
          <div >
            <Row style={{ width: '100%' }}>
              <Col span={12}>{this.checkMedia(0, false)}</Col>
              <Col span={12}>{this.checkMedia(1, false)}</Col>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col span={12}>{this.checkMedia(2, false)}</Col>
              <Col span={12}> {this.checkMedia(3, false)}
                <Button size='large' type='text' style={{ marginTop: -100 }} >{`+${this.state.AllMedia.length - 3} More`} </Button>
              </Col>
            </Row>
          </div>
        )
    }
  };

  //@ts-ignore
  onEmojiClick = (event, emojiObject) => {
    const ref = this.contentRef.current
    ref.focus()
    const start = this.state.createPostDescription.substring(0, ref.selectionStart)
    const end = this.state.createPostDescription.substring(ref.selectionStart)
    const content = start + emojiObject.emoji;
    this.setState({ createPostDescription: content })
    ref.selectionEnd = start.length + emojiObject.emoji.length
  };

  renderCreatePost = () => {
    const { userProfile } = this.state;
    return (
      <div style={{ borderWidth: "0px", borderRadius: 4 }}>
        <Card style={{ borderWidth: "0px" }}>
          <Card
            bodyStyle={{ flexDirection: "row", display: "flex", padding: "10px", alignItems: "center" }}
            style={{ borderWidth: "0px" }}>
            <Card.Meta
              style={{ width: "80%" }}
              avatar={
                <Avatar
                  src={workStation}
                  size={40}
                  style={{ marginTop: "8px" }}
                />
              }
              title={
                <div className="margin-top5">{`${this.state.loginUser.first_name + ' ' + this.state.loginUser.last_name}`}</div>
              }
              description={
                <p className="margin-top-10">
                  {/* <Text>
                    Sr.PythonDeveloper
                    <Text style={{ color: "grey" }}> at </Text>google
                    <Text>
                      {" "}
                      <Text style={{ color: "grey" }}> | </Text>{" "}
                      Python,nodejs,mongodb
                    </Text>
                  </Text> */}
                  {this.state.loginUser.role === "personal" && userProfile && userProfile.attributes && userProfile.attributes.designation && userProfile.attributes.designation.title ? (
                    <Text style={{ color: "#757575" }}>{userProfile.attributes.designation.title + " at " + userProfile.attributes.designation.company}</Text>
                  ) : null}
                  {this.state.loginUser.role && this.state.loginUser.role !== "personal" && userProfile && userProfile.attributes && userProfile.attributes.business ? (
                    <Text style={{ color: "#757575" }}>{userProfile.attributes.business + ", " + userProfile.attributes.business_industry}</Text>
                  ) : null}
                  {this.state.loginUser.role && this.state.loginUser.role === "personal" && userProfile ? (
                    <Text style={{ color: "#757575" }}> {this.getDomainSkills(userProfile)}</Text>
                  ) : null}
                  {this.state.loginUser.role && this.state.loginUser.role !== "personal" && userProfile && userProfile.attributes && userProfile.attributes.location ? (
                    <Text style={{ color: "#757575" }}>{userProfile.attributes.location}</Text>
                  ) : null}
                </p>
              }
            />
          </Card>
          <Row style={{ padding: "10px" }}>
            <TextArea
              data-testid="createPostDescription"
              ref={this.contentRef}
              value={this.state.createPostDescription}
              onChange={({ target: { value } }) => this.onChange(value)}
              placeholder={this.placeHolderWhatOnMind}
              autoSize={{ minRows: 2, maxRows: 6 }}
              maxLength={this.state.isBusinessUser ? undefined : 1000}
              onBlur={() => {
                if (this.state.createPostDescription.length <= 0 && this.state.AllMedia.length <= 0) {
                  this.setState({ isCreatePostVisible: false });
                }
              }}
            />
            {this.state.isEmojiPickerEnable && <Picker data-testid="onEmojiClick" onEmojiClick={this.onEmojiClick} />}
            {this.state.AllMedia.length ?
              <Row style={{ paddingTop: "10px", width: '100%' }}>
                {this.renderMedia(this.state.AllMedia)}
              </Row> : null}
            {this.state.createPostDescription.length >= 1000 ? (
              <Row style={{ padding: "10px", }}>
                <Text style={{ color: "red" }}>
                  You have used your 1000 characters and one media per post.Please
                  upgrade to premium account to avail benefits to post without
                  any word or media limit.
                  <Text onClick={() => { this.props.history.push({ pathname: '/Premium/Account' }) }}
                    style={{ color: "#005574", marginLeft: "10px", cursor: "pointer" }}>
                    {"Upgrade to premium account."}
                  </Text>
                </Text>
              </Row>
            ) : null}
          </Row>
        </Card>
        <Card
          bodyStyle={{ flexDirection: "row", display: "flex", alignItems: "center", padding: "10px" }}>
          <Col style={{ width: "60%" }} >
            {/* <Text
            style={{
              fontSize: "16px",
              marginRight: "16px",
              color: "grey",
              width: "60%",
            }}
          >{`${1000 -
            this.state.createPostDescription.length} character left`}</Text> */}
          </Col>

          <Col style={{ display: "flex", justifyContent: "flex-end", width: '40%' }}>
            <Button style={{ marginRight: "10px" }}
              type="ghost"
              shape="circle"
              icon={<PaperClipOutlined style={{ fontSize: "25px", color: "grey" }} />}
              size={"large"}
            />
            <Button style={{ marginRight: "5px" }}
              data-testid="EmojiPickerEnable"
              type="ghost"
              shape="circle"
              icon={<SmileOutlined style={{ fontSize: "25px", color: "grey" }} />}
              size={"large"}
              onClick={() => this.setState({ isEmojiPickerEnable: !this.state.isEmojiPickerEnable })}
            />
            <Upload
              data-testid='updateDataModal'
              name='file'
              showUploadList={false}
              multiple={false}
              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={(file) => this.beforeUpload(file)}
              onChange={this.handleImageChange}
            >
              <Button
                style={{ marginRight: "10px" }}
                type="ghost"
                shape="circle"
                icon={<PictureOutlined style={{ fontSize: "25px", color: "grey" }} />}
                size={"large"}
              />
            </Upload>
            <Button style={{ marginRight: "10px" }}
              type="ghost"
              shape="circle"
              icon={<Image preview={false} src={tag} style={{ height: 25, width: 25, color: "grey" }} />}
              size={"large"}
            />
            <Button style={{ marginRight: "10px" }} type="ghost" shape="circle"
              icon={<EnvironmentOutlined style={{ fontSize: "25px", color: "grey" }} />}
              size={"large"}
              onClick={this.getUserLocattion}
            />
            <Button style={{ marginRight: "10px", color: " #fff", backgroundColor: "#005574", borderWidth: "0px" }}
              data-testid="submit"
              type="primary"
              shape="circle"
              icon={<SendOutlined rotate={-60} />}
              size={"large"}
              onClick={() => this.handleSubmit()}
            />
          </Col>
        </Card>
      </div>
    );
  };
  //@ts-ignore
  onChange = (value) => {
    let data = this.state.createPostDescription + value

    this.setState({ createPostDescription: value });
  };
  // onChange = ({ target: { value } }) => {
  //   this.setState({ createPostDescription: value });
  // };

  // Customizable Area End
  render() {
    const { navigation } = this.props;
    return (
      <div className="background" style={{ backgroundColor: "#f6f6f6" }}>
        {this.redirectUser()}
        <Text style={{ marginTop: "15px", marginBottom: 7, marginLeft: 16 }} className="textlabel_subheadingText">My Profile</Text>
        <Row>
          <Col span={6} xs={24} sm={24} md={12} lg={10} xl={8} xxl={6} style={{ padding: "16px" }}>
            {this.renderUSerProfile()}
            {this.renderAboutContainer()}
            {this.renderPostConnectionDetails()}
            <Card bodyStyle={{ textAlign: "center" }}
              style={{ borderRadius: 4 }}
            >
              <Row style={{ justifyContent: "space-around" }} >
                <Button type="primary" htmlType="submit" shape="round" size={"large"} style={{ color: 'blue', opacity: 0.5 }}>
                  Download Resume
                </Button>
                <Button type="primary" htmlType="submit" shape="round" size={"large"} style={{ color: 'blue', opacity: 0.5 }}>
                  Edit Profile
                </Button>
                <Button type="primary" htmlType="submit" shape="circle" size={"large"} style={{ color: 'blue', opacity: 0.5 }}>
                  ...
                </Button>
              </Row>
            </Card>
            <Button style={{ color: 'white', opacity: 0.5, width: 395, height: 40, backgroundColor: '#0F5B79', borderRadius: 16, marginTop: 12, fontSize: 16 }}>
              Create New Post
            </Button>
          </Col>
          <Col span={12} xs={24} sm={24} md={12} lg={16} xl={13} xxl={18}>
            {/* <Card
              style={{ borderRadius: 4, backgroundColor: "rgb(221,230,235)"}}
              hidden={this.state.isCreatePostVisible}
            > */}
            <Tabs defaultActiveKey="1" centered tabBarStyle={{ backgroundColor: '#DEE5EB', width: "100%" }} style={{ backgroundColor: '#F1F0F5' }}>
              <TabPane tab="Posts" key="1"><List dataSource={this.state.postList} renderItem={this.renderPost} /></TabPane>
              <TabPane tab="Ratings" key="2">{this.renderRating()} </TabPane>
              <TabPane tab="Reviews" key="3">{this.renderReviews()}</TabPane>
            </Tabs>
            {/* </Card> */}
            {(this.state.isCreatePostVisible || this.state.AllMedia.length != 0) ? this.renderCreatePost() : null}
            {this.showReportModal()}
          </Col>
        </Row>
      </div>
    );
  }
}
// Customizable Area Start
// Customizable Area End
