import React from 'react';
// Customizable Area Start
import { runEngine } from '../../../../framework/src/RunEngine';
import MessageEnum, {
  getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import { IBlock } from '../../../../framework/src/IBlock';
import { RouterProps } from 'react-router';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
// Customizable Area End

export interface Props extends RouterProps {
  // Customizable Area Start
  src: string
  setCroppedImageUrl: (url: any) => void
  openCropModal: boolean
  setCropModal: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  crop: Crop,
  croppedImageUrl: string | null
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CropModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizble Area Start
  imageRef: React.RefObject<ReactCrop> = React.createRef()
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      // Customizable Area Start
      crop: {
        unit: "%",
        aspect: 1,
        x: 0,
        y: 0,
        width: 50,
        height: 50,
      },
      croppedImageUrl: ""
      // Customizable Area End
    };
  }
  // Customizable Area Start

  onImageLoaded = (image: any): void => {

    this.imageRef = image;
  };

  onCropChange = (crop: Crop, percentageCrop: Crop) => {
    this.setState({ crop: crop });
  };

  getCroppedImg(image: any, crop: Crop, fileName: any) {

    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;
    if (ctx) {
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
    }
    //   // As Base64 string
    //   // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob: any) => {
          blob.name = fileName;
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });

  }

  onCropComplete = async (crop: Crop) => {
    const croppedImageUrl: any = await this.getCroppedImg(this.imageRef,
      crop,
      new Date().getTime() + ".jpg"
    );
    this.setState({ croppedImageUrl: URL.createObjectURL(croppedImageUrl) });
    this.props.setCroppedImageUrl({ pic: croppedImageUrl, url: URL.createObjectURL(croppedImageUrl) });
  };

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End