import React, { Suspense } from "react";
import MyBusinessProfileController, { Props } from "./MyBusinessProfileController.web";
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import {
  postsImgsIcon, connectionsIcon, followersIcon,
  followingIcon, ratingsReceivedIcon, ratingSentIcon, three_dot, createProfile, sendIcon, locationIcon, photoIcon, atIcon, faceIcon,
  whatsappIcon, facebookIcon, gmailIcon, linkdinIcon,infoIcon
} from "./../assets";
import { CloseOutlined,ExclamationCircleOutlined,FileTextOutlined,StarOutlined,EditOutlined,UserOutlined } from "@ant-design/icons";
import "./../userFeed.css";
import moment from 'moment';
import {
  Card, Row, Col, List, Input, Typography, Avatar, Button, Upload, Image, Radio,
  Tabs, Space, Modal, Form, Empty, message, Dropdown, Progress, Mentions, Spin,Tooltip
} from "antd";
import {
  SearchOutlined
} from '@ant-design/icons';
import { FacebookShareButton, WhatsappShareButton, EmailShareButton, LinkedinShareButton } from 'react-share'
import { isEmpty } from "../../../../framework/src/Utilities";
import Picker from 'emoji-picker-react';
import ReactPlayer from 'react-player'
import { Dimensions, PixelRatio } from "react-native";
import { Divider } from "react-native-elements/dist/divider/Divider";
import ProfileRatingViewsPercentage from "./ProfileRatingViewsPercentage.web";
import ProfileRatingAppliedProgressBar from "./profileRatingAplliedProgressBar.web";
const CustomPost = React.lazy(() => import('./../CustomPost.web'));
import Editor from "@draft-js-plugins/editor";
import '@draft-js-plugins/emoji/lib/plugin.css';
import createMentionPlugin, {
  defaultSuggestionsFilter
} from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import createEmojiPlugin from '@draft-js-plugins/emoji';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

import { convertToRaw } from "draft-js";
import { Box } from "@material-ui/core";
const { Title, Text } = Typography;
const { Option, getMentions } = Mentions;
const { TabPane } = Tabs;
const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};
//emoji
const emojiPlugin = createEmojiPlugin({
  selectButtonContent: <img src={faceIcon} width={20} />
});
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
let screenWidth = Dimensions.get('window').width;
let screenHeight = Dimensions.get('window').height;
//Artboard Dimension 
let artBoardHeightOrg = 1024;
let artBoardWidthOrg = 1440;
//Re calculated Artboard Dimension 
let artBoardWidth = isSameRatio() ? artBoardWidthOrg : screenWidth;
let artBoardHeight = isSameRatio() ? artBoardHeightOrg : screenHeight;
// To check if Artboard and Device screen has same ratio 
function isSameRatio(): boolean {
  return artBoardWidthOrg / artBoardHeightOrg < 1 && screenWidth / screenHeight < 1
};

//Top or Bottom nav spaces or any extra space occupied by os e.g Status bar, Notch 
let extraSpace = 0;

function deviceBasedDynamicDimension(originalDimen: number, compareWithWidth: boolean, resizeFactor: number): number | undefined {
  if (originalDimen != null) {
    if (resizeFactor != null) {
      originalDimen *= resizeFactor;
    }
    const compareArtBoardDimenValue = compareWithWidth ? artBoardWidth : artBoardHeight;
    const artBoardScreenDimenRatio = (originalDimen * 100) / compareArtBoardDimenValue;
    let compareCurrentScreenDimenValue = compareWithWidth ? screenWidth : screenHeight - extraSpace;
    return PixelRatio.roundToNearestPixel((artBoardScreenDimenRatio * compareCurrentScreenDimenValue) / 100,);
  }
  return;
};
export interface PlacesAutocompleteProps {
  getInputProps?: any;
  suggestions?: any;
  getSuggestionItemProps?: any;
  loading: any;
}
export default class MyBusinessProfile extends MyBusinessProfileController {
  mentionPlugin: any;
  editor: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    const { } = this.state;
    this.mentionPlugin = createMentionPlugin();
    // Customizable Area End
  }

  // Customizable Area Start

  renderPledgeTracking = () => {
    return (
      <Card bordered={false}>
        <p data-testid='textlabelheadingText' className="textlabel_headingText" >Profile Update Status</p>
        <div>
          <p><Text style={{ fontSize: 14, marginBottom: 12, color: 'rgb(196, 196, 196)', textTransform: 'capitalize' }}>profile set Up <span style={{ fontSize: 18, color: "#4080c0" }}>{this.state.pledgeTracking.completion_percent}%</span> done</Text></p>
        </div>
        <Row>
          <Col span={18} xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
            <div style={{ width: '90%' }}>
              <Progress
                strokeColor="linear-gradient(#00536c, #007491)"
                percent={this.state.pledgeTracking.completion_percent}
                showInfo={false} />
            </div>
          </Col>
          <Col span={6} xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
            <div style={{
              color: "#4080c0",
              cursor: 'pointer'
            }}
              onClick={() => this.props.history.push({ pathname: '/edit/profile/business' })}
            >Complete Your Profile</div>
          </Col>
        </Row>
      </Card>
    )
  }


  renderUSerProfile = () => {
    var startDate = moment("2018-10-10", "YYYY.MM.DD"), // $('[name="date-start"]').val() === "13.04.2016"
      endDate = moment("2020-11-12", "YYYY.MM.DD"); // $('[name="date-end"]').val() === "28.04.2016"

    var diff = startDate.diff(endDate);

    // console.log( 'date-->',moment(diff).format('E') );
    const { userProfile } = this.state
    return (
      <>
        <Card bordered={false}>
          <Row className="myProfie_RenderUser" style={{ alignItems: 'center', padding: '6px 0px' }}>
            <Col>
              <Avatar src={userProfile && userProfile?.photo} size={65} style={{ backgroundColor: "grey", marginLeft: 6 }} />
            </Col>
            <Col style={{ marginLeft: 12 }}>
              <Text style={{ fontWeight: 600, fontSize: "16px", color: "rgba(0, 0, 0, 0.85)", textTransform: "capitalize" }}>
                {userProfile?.company_name}
              </Text><br />
              <span style={{ fontWeight: 500, fontSize: "14px", color: "rgba(0, 0, 0, 0.85)" }}>
                {
                  userProfile?.business_industry ?
                    userProfile?.business_industry
                    : null
                }
              </span><br />
              <p>
                {this.state.loginUser.role &&
                  this.state.loginUser.role !== "personal" &&
                  userProfile?.business ? (
                  <Text style={{ fontSize: '13px' }}>
                    {userProfile?.business +
                      ", " +
                      userProfile?.business_industry}
                  </Text>
                ) : null}
                {userProfile ? (
                  <Text style={{ color: "#757575", fontSize: '13px' }}>
                    {this.getDomainSkills(userProfile?.domain_skills)}
                  </Text>
                ) : null}
                <br />
                {this.state.loginUser.role &&
                  this.state.loginUser.role !== "personal" &&
                  userProfile &&
                  userProfile.attributes &&
                  userProfile.attributes.location ? (
                  <Text style={{ color: "#757575", fontSize: '13px' }}>
                    {userProfile.attributes.location}
                  </Text>
                ) : null}
                {userProfile?.role !== "personal" &&
                  userProfile?.location ? (
                  <Text style={{ color: "#757575", fontSize: '13px' }}>
                    {userProfile?.location}
                  </Text>
                ) : null}
              </p>

              <p>
                {" "}
                {userProfile?.experience_years ? (
                  <Text style={{ fontSize: 16 }}>{userProfile?.experience_years}+ years of experience</Text>
                ) : null}
              </p>
            </Col>
          </Row>
        </Card>
      </>
    );
  };
  //@ts-ignore
  //editpostrender
  rendereditPostTrue = () => {
    return (
      this.state.tagsOpen ?
        <Col
          xs={24}
          xl={12}
          md={12}
          sm={12}
        >
          {this.state.tagsOpen &&
            <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
              <img src={atIcon} alt="" width={20} height={20} />
              <Mentions
                data-testid="Tag1"
                onSelect={(value) => this.onSelectTags(value)}
                onChange={(value) => this.onChangeTags(value)}
                style={{ border: 'none' }}
                placement="top"
              >
                {this.state.followers && this.state.followers.map((followersItemData: any) => (
                  <Option
                    key={followersItemData?.attributes?.account_id}
                    value={followersItemData?.attributes?.role === "business" ?
                      followersItemData?.attributes?.profile?.data?.attributes?.company_name :
                      followersItemData?.attributes?.full_name
                    }
                    style={{
                      padding: "4px",
                      height: '100%',
                      maxHeight: 280,
                      minWidth: '260px',
                      width: '100%',
                      marginBottom: 5,
                      borderBottom: '1px solid #BDBDBD',
                    }}  >
                    <div style={{
                      // padding: 8
                    }}>
                      <span>
                        <Avatar
                          src={followersItemData?.attributes?.photo}
                        />
                      </span>
                      <span style={{ marginLeft: '15px' }}>
                        {followersItemData?.attributes?.role === "business" ?
                          followersItemData?.attributes?.profile?.data?.attributes?.company_name :
                          followersItemData?.attributes?.full_name
                        }
                      </span>
                    </div>
                  </Option>
                ))
                }
              </Mentions>
            </div>
          }
        </Col>
        :
        <Col
          xs={24}
          xl={12}
          md={12}
          sm={12}
        >
          {this.state.location && this.getUserLocattion()}
        </Col>
    )
  }
  rendereditPost = () => {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin, emojiPlugin];
    const mentions = this.state.followers?.map(function (followersItem: any) {
      return ({
        name: ` @${followersItem?.attributes?.full_name} `,
        avatar: followersItem?.attributes?.photo,
        userId: followersItem?.id
      })
    })
    const onSearchChange = ({ value }: { value: any }) => {
      this.setState({
        suggestions: defaultSuggestionsFilter(value, mentions)
      });
    };
    const focus = () => {
      this.editor.focus();
    };
    return (
      <div style={{ borderWidth: "0px", borderRadius: 4 }}>
        <div className='editor' onClick={focus}>
          <Editor
            data-testid='renderEditPost'
            editorState={this.state.createPostDescription}
            //@ts-ignore
            handleBeforeInput={this.handleBeforeInput}
            //@ts-ignore
            handlePastedText={this.handlePastedText}
            onChange={this.onChangeText}
            placeholder="Text here..."
            plugins={plugins}
            ref={(element) => {
              this.editor = element;
            }}
          />
          <MentionSuggestions
            onSearchChange={onSearchChange}
            suggestions={this.state.suggestions}
            onOpenChange={this.onOpenChange}
            open={this.state.open}
          />
        </div>

        <Card style={{ borderWidth: "0px" }}>
          <Row style={{ padding: "0px" }}>
            {!this.state.location && <div style={{ marginLeft: 5, marginTop: 10, display: 'flex', alignItems: 'center' }}>
              {this.state.address && <img src={locationIcon} width={14} />}
              <Text style={{ color: "#757575", marginLeft: 18, fontSize: 14, fontWeight: 600 }}>  {this.state.address && this.state.address}</Text>
            </div>}
            {this.state.getMentionsArray.length !== 0 &&
              <div style={{ marginLeft: 5, marginTop: 10, display: 'flex', alignItems: 'center' }}>
                {
                  this.state.getMentionsArrayList.map((item: any, index: any) => <Text key={index} style={{ color: "#757575", marginLeft: 18, fontSize: 14, fontWeight: 600 }}>@{item}{this.state.getMentionsArray.length - 1 > index ? ", " : null}</Text>)
                }
              </div>}
            <EmojiSuggestions />
            {this.state.isEmojiPickerEnable && <Picker onEmojiClick={this.onEmojiClick} />}
            {this.state.AllMedia.length ?
              <Row style={{ paddingTop: "10px", width: '100%' }}>
                {this.renderMedia(this.state.AllMedia)}
              </Row> : null}
            {
              this.state.bodyText && <Row style={{ padding: "10px", }}>
                <Text style={{ color: "red" }}>Body can't be blank</Text>
              </Row>
            }
            {(this.state.editortext).length > 1000 ? (
              this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan == "free" &&
              <Row style={{ padding: "10px", }}>
                <Text style={{ color: "red" }}>
                  You have used your 1000 characters and one media per post.Please
                  upgrade to premium account to avail benefits to post without
                  any word or media limit.
                  <Text onClick={() => { this.props.history.push({ pathname: '/Premium/Account' }) }}
                    style={{ color: "#005574", marginLeft: "10px", cursor: "pointer" }}>
                    {"Upgrade to premium account."}
                  </Text>
                </Text>
              </Row>
            ) : null}
          </Row>
        </Card>
        <Card
          style={{
            // position: "absolute",
            width: "100%",
            top: "26px"
          }}
        // className="MyProfile_CreatePost"
        >
          {this.state.isloader ? <Spin /> :
            <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
              {
                this.state.tagsOpen || this.state.location ?
                  this.rendereditPostTrue()
                  :
                  <Col
                    xs={24}
                    xl={12}
                    md={12}
                    sm={12}
                    style={{ padding: "10px" }}
                  >
                    {this.state.data?.account_plan == "free" && <Text
                      style={{
                        fontSize: "14px",
                        marginRight: "5px",
                        color: "grey",
                      }}
                    >{(this.state.editortext).length > 1000 ? "0 characters left" : `${1001 -
                      (this.state.editortext).length} characters left`}</Text>
                    }
                  </Col>
              }
              <Col
                // className='MyProfile_createPost_Button'
                xs={24}
                sm={12}
                md={12}
                xl={12}
                style={{
                  display: "flex", justifyContent: "flex-end",
                  padding: "10px", width: '100%'
                }}
              ><Upload
              name='file'
              showUploadList={false}
              multiple={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={(file) => this.beforeUploadAttachment(file)}
              onChange={this.handleImageChange}
            >
              <Button
                className="post_btn"
                type="ghost"
                shape="circle"
                icon={<AttachmentOutlinedIcon color="disabled" style={{ transform: "rotate(-45deg)" }} width={20} />}
                disabled={this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" && this.state.AllMedia.length === 1}
              />
            </Upload>
                <EmojiSelect />
                <Upload
                  data-testid='fileUploadEditPost'
                  name='file'
                  showUploadList={false}
                  multiple={this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" ? false : true}
                  maxCount={this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" ? 1 : 5}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={(file) => this.beforeUpload(file)}
                  onChange={this.handleImageChange}
                >
                  <Button
                    className="post_btn"
                    shape="circle"
                    disabled={
                      this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" ?
                        this.state.AllMedia.length == 1 ? true : false : false
                    }
                    icon={<img src={photoIcon} width={20} />}
                  />
                </Upload>
                <Button
                  className="post_btn"
                  type="ghost"
                  shape="circle"
                  onClick={() => this.setState({
                    tagsOpen: !this.state.tagsOpen,
                    location: false,
                    tagged_connection_ids: []
                  })}
                  icon={<img src={atIcon} width={20} />}
                />
                <Button
                  className="post_btn"
                  type="ghost"
                  shape="circle"
                  icon={<img src={locationIcon} width={14} />}
                  onClick={() => {
                    this.setState({
                      location: !this.state.location,
                      tagsOpen: false,
                    })
                  }}
                />
                <Button
                  data-testid="EditSubmit"
                  className="post_btn"
                  type="ghost"
                  shape="circle"
                  disabled={this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" && this.state.editortext.length > 1001}
                  icon={<img src={sendIcon} width={"100%"} />}
                  onClick={() => this.handleEditSubmit(this.state.editData?.id)}
                />
              </Col>
            </Row>}
        </Card>
      </div>
    );
  };
  //@ts-ignore

  renderAboutContainer = () => {
    return (
      this.state.userProfile?.company_overview
        ?
        (<Card bordered={false}>
          <Col style={{ marginLeft: 12, marginTop: 12 }} >
            <p><Text style={{ fontSize: 14, fontWeight: "bold" }}>About</Text></p>
            <p><Text style={{ fontSize: 12, marginBottom: 12, color: 'rgb(196, 196, 196)' }}>{this.state.userProfile?.company_overview}</Text></p>
          </Col>
        </Card>)
        : ""
    )
  };

  renderCreatePostPopupTrue = () => {
    return (
      this.state.tagsOpen ?
        <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
          <img src={atIcon} alt="" width={20} height={20} />
          <Mentions
            data-testid="Tag2"
            onChange={(value) => this.onChangeTags(value)}
            onSelect={(value) => this.onSelectTags(value)}
            placement="top"
            style={{ border: 'none' }}
          >
            {this.state.followers && this.state.followers.map((followersItem: any) => (
              <Option
                value={followersItem?.attributes?.role === "business" ?
                  followersItem?.attributes?.profile?.data?.attributes?.company_name :
                  followersItem?.attributes?.full_name
                }
                key={followersItem?.attributes?.account_id}
                style={{
                  borderBottom: '1px solid #BDBDBD',
                  marginBottom: 5,
                  width: '100%',
                  minWidth: '260px',
                  maxHeight: 280,
                  height: '100%',
                  padding: "4px"
                }}
              >
                <div style={{ padding: 8 }}>
                  <span>
                    <Avatar
                      src={followersItem?.attributes?.photo}
                    />
                  </span>
                  <span style={{ marginLeft: '15px' }}>
                    {followersItem?.attributes?.role === "business" ?
                      followersItem?.attributes?.profile?.data?.attributes?.company_name :
                      followersItem?.attributes?.full_name
                    }
                  </span>
                </div>
              </Option>
            ))
            }
          </Mentions>
        </div>
        :
        <div>
          {this.state.location && this.getUserLocattion()}
        </div>
    )
  }
  renderCreatePostPopup = () => {
    return (<Modal
      data-testid="createpost"
      centered
      bodyStyle={{
        height: "100%",
        width: "100%",
        padding: "0px 23px 10px 23px"
      }}
      title={
        <Space
          style={{
            color: "#414141",
            fontWeight: "bold",
            cursor: "move",
            width: "100%",
          }}
        >
          Create New Post
        </Space>
      }
      onCancel={() => this.handlecreatePostOpenCancel()}
      visible={this.state.createPostOpen}
      footer={
        <Row
          style={{
            borderTop: "1px solid #f0f0f0",
            textAlign: "left",
            padding: "20px",
          }}
        >
          <Col
            span={12}
          >
            {
              this.state.location || this.state.tagsOpen ?
                this.renderCreatePostPopupTrue()
                :
                <div>
                  {this.state.data?.account_plan == "free" && <Text
                    style={{
                      fontSize: "14px",
                      marginRight: "5px",
                      color: "grey",
                    }}
                  >{(this.state.editortext).length > 1000 ? "0 characters left" : `${1001 -
                    (this.state.editortext).length} characters left`}</Text>
                  }
                </div>
            }
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Upload
              name='file'
              showUploadList={false}
              multiple={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={(file) => this.beforeUploadAttachment(file)}
              onChange={this.handleImageChange}
            >
              <Button
                className="post_btn"
                type="ghost"
                shape="circle"
                icon={<AttachmentOutlinedIcon color="disabled" style={{ transform: "rotate(-45deg)" }} width={20} />}
                disabled={
                  this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" && this.state.AllMedia.length === 1
                }                
              />
            </Upload>
            <EmojiSelect />
            <Upload
              name='file'
              showUploadList={false}
              multiple={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={(file) => this.beforeUpload(file)}
              onChange={this.handleImageChange}
            >
              <Button
                className="post_btn"
                type="ghost"
                shape="circle"
                icon={<img src={photoIcon} width={20} />}
                disabled={
                  this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" ?
                    this.state.AllMedia.length == 1 ? true : false : false
                }
              />
            </Upload>
            <Button className="post_btn"
              type="ghost"
              shape="circle"
              onClick={() => this.setState({
                tagsOpen: !this.state.tagsOpen,
                location: false
              })}
              icon={<img src={atIcon} width={20} />}
            />
            <Button
              className="post_btn"
              type="ghost"
              shape="circle"
              icon={<img src={locationIcon} width={14} />}
              onClick={() => {
                this.setState({
                  tagsOpen: false,
                  location: !this.state.location
                })
              }}
            />
            <Button
              className="post_btn"
              data-testid="submitPostBtn"
              type="ghost"
              shape="circle"
              disabled={this.state.data?.account_plan === "free" && this.state.editortext.length > 1001}
              icon={<img src={sendIcon} width={"100%"} />}
              onClick={() => this.handleSubmit()}
            />
          </Col>
        </Row>
      }
    >
      {this.renderCreatePost()}
    </Modal>
    )
  }

  //editpostpopup
  renderEditPostPopup = () => {
    return (<Modal
      footer={null}
      bodyStyle={{
        width: "100%",
        height: "100%",
        maxHeight: "60vh",
        minHeight: "40vh",
        overflowY: "auto"
      }}
      className="Edit_Modal_Layout"
      centered
      title={
        <Space
          style={{
            width: "100%",
            cursor: "move",
            fontWeight: "bold",
            color: "#414141"
          }}
        >
          Edit the post
        </Space>
      }
      visible={this.state.editPostOpen}
      onCancel={() => this.handleEditPostCancel()}
    >
      {this.rendereditPost()}
    </Modal>
    )
  };

  renderPostConnectionDetails = () => {
    const postData = [
      {
        key: "posts",
        count: this.state.profileData?.attributes?.posts_count,
        image: postsImgsIcon,
        description: "UI/Ux Designer Community",
        nav: 1
      },
      {
        key: "connections",
        count: this.state.profileData?.attributes?.friends_count,
        image: connectionsIcon,
        description: "UI/Ux Designer Community",
        nav: '/myNetwork'
      },
      {
        key: "followers",
        count: this.state.profileData?.attributes?.followers_count,
        image: followersIcon,
        description: "UI/Ux Designer Community",
        nav: '/Followers/Following',
        keyValue: "1"
      },
      {
        key: "following",
        count: this.state.profileData?.attributes?.following_count,
        image: followingIcon,
        description: "UI/Ux Designer Community",
        nav: '/Followers/Following',
        keyValue: "2"
      },
      {
        key: "Ratings Received",
        count: this.state.profileData?.attributes?.ratings_received,
        image: ratingsReceivedIcon,
        description: "UI/Ux Designer Community",
        nav: '/Rating',
        keyValue: "2"
      },
      {
        key: "Ratings Sent",
        count: this.state.profileData?.attributes?.ratings_given,
        image: ratingSentIcon,
        description: "UI/Ux Designer Community",
        nav: '/Rating',
        keyValue: "1"
      },

    ];
    const premiumData = [
      {
        key: "Resume Downloads",
        image: <FileTextOutlined />,
        keyValue: "1",
        count: this.state.userAnalytics?.cv_downloaded_users_count,
        description: "UI/Ux Designer Community",
        nav: "/UserAnalytics",
      },
      {
        key: "Rating Users",
        nav: "/UserAnalytics",
        keyValue: "2",
        count: this.state.userAnalytics?.rated_users_count,
        image: <StarOutlined />,
        description: "UI/Ux Designer Community",
      },
      {
        nav: "/UserAnalytics",
        key: "Review Given",
        count: this.state.userAnalytics?.reviewers_count,
        keyValue: "3",
        image: <EditOutlined />,
        description: "UI/Ux Designer Community",
      },
      {
        count: this.state.userAnalytics?.visited_users_count,
        key: "Profile Visit",
        description: "UI/Ux Designer Community",
        image: <UserOutlined />,
        nav: "/UserAnalytics",
        keyValue: "4"
      },
    ]
    if(this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "premium"){
      postData.push(...premiumData)
    }
    return (
      <Card style={{ borderRadius: 4 }}>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Text style={{ fontSize: 14, fontWeight: "bold" }}>Stats</Text>
        </div>
        <div>
          <List
            grid={{ gutter: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 2, xxl: 2 }}
            dataSource={postData}
            className="stats_responsive"
            renderItem={(item) => (
              <List.Item style={{ cursor: "pointer" }}
                // onClick={() => this.handleFollowersAndFollowingOpen(item.key)}
                onClick={() => item.nav === 1 ?
                  this.setState({ defaultActiveKey: 1 })
                  :
                  this.props.history.push({
                    //@ts-ignore
                    pathname: `${item.nav && item.nav}`, state: { keyValue: item?.keyValue ? item?.keyValue : '' }
                  })
                }
              >
                <List.Item.Meta
                  avatar={
                    <div className={"post-connection-content ant-row-middle"}>
                      <Avatar style={{ padding: "5px",justifyContent:"center",display:"flex" }} src={item.image} size={40} />
                    </div>
                  }
                  title={<h4 className="post-connection-title" style={{ overflow: "hidden" }}>{item.key.charAt(0).toUpperCase() + item.key.slice(1)}</h4>}
                  description={
                    <h2 className="post-connection-count">
                      <b>{item.count ? item.count : "0"}</b>
                    </h2>
                  }
                  style={{ height: 64, justifyContent: 'center', alignItems: "center", backgroundColor: "rgb(221,230,235)", marginRight: "5px", borderRadius: "4px" }}
                />
              </List.Item>
            )}
          />
        </div>
      </Card>
    );
  };

  showReportModal = () => {
    return (
      <Modal
        width={350}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        // placement="bottomRight"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px" }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Report Post
          </div>
        }
        visible={this.state.isReportIssueVisible}
        onCancel={this.handleCancel}
        footer={[
          <Button size="large" className="report-button" style={{ width: "100%" }} key="report" onClick={this.handleOk}>
            Report
          </Button>,
        ]}
      >
        <Radio.Group onChange={this.onChangeValue} value={this.state.value} buttonStyle="outline">
          <Space direction="vertical">
            <Radio style={{ color: "grey" }} value={1}>
              Fraude or pyramid Marketing
            </Radio>
            <Radio style={{ color: "grey" }} value={2}>
              Promoting/Selling illegal or regulated goods
            </Radio>
            <Radio style={{ color: "grey" }} value={3}>
              Promotes Terrorism
            </Radio>
            <Radio style={{ color: "grey" }} value={4}>
              Discrimination, Harrassment or Bullying
            </Radio>
            <Radio style={{ color: "grey" }} value={5}>
              Child Abuse
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    )
  };

  renderPost = (item: any) => {
    if (this.state.isPostCreateLoading) {
      return <></>;
    }
    return (
      <Suspense
        fallback={
          <Card
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </Card>
        }
      >
        <div style={{ paddingTop: "20px", display: this.state.isPostCreateLoading ? "none" : "block"}}>
          <CustomPost
            data-testid="renderPostProp"
            menu={{ isCommunity: false }}
            handlePostList={this.handlePostList}
            loginUserId={this.state?.loginUser?.id ? this.state?.loginUser?.id : this.state?.userProfile?.account_id}
            data={item?.attributes}
            handleMenuClick={(e: any) => this.handleMenuClick(e, item.id)}
            isFollowed={this.state.followedItems.includes(item.attributes?.account?.data?.id)}
            toggleFollow={() => this.onClickFollow(item.attributes?.account?.data?.id, this.state.followedItems.includes(item.attributes?.account?.data?.id))}
            handleEditPostOpen={(id: any) => this.handleEditPostOpen(id)}
          />
        </div>
      </Suspense>
    );
  };

  renderRating = () => {
    return (
      <div>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Text style={{ fontWeight: 'bold' }}>Ratings {this.state.shiftRatings === 1 ? "Received" : "Send"}</Text>
          {/* <Col>
                  <Text style={{marginRight: 20}}>Ratings Received</Text>
                  <Text>Rating Send</Text>
                  </Col> */}
          {this.state.shiftRatings === 2 &&
            <div style={{ width: 185, marginLeft: 50 }}>
              <Input
                placeholder="Search Sent Ratings"
                //@ts-ignore
                prefix={<SearchOutlined />}
                style={{ border: "none", borderRadius: 50, height: 30, background: '#c1dbef' }}
              />
            </div>
          }
          <Radio.Group onChange={this.ratingOnchange} value={this.state.shiftRatings}>
            <Space>
              <Radio value={1}>Ratings Received</Radio>
              <Radio value={2}>Rating Send</Radio>
            </Space>
          </Radio.Group>
        </Row>
        {this.state.shiftRatings === 2
          ?
          <Row style={{ justifyContent: 'space-between', alignItems: 'center', paddingTop: 20 }} >
            <Row style={{ alignItems: 'center' }}>
              <Col>
                <Avatar src={this.state.loginUser.profile_pic ? this.state.loginUser.profile_pic : require("../../assets/image_profile.png")} size={40} style={{ backgroundColor: "grey" }} />
              </Col>
              <Row justify="center" style={{ paddingTop: "0px", paddingLeft: 10, paddingBottom: 10 }}>
                <p style={{ width: "100%", marginBottom: "0", fontSize: 12, fontWeight: 600 }}><Text type="secondary"> you have rated</Text> Jason Price<Text type="secondary"> as</Text> leader</p>
                <br />
                <Text style={{ width: "100%", fontSize: 12 }} type="secondary">13 days ago</Text>
              </Row>
            </Row>
            <Button type="primary" ghost style={{ borderRadius: 30 }} onClick={() => { this.props.navigation.navigate("RatingSent") }}>
              View Ratings
            </Button>
          </Row>
          :
          <>
            <Row style={{ justifyContent: 'space-between', alignItems: 'center', paddingTop: 20 }} >
              <Row style={{ alignItems: 'center' }}>
                <Col>
                  <Avatar src={this.state.loginUser.profile_pic ? this.state.loginUser.profile_pic : require("../../assets/image_profile.png")} size={40} style={{ backgroundColor: "grey" }} />
                </Col>
                <Row justify="center" style={{ paddingTop: "0px", paddingLeft: 10, paddingBottom: 10 }}>
                  <p style={{ width: "100%", marginBottom: "0", fontSize: 12, fontWeight: 600 }}>Jason Price <Text type="secondary"> has rated you as</Text> Google</p>
                  <br />
                  <Text style={{ width: "100%", fontSize: 12 }} type="secondary">13 days ago</Text>
                </Row>
              </Row>
            </Row>
          </>
        }
        <Divider />
      </div>
    )
  };
  renderPremiumModal = () => {
    return (
      <Modal
        visible={this.state.premiumModal}
        onCancel={() => this.setState({
          premiumModal: false
        })}
        width={350}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        // placement="bottomRight"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px" }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Premium Account
          </div>
        }
        footer={null}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column", gap: "20px" }}>
          <Text>Your account have already premium subscription</Text>
          <Button size="large" className="report-button" style={{ width: "100%", fontSize: "14px" }} key="report" onClick={() => {
            this.setState({
              premiumModal: false
            })
          }}>
            Cancel
          </Button>
        </div>


      </Modal>
    )
  }
  renderReviews = () => {
    const getDomainSkills = (domain_skills: any) => {
      if (
        isEmpty(domain_skills)
      )
        return "";
      const domainSkillsPresent = domain_skills;
      const domainSkillsStr = domainSkillsPresent.join(", ");
      return domainSkillsStr;
    }
    return (
      <div>
        {this.state.myReviews &&
          this.state.myReviews.length !== 0 ? this.state.myReviews?.map((rev: any) =>
            <div className="follow_main" key={rev.id}>
              <div className="review_content">
                <div className='d-f'>
                  <div className="follow_profile">

                    <Avatar src={rev?.attributes?.reviewer ?
                      rev?.attributes?.reviewer?.data?.attributes?.photo :
                      createProfile

                    }
                      style={{ background: "gray" }}
                      size={40} />
                    <div style={{ marginLeft: 10 }}>
                      <Text style={{ fontSize: 16, fontWeight: "bold", color: '#4B4B4B' }}>
                        {
                          rev.attributes?.reviewer?.data?.attributes ?
                            rev.attributes?.reviewer?.data?.attributes?.role === "business" ?
                              rev.attributes?.reviewer?.data?.attributes?.profile?.data?.attributes?.company_name :
                              rev.attributes?.reviewer?.data?.attributes?.full_name
                            : "Anonymous"
                        }
                      </Text>
                      <div>
                        {rev.attributes?.reviewer?.data?.attributes?.designation && <Text style={{ fontWeight: 500, color: '#4f4f4f', fontSize: 14 }}>
                          {rev.attributes?.reviewer?.data?.attributes?.designation?.title}
                          <span style={{ fontWeight: 500, color: '#D4D4D4', fontSize: 14 }}> at </span>
                          {rev.attributes?.reviewer?.data?.attributes?.designation?.company}
                          <span style={{ fontWeight: 500, color: '#D4D4D4', fontSize: 14 }}> | </span>
                        </Text>}
                        {rev.attributes?.reviewer?.data?.attributes?.designation?.profile?.data?.attributes?.domain_skills ?
                          <Text style={{ fontWeight: 400, color: '#4f4f4f', fontSize: 14 }}>
                            {" "}
                            {getDomainSkills(rev.attributes?.reviewer?.data?.attributes?.designation?.profile?.data?.attributes?.domain_skills)}
                          </Text> : null
                        }
                      </div>
                    </div>
                  </div>
                  <div className=''>
                    <span style={{ fontWeight: 400, color: '#D4D4D4', fontSize: 14 }}>
                      {moment(rev.attributes?.created_at).format("Do")}{" "}
                      {moment(rev.attributes?.created_at).format("MMMM")},
                      {moment(rev.attributes?.created_at).format("YYYY")}</span>
                  </div>
                </div>
                <div style={{ marginTop: 10 }}>
                  <h4>{rev.attributes?.title}</h4>
                  <p>{rev.attributes?.description}</p>
                </div>
              </div>
            </div>
          )
          : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }

      </div>
    )
  };
  rendericon = () => {
    return <CloseOutlined />
  }
  renderImage = (id: any, isFullHeight: boolean) => {
    if (this.state.AllMedia[id]?.content_type) {
      return (
        <Card style={{ width: 'auto', padding: 3, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px' }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.deleteItem(id)} icon={this.rendericon()} />
          <Image src={this.state.AllMedia[id].url} height={isFullHeight ? 380 : 180}
            style={{ borderRadius: '4px', backgroundColor: 'grey' }}
            wrapperStyle={{ backgroundSize: 'cover' }} />
        </Card>
      )
    } else {
      return (
        <Card style={{ width: 'auto', padding: 3, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px' }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.onRemoveImage(id)} icon={this.rendericon()} />
          <Image src={this.state.AllMedia[id].filePath} height={isFullHeight ? 380 : 180}
            style={{ borderRadius: '4px', backgroundColor: 'grey' }}
            wrapperStyle={{ backgroundSize: 'cover' }} />
        </Card>
      )
    }
  }
  renderVideo = (id: any, isFullHeight: boolean) => {
    if (this.state.AllMedia[id]?.content_type) {
      return (
        <Card style={{ width: '100%', padding: 3, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px', backgroundColor: "#FAFAFA" }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.deleteItem(id)} icon={this.rendericon()} />
          <ReactPlayer url={this.state.AllMedia[id]?.url}
            controls
            height={isFullHeight ? 380 : 180}
            width={'98%'}
            style={{ borderRadius: '4px', backgroundColor: "#FAFAFA" }} />
        </Card>
      )
    } else {
      return (
        <Card style={{ width: '100%', padding: 3, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px', backgroundColor: "#FAFAFA" }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.onRemoveImage(id)} icon={this.rendericon()} />
          <ReactPlayer url={this.state.AllMedia[id].filePath} controls
            height={isFullHeight ? 380 : 180}
            width={'98%'}
            style={{ borderRadius: '4px', backgroundColor: "#FAFAFA" }} />
        </Card>
      )
    }
  }
  checkMedia = (id: any, isFullHeight: boolean) => {
    if (this.state.AllMedia[id]?.content_type) {
      return this.state.AllMedia[id].content_type.slice(0, 5) == "image" ? this.renderImage(id, isFullHeight) : this.renderVideo(id, isFullHeight);
    } else {
      return this.state.AllMedia[id].type.slice(0, 5) == "video" ? this.renderVideo(id, isFullHeight) : this.renderImage(id, isFullHeight);
    }
  }

  renderMedia = (imgArray: any) => {
    switch (imgArray.length) {
      case 0:
        return null;
      case 1:
        return (
          <Row style={{ padding: 5, width: '100%' }} >
            {/* <Col span={24}  > */}
            {this.checkMedia(0, false)}
            {/* </Col> */}
          </Row>
        )
      case 2:
        return (
          <Row className="flex-row">
            <Col span={12} >{this.checkMedia(0, false)}</Col>
            <Col span={12} >{this.checkMedia(1, false)}</Col>
          </Row>
        )
      case 3:
        return (
          <Row className="flex-row"  >
            <Col span={12}>{this.checkMedia(0, true)}</Col>
            <Col style={{ flexDirection: 'column' }} span={12} >
              {this.checkMedia(1, false)}
              {this.checkMedia(2, false)}
            </Col>
          </Row>
        )
      case 4:
        return (
          <div >
            <Row style={{ width: '100%' }}>
              <Col span={12} >{this.checkMedia(0, false)}</Col>
              <Col span={12} >{this.checkMedia(1, false)}</Col>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col span={12} >{this.checkMedia(2, false)}</Col>
              <Col span={12} > {this.checkMedia(3, false)}</Col>
            </Row>
          </div>
        )
      default:
        return (
          <div >
            <Row style={{ width: '100%' }}>
              <Col span={12}>{this.checkMedia(0, false)}</Col>
              <Col span={12}>{this.checkMedia(1, false)}</Col>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col span={12}>{this.checkMedia(2, false)}</Col>
              <Col span={12}> {this.checkMedia(3, false)}
                <Button size='large' type='text' style={{ marginTop: -100 }} >{`+${this.state.AllMedia.length - 3} More`} </Button>
              </Col>
            </Row>
          </div>
        )
    }
  };

  handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    this.setState({ address: value });
  };

  //oncopylinkforinvitefriends
  oncopyInviteFrdiends = () => {
    this.setState({ isModalInviteFriends: false })
    navigator.clipboard.writeText("https://appliedapp.page.link/welcome")
    message.info("Url Copied")
  }

  //@ts-ignore
  onEmojiClick = (event, emojiObject) => {
    const ref = this.contentRef.current
    ref.focus()
    const start = this.state.createPostDescription.substring(0, ref.selectionStart)
    const end = this.state.createPostDescription.substring(ref.selectionStart)
    const content = start + emojiObject.emoji;
    this.setState({ createPostDescription: content })
    ref.selectionEnd = start.length + emojiObject.emoji.length
  };

  onSelect = (option: any) => {
    let t = this.state.tagged_connection_ids;
    t.push(option.key)
    this.setState({ tagged_connection_ids: t })

  }

  onChangeText = (editorState: any) => {
    this.setState({ createPostDescription: editorState });
    const contentState = editorState?.getCurrentContent();
    const raw = convertToRaw(contentState);
    if (raw?.blocks[0]?.text.trim().length > 0) {
      this.setState({
        bodyText: false
      })
    }
    const name = raw?.blocks.map((line: any) => line?.text);

    var stringValue = ""
    name.map((item: any) => {
      stringValue = stringValue + item + "\n"
      return stringValue
    })
    this.setState({ editortext: stringValue });


    const raws = convertToRaw(contentState);
    let mentionedUsers = [];
    for (let key in raws?.entityMap) {
      const ent = raws?.entityMap[key];
      if (ent.type === "mention") {
        mentionedUsers.push(ent?.data.mention);
      }
    }
    const mentiontag = mentionedUsers.map(function (followersItem: any) {
      return followersItem?.userId
    })
    let t = this.state.tagged_connection_ids;
    t.push(...mentiontag)
    this.setState({ tagged_connection_ids: t });
  };

  onOpenChange = (_open: any) => {
    this.setState({
      open: _open
    });
  };

  //newsSubscribe
  submitNewsSubscribe = () => {
    this.NewsletterSubscribe(this.state.newsEmail)
  }
  //newsUnSubscribe
  submitNewsUnSubscribe = () => {
    this.NewsletterUnSubscribe()
    this.setState({ isModalNewsletter: false })
  }
  //editchange
  onEditChange = (value: any) => {

    this.setState({ createPostDescription: value });

  };
  //tags
  onSelectTags = (option: any) => {
    let mention = this.state.selectTags;
    mention.push({ value: option.value, key: option.key });
    this.setState({ selectTags: mention })
  }
  onChangeTags = (value: any) => {
    const data = value.split("@").filter((item: string) => {
      if (item) {
        return item.trim();
      } else {
        return null;
      }
    })
    let getMention = getMentions(value);
    this.setState({
      getMentionsArray: getMention,
      getMentionsArrayList: data
    });
  };
  getLengthOfSelectedText = () => {
    const currentSelection = this.state.createPostDescription.getSelection();
    const isCollapsed = currentSelection.isCollapsed();
    let length = 0;
    if (!isCollapsed) {
      const currentContent = this.state.createPostDescription.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;
        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }
          currentKey = currentContent.getKeyAfter(currentKey);
        };
      }
    }

    return length;
  }

  handleBeforeInput = () => {
    if (this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free") {
      const currentContent = this.state.createPostDescription.getCurrentContent();
      const currentContentLength = (currentContent.getPlainText('')).length;
      const selectedTextLength = this.getLengthOfSelectedText();
      if (currentContentLength - selectedTextLength >= 1001 - 1) {
        // this.infoComingSoon()
        return 'handled';
      }
    }
  }

  handlePastedText = (pastedText: string | any[]) => {
    if (this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free") {
      const currentContent = this.state.createPostDescription.getCurrentContent();
      const currentContentLength = (currentContent.getPlainText('')).length;
      const selectedTextLength = this.getLengthOfSelectedText();
      if (currentContentLength + pastedText.length - selectedTextLength > 1000) {
        // this.infoComingSoon()
        return 'handled';
      }
    }
  }

  renderCreatePost = () => {
    const { userProfile } = this.state;
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin, emojiPlugin];
    const mentions = this.state.followers?.map(function (followersItem: any) {
      return ({
        name: ` @${followersItem?.attributes?.full_name} `,
        avatar: followersItem?.attributes?.photo,
        userId: followersItem?.attributes?.account_id
      })
    })
    const onSearchChange = ({ value }: { value: any }) => {
      this.setState({
        suggestions: defaultSuggestionsFilter(value, mentions)
      });
    };
    const focus = () => {
      this.editor.focus();
    };
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "10px"
      }}>
        <div className='editor' onClick={focus}>
          <Editor
            editorState={this.state.createPostDescription}
            //@ts-ignore
            handleBeforeInput={this.handleBeforeInput}
            //@ts-ignore
            handlePastedText={this.handlePastedText}
            onChange={this.onChangeText}
            placeholder="Text here..."
            plugins={plugins}
            ref={(element) => {
              this.editor = element;
            }}
          />
          <MentionSuggestions
            onSearchChange={onSearchChange}
            suggestions={this.state.suggestions}
            onOpenChange={this.onOpenChange}
            open={this.state.open}
          />
        </div>
        {
          this.state.address ?
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <img src={locationIcon} width={10} />
              <Text
                style={{
                  color: "#757575",
                  fontSize: 14,
                  fontWeight: 600,
                  marginLeft: "5px"
                }}
              >
                {this.state.address}
              </Text>
            </div> : null
        }
        {this.state.getMentionsArray.length !== 0 &&
          <div>
            {
              this.state.getMentionsArrayList.map((item: any, index: any) => <Text
                key={index}
                style={{
                  color: "#757575",
                  fontSize: 14,
                  fontWeight: 600
                }}
              >
                @{item}{this.state.getMentionsArray.length - 1 > index ? "," : null}{" "}
              </Text>)
            }
          </div>}
        <EmojiSuggestions />
        {this.state.AllMedia.length ?
          this.renderMedia(this.state.AllMedia)
          : null}
        {
          this.state.bodyText ?
            <Text style={{ color: "red" }}>Body can't be blank</Text>
            : null
        }

        {(this.state.editortext).length > 1000 && this.state.data?.account_plan == "free" ? (
          <Text style={{ color: "red" }}>
            You have used your 1000 characters and one media per post. Please
            upgrade to premium account to avail benefit to post without
            any word or media limit.
            <Text onClick={() => { this.props.history.push({ pathname: '/Premium/Account' }) }}
              style={{ color: "#005574", marginLeft: "10px", cursor: "pointer" }}>
              {"Upgrade to Premium Account."}
            </Text>
          </Text>
        ) : null}
      </div>
    );
  };
  //@ts-ignore

  renderCardTitle = () => {
    return (
      <div className="title-avatar">
        <div>
          <Avatar size={64} src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fencrypted-tbn0.gstatic.com%2Fimages%3Fq%3Dtbn%3AANd9GcQjzC2JyZDZ_RaWf0qp11K0lcvB6b6kYNMoqtZAQ9hiPZ4cTIOB&psig=AOvVaw021e1-4qRqx_miJjzBCRaV&ust=1646294370129000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCKDOi8r6pvYCFQAAAAAdAAAAABAD" />
        </div>
        <div style={{ lineHeight: 1.2 }}>
          <Text className="title-text" style={{ fontSize: '20px' }}>Google</Text><br />
          <Text className="title-text">Software Development</Text><br />
          <Text className="title-text" style={{ fontSize: '12px', opacity: 0.5, color: 'gray' }}>San Diego, California, US</Text>
        </div>
      </div>
    )
  }

  onChange = (value: any) => {
    let data = this.state.createPostDescription + value

    this.setState({ createPostDescription: value });
  };

  //locations
  getUserLocattion = () => {
    return (
      <div style={{ position: 'relative' }}>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={(address) => this.setState({ address })}
          onSelect={this.handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }: PlacesAutocompleteProps) => (
            <div>
              <div className="autocomplete-dropdown-container">
                {/* {loading ? <div>...loading</div> : null} */}
                {<div style={{
                  // position: 'fixed',
                  boxShadow: "0px 2px 7px 0px #888888",
                  maxHeight: '120px',
                  overflowY: "auto"
                }}>
                  {suggestions.map((suggestion: { terms: any; formattedSuggestion: any; active: any; description: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                      padding: '14px',
                      borderBottom: '1px solid rgb(0, 0, 0, 0.05)'
                    };
                    return (
                      <div className="input-suggestion"
                        {...getSuggestionItemProps(suggestion, { style })}>
                        <img src={locationIcon} width={14} />
                        <span style={{ paddingLeft: "1rem" }}>
                          {/* {suggestion.terms[suggestion.terms.length - 2].value}, {suggestion.terms[suggestion.terms.length - 1].value} */}
                          {
                            suggestion.terms.length == 1 ? suggestion.terms[0].value :
                              suggestion.terms.length == 2 ? suggestion.terms[0].value + ", " + suggestion.terms[1].value :
                                suggestion.terms.length >= 3 && suggestion.terms[suggestion.terms.length - 2].value + ", " + suggestion.terms[suggestion.terms.length - 1].value
                          }
                        </span>
                      </div>
                    );
                  })}
                </div>}
              </div>
              <div className="input-location">
                <img src={locationIcon} width={14} />
                <input {...getInputProps({
                  // placeholder: "Type address",
                  className: 'location-search-input',
                })} />

              </div>

            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }

  renderColor = (item: any) => {
    return item.value <= 75 ? "#3a87a8" : "#3c9b83"
  }

  getProfileRatingViewsPercentageColor = (item: any) => {
    return item.value <= 50 ? "#b24040" : this.renderColor(item);
  };

  getProfileRatingAppliedProgressBarColor = (item: any) => {
    return item.score < 59 ? "#b24040" : this.renderColor(item);
  };
  

  renderRatingTab = () => {
    const progressCircleBarData = [
      {
        id: 1,
        title: "Customer View",
        value: this.state.data?.rating ? Math.round(this.state.data.rating.data?.attributes?.rating['Customer'][this.state.data.rating.data?.attributes?.rating?.['Customer']?.length-1]?.score) : 0,
        size: 120,
        ratingName: "Customer Rating",
        subTitle: "Customer View",
        data: this.state.data?.rating?.data?.attributes?.rating['Customer'],
        ratingdata: "Customer"
      },
      {
        id: 2,
        title: "Supplier View",
        value: this.state.data?.rating ? Math.round(this.state.data.rating.data?.attributes?.rating['Supplier'][this.state.data.rating.data?.attributes?.rating?.['Supplier']?.length-1]?.score) : 0,
        size: 120,
        ratingName: "Supplier Rating",
        subTitle: "Supplier View",
        data: this.state.data?.rating?.data?.attributes?.rating['Supplier'],
        ratingdata: "Supplier"
      },
      {
        id: 3,
        title: "Employees View",
        value: this.state.data?.rating ? Math.round(this.state.data.rating.data?.attributes?.rating['Employee'][this.state.data.rating.data?.attributes?.rating?.['Employee']?.length-1]?.score) : 0,
        size: 120,
        ratingName: "Employees Rating",
        subTitle: "Employees View",
        data: this.state.data?.rating?.data?.attributes?.rating['Employee'],
        ratingdata: "Employee"
      },
      {
        id: 4,
        title: "Community View",
        value: this.state.data?.rating ? Math.round(this.state.data.rating.data?.attributes?.rating['Community'][this.state.data.rating.data?.attributes?.rating?.['Community']?.length-1]?.score) : 0,
        size: 120,
        ratingName: "Community Rating",
        subTitle: "Community View",
        data: this.state.data?.rating?.data?.attributes?.rating['Community'],
        ratingdata: "Community"
      }
    ];
    return (
      <Card
        className="rating_receive"
        data-testid="rating_tab"
      >
        <Row gutter={[16, 16]}>
          {
            progressCircleBarData.map((item: any, index: number) => {
              return (<Col
                xs={{ span: 24 }}
                md={{ span: 6 }}
                key={item.id}
                sm={{ span: 12 }}
                lg={{ span: 6 }}
                style={{ backgroundColor: "#f1f0f5", display: 'flex', justifyContent: 'center' }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    background: "white",
                    borderRadius: '4px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    width: "248px",
                    height: "244px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  data-testid={`view_box${index}`}

                  onClick={() => {
                    this.setState({
                      updateQuestionnaire: { ...item }
                    })
                  }}
                >
                  <ProfileRatingViewsPercentage
                    subTitle={item.subTitle}
                    title={item.title}
                    value={item.value}
                    color={this.getProfileRatingViewsPercentageColor(item)}
                    size={item.size}
                  />
                </div>
              </Col>)
            })
          }
          {this.state.data?.rating &&
            <Col
              data-testid=""
              xs={{ span: 24 }}
              lg={{ span: 24 }}
              style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
            >
              <div
                style={{ width: "100%", alignItems: "center", display: "flex", flexDirection: "row", justifyContent: "space-between" }}
              >
                <Title
                  style={{ fontSize: "14px", color: "#414141" }}
                  level={5}
                >
                  Applied View
                </Title>
                <Title
                  style={{ fontSize: "13px", color: "#414141" }}
                  level={5}
                >
                  Applied Score: {`${Math.round(this.state.data.rating?.data?.attributes?.rating['Applied View Rating'][this.state.data.rating.data?.attributes?.rating?.['Applied View Rating']?.length-1]?.score)}%`}
                </Title>
              </div>
              <Card
                style={{ width: "100%", height: "100%", borderRadius: "5px" }}
              >
                {
                  this.state.generalBusinessRatingData.map((item: any, id: number) => {
                    if (item.name) {
                      return (
                        <div key={id} >
                          <ProfileRatingAppliedProgressBar
                            key={id}
                            description={item.description}
                            title={item.name}
                            value={item.score === null || this.state.data.ratings_received <= 5 ? 0 : Math.trunc(item.score)}
                            color={this.getProfileRatingAppliedProgressBarColor(item)}
                          />
                        </div>
                      )
                    } else {
                      return null
                    }
                  })
                }


              </Card>
            </Col>
          }

        </Row>
      </Card>
    )
  }
  renderSubscribeUnSubscribe = () => {
    return (
      <Modal title={this.state.isNewsLetterSubscription ? "UnSubscribe to our Newsletter" : "Subscribe to our Newsletter"}
        onCancel={() => this.setState({ isModalNewsletter: false })}
        footer={null}
        data-testid="newsLetterSubscription"
        onOk={() => this.setState({
          isModalNewsletter: false
        })}
        visible={this.state.isModalNewsletter} >
        {
          // this.state.isNewsLetterSubscription === null ? <Spin /> :
          this.state.isNewsLetterSubscription
            ?
            <>
              <p style={{ fontSize: 18, color: '#d9d9d9' }}>You have already Subscribed!</p>
              <Button
                style={{ width: 180, paddingLeft: 35, textAlign: "center" }}
                className="model-button-copy"
                data-testid="unSubscribe"
                htmlType="submit"
                onClick={() => this.submitNewsUnSubscribe()}
              >
                UnSubscribe
              </Button>
            </>
            :
            <>
              {
                this.state.newsubscribe
                  ? (<>
                    <p
                      style={{ fontSize: 16, color: '#B8B8B8' }}>Thank You!</p>
                    <p style={{ fontSize: 16, color: '#B8B8B8' }}>Before receiving Newsletter from applied, you need to confirm your email address.</p>
                    <p
                      style={{ fontSize: 16, color: '#B8B8B8' }}>Please check your mailbox.</p>
                  </>)
                  : (<>
                    <p
                      style={{ fontSize: 16, color: '#B8B8B8' }}>
                      Get handpicked articles once a month to get latest news from your industry</p>
                    <Form
                      name="basic"
                      // initialValues={{ anonymous: false }}
                      autoComplete="off"
                      onFinish={(value) => console.log(value, "value of subscribe")}
                      data-testid="form_basic"
                      onFinishFailed={onFinishFailed}
                      layout='inline'
                      style={{
                        alignItems: "baseline",
                        justifyContent: "space-between",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Form.Item
            rules={[{ required: true, message: "Required field" },{ type: 'email', message: 'Please enter a valid email address' },]}
            name="email"
            getValueProps={() => {
              return this.state.newsEmail
            }}
          >
            <Input
              name="email"
              value={this.state.newsEmail}
              type="email"
              size="large"
              style={{ width: 285,border:"1px solid #d9d9d9", height:"50px",color:"#4080c0",borderRadius:"50px" }}
              placeholder="Enter email address"
              onChange={(e) => this.setState({ newsEmail: e.target.value })}
            />
          </Form.Item>

                      <Form.Item>
                        <Button
                          htmlType="submit"
                          onClick={() => this.submitNewsSubscribe()}
                          data-testid="subcribButton"
                          style={{ width: 150, paddingLeft: 35 }}
                          className="model-button-copy"
                        >
                          Subscribe
                        </Button>
                      </Form.Item>
                    </Form>
                  </>)
              }
            </>
        }
      </Modal>
    )
  }

  renderProfileQuestionnaireTabsWeb = () => {
    if (this.state.data?.rating) {
      return (
        <>
          <Col xs={24} md={24} xl={24} lg={24} >
            <div style={{ background: '#fff', height: '100%', width: '100%' }}>
              <div style={{ background: "rgb(245, 245, 245)", padding: '8px 24px' }}>
                <h3 style={{ color: "#1890ff", fontSize: 14, }}>{this.state.updateQuestionnaire?.ratingName}</h3>
              </div>
              {this.state.updateQuestionnaire?.data?.slice(0, this.state.updateQuestionnaire?.data?.length - 1).map((item: any) => {
                return (
                  <div key={item} style={{ padding: '0px 24px', display: 'flex', alignItems: 'center', marginTop: 16 }}>

                    <Box position="relative" display="inline-flex"
                    >
                      <Progress
                        type="circle"
                        strokeColor={item.score < 59 ? "#b24040" : this.getItemScore(item)}
                        strokeWidth={9}
                        width={50}
                        data-testid={{}}
                        className="rating-progress"
                        percent={Math.round(item.score)}
                        style={{
                          cursor: 'pointer',
                          fontSize: 11,
                          fontWeight: 600
                        }}
                      />
                      {item?.description &&
                            <Tooltip
                            title={ <div
                              dangerouslySetInnerHTML={{
                                __html: item?.description.replace(/\n/g, '<br />')
                              }}
                            />}
                             >
                                <ExclamationCircleOutlined
                                    style={{ marginLeft: "0.4rem", cursor: "pointer", color: "#919191"}}
                                />
                            </Tooltip>}
                    </Box>
                    <h3 style={{ marginLeft: '20px', color: "rgba(0, 0, 0, 0.6)", fontSize: 14, fontWeight: 'bold' }}>{item?.name}</h3>
                  </div >
                )
              })}
              <div style={{ textAlign: "end" }}>
                {this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan == "premium" && <Button
                  htmlType="submit"
                  style={{ margin: "10px 15px 15px 15px" }}
                  data-testid="edit_questionnaire"
                  className="model-button-copy"
                  onClick={() => this.props.history.push({ pathname: '/myProfile/updateQuestionnaire', state: { title: this.state.updateQuestionnaire?.ratingdata } })}
                >
                  Edit Questionnaire
                </Button>}
              </div>
            </div>
          </Col>
        </>
      )
    } else {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    }
  }
  getItemScore = (item: any) => {
    return item.score < 79 ? "#3a87a8" : "#3c9b83"
  }

  checkPremiumUser = ()=>{
    if(this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan !== "premium"){
       this.setState({isModel:true})
    }else{
      this.props.history.push({
        pathname: "/myProfile/updateQuestionnaire",
      })
    }    
   }

   renderPopup =() =>{
    return  <Modal
                width={375}
                style={{
                    borderRadius: "4px",
                }}
            
                onCancel={()=>{this.setState({isModel:false})}}
                footer={null}
                visible={this.state.isModel}
            
                bodyStyle={{
                    marginTop: '12px',
                    textAlign: 'center',
                    paddingBottom: '16px',
                }}
           >
               <>        
                    <img  src={infoIcon}
                       style={{
                           fontSize: '44px',
                           paddingBottom: '16px',
                           color: '#00536c',
                           width:"60px",
                           height:"60px"
                       }}        
                   />
                   <Typography style={{ fontSize: 18, fontWeight: 'bold', textTransform: "capitalize",marginBottom:"8px" }}>
                       Please upgrade to premium account
                   </Typography>
                   <div style={{ display:"flex",flexDirection:"column", gap:"8px"}}>
                   <Button onClick={()=>this.goToUpgrade()} style={{background:"#00536c",color:"white",padding:"4px",borderRadius:"20px",height:"40px"}}>Upgrade</Button>
                   <Button  onClick={()=>this.setState({isModel:false})} style={{color:"#00536c",padding:"4px",borderRadius:"20px",height:"40px"}}>Cancel</Button>
                   </div>       
               </>
            </Modal>
   }
  // Customizable Area End
  render() {
    const { navigation } = this.props;
    const handleChangeTab = (value: any) => {
      this.setState({ defaultActiveKey: value });
    }
    const url = "https://appliedapp.page.link/welcome"
    return (
      <div className="background" style={{ backgroundColor: "#f6f6f6" }}>
        {this.redirectUser()}
        {/* <CustomHeader {...this.props} dotVisible={false} /> */}
        <Row style={{ paddingTop: "15px", marginBottom: 7, marginLeft: 16 }}>
          <Text
            className="textlabel_subheadingText"
          >
            My Profile
          </Text>
        </Row>
        <Row style={{ backgroundColor: "#f6f6f6" }}>
          <Col span={6} xs={24} sm={24} md={24} lg={7} xl={8} xxl={8} style={{ paddingLeft: "16px", paddingRight: '16px' }}>
            <p className="textlabel_headingText" >My Profile</p>
            {this.renderUSerProfile()}
            <Divider />
            {this.renderAboutContainer()}
            {this.renderPostConnectionDetails()}
            <Card bodyStyle={{ textAlign: "center" }}
              style={{ borderRadius: 4 }}
            >
              <Row style={{ justifyContent: "space-between" }} >
                <Button data-testid="downloadResume" htmlType="submit" shape="round" size={"large"}
                  className="MyBusiness_Download" style={{ paddingTop: "0px", paddingBottom: '0px' }}
                  onClick={()=>this.checkPremiumUser()}                  
                >
                  {this.state.downloadResumeLoading ? <Spin /> : <span style={{ padding: '0px 0px', fontSize: "90%" }}>Update Questionnaire</span>}
                </Button>
                <Button htmlType="submit" shape="round" size={"large"}
                  className="MyBusiness_Update" style={{}}
                  data-testid="edit_profile"
                  onClick={() => this.props.history.push({ pathname: '/edit/profile/business' })}
                >
                  <span style={{ fontSize: "90%" }}>Edit Profile</span>
                </Button>

                {this.renderSubscribeUnSubscribe()}
                <Modal
                  data-testid='InviteFriends'
                  title="Invite Friends"
                  footer={null}
                  onOk={() => this.setState({ isModalInviteFriends: false })}
                  onCancel={() => this.setState({ isModalInviteFriends: false })}
                  visible={this.state.isModalInviteFriends} >
                  {/* <p style={{ fontSize: 18, color: '#d9d9d9' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eros risus, bibendum id faucien nec.</p> */}
                  <Row style={{ justifyContent: "space-around", marginLeft: 20, marginRight: 20 }} >
                    <LinkedinShareButton
                      url={window.location.origin}
                      title="Inviting you"
                      summary="Description of the shared page"
                      source="Source of the content (e.g. your website or application name)"
                    >
                      <Col>
                        {/* @ts-ignore */}
                        <div className="model-img">
                          <img src={linkdinIcon} alt="img" />
                        </div>
                        <p style={{ fontSize: 18, color: '#d9d9d9' }} >Linkedin</p>
                      </Col>
                    </LinkedinShareButton>

                    <WhatsappShareButton
                      url={url}
                      title="Inviting you"
                      separator=""
                    >
                      <Col>
                        {/* @ts-ignore */}
                        <div className="model-img">
                          <img src={whatsappIcon} alt="img" />
                        </div>
                        <p style={{ fontSize: 18, color: '#d9d9d9' }} >Whatsapp</p>
                      </Col>
                    </WhatsappShareButton>

                    <FacebookShareButton
                      url={url}
                      quote="Inviting you"
                      hashtag=""
                    >
                      <Col>
                        {/* @ts-ignore */}
                        <div className="model-img">
                          <img src={facebookIcon} alt="img" />
                        </div>
                        <p style={{ fontSize: 18, color: '#d9d9d9' }} >facebook</p>
                      </Col>
                    </FacebookShareButton>

                    <EmailShareButton
                      url={url}
                      subject="Inviting you into our web"
                      body=""
                      separator=""
                    >
                      <Col>
                        {/* @ts-ignore */}
                        <div className="model-img">
                          <img src={gmailIcon} alt="img" />
                        </div>
                        <p style={{ fontSize: 18, color: '#d9d9d9' }} >Gmail</p>
                      </Col>
                    </EmailShareButton>
                  </Row>
                  <Row style={{ justifyContent: "space-around", marginBottom: 10 }} >
                    <Col style={{ width: 300 }}><Input placeholder={url} className="model-input" value={url} /></Col>
                    <Col style={{ width: 150 }}><Button data-testid='copyInviteFriends' className="model-button-copy" onClick={this.oncopyInviteFrdiends}>Copy Url</Button></Col>
                  </Row>
                </Modal>
                {!this.state.isModalInviteFriends && !this.state.isModalNewsletter  &&<Dropdown
                  className="MyProfile_Dropdown_css"
                  data-testid="Dropdown"
                  overlay={
                    <ul className="dropdown" style={{ listStyleType: "none" }}>
                      <li><a data-testid="Premium_Account" onClick={() => {
                        if (this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan == "free") {
                          this.goToUpgrade()
                        } else {
                          this.setState({
                            premiumModal: true
                          })
                        }
                      }}>Premium Account</a></li>
                      <li><a data-testid="SavedPosts" onClick={() => this.props.history.push('/MySaved/Posts')} >My Saved Posts</a></li>
                      <li><a data-testid="HiddenPosts" onClick={() => this.props.history.push('/hide/Posts')} >My Hidden Posts</a></li>
                      <li><a data-testid="Anniversaries" onClick={() => this.props.history.push({
                        //@ts-ignore
                        pathname: '/upcoming/anniversaries'
                      }
                      )}>Anniversaries</a></li>
                      <li><a data-testid='NewsletterSubscription' onClick={() => this.setState({ isModalNewsletter: true })}>Newsletter Subscription</a></li>
                      <li><a data-testid='InviteFriends' onClick={() =>
                        this.setState({ isModalInviteFriends: true })
                      }
                      >Invite Friends</a></li>
                    </ul>
                  } placement="bottomRight" trigger={['click']}>
                  <a onClick={e => e.preventDefault()}
                  >
                    <Space>
                      <img src={three_dot} className="Dropdown_image_myProfile" />
                    </Space>
                  </a>
                </Dropdown>}
              </Row>
            </Card>
            <button data-testid="Submitbtn" className="create-new-post" onClick={() => this.handlecreatePostOpen()}>Create New Post</button>
          </Col>
          <Col span={12} xs={24} sm={24} md={24} lg={17} xl={14} xxl={14}>
            {this.state.pledgeTracking?.completion_percent && this.state.pledgeTracking.completion_percent !== 100 &&
              <div style={{ padding: '0px 0px 18px' }}>
                {this.renderPledgeTracking()}
              </div>
            }
            <Tabs
              data-testid="ChangeTab"
              activeKey={`${this.state.defaultActiveKey}`}
              centered={true}
              size="large"
              tabBarStyle={{
                color: "#919191",
                backgroundColor: "#dee5eb",
                height: "3rem"
              }}
              style={{
                borderRadius: "3px",
                backgroundColor: "#f1f0f5",
                paddingBottom: 13
              }}
              onChange={(e) => handleChangeTab(e)}
            >
              <TabPane
                tab="Posts"
                key="1"
                style={{ width: "96%", height: "100%", margin: "auto" }}
              >
                <List data-testid="renderpost" dataSource={this.state.postList} className="home-posts" renderItem={this.renderPost} />
                {this.state.postList?.length > 0 && this.state.showLoadMore && <>{this.state.showLoadMoreLoading ? <Spin /> : <div
                  data-testid="homeFeedPost"
                  style={{
                    cursor: 'pointer',
                    marginTop: "10px"
                  }}
                  onClick={async () => {
                    await this.setState({
                      perpageCountForPost: this.state.perpageCountForPost + 1
                    })
                    this.getHomeFeedPosts()
                  }}>Load more post</div>
                }
                </>
                }
              </TabPane>
              <TabPane
                tab="Ratings"
                key="2"
                style={{
                  width: "96%",
                  height: "100%",
                  margin: "auto"
                }}
              >
                {this.renderRatingTab()}
                {this.state.updateQuestionnaire?.data && this.renderProfileQuestionnaireTabsWeb()}
              </TabPane>
              <TabPane
                tab="Reviews"
                key="3"
                style={{ width: "96%", height: "100%", margin: "auto" }}
              >
                {this.renderReviews()}
              </TabPane>
            </Tabs>

            {(this.state.isCreatePostVisible || this.state.AllMedia.length != 0) ? this.renderCreatePost() : null}
            {this.showReportModal()}
          </Col>
          {this.renderCreatePostPopup()}
          {this.renderEditPostPopup()}
          {this.renderPremiumModal()}
        </Row>
        {this.renderPopup()}
      </div>
    );
  }
}
// Customizable Area Start
// Customizable Area End
