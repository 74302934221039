import React, { Component } from "react";
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import confirm from "../../alert/src/alert.web";
import { RouterProps } from "react-router";
import { Redirect } from "react-router-dom";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Dimensions } from "react-native";
import { isInteger } from "lodash";
export const configJSON = require("./config");
const { width, height } = Dimensions.get("window");
// console.log(height, width);
// Customizable Area End
export interface Props extends RouterProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  match: any;
  // Customizable Area End
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  button: string;
  isRemember: boolean
  countryCode: any | string;
  isMobile: boolean;
  countryCodePlaceholder: any | string
  isErrorModalShow: boolean
  errorContent: any;
  redirect: boolean;
  isLoading: boolean;
  previousLoginData: {
    email: string,
    password: string
  }
  // Customizable Area Start
  postId: any;
  loginBannerImageId: string | null;
  loginBannerImage: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class LoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createAccountApiCallId: any;
  GetProfielInformationId: any;
  getLoginBannerImageApiCallId = '';
  formref = React.createRef();
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: false,
      isRegistration: true,
      data: this.props.history ? this.props.history.location.state : "",
      button: "business",
      isRemember: false,
      countryCode: "+91",
      countryCodePlaceholder: '🇮🇳 | +91',
      isMobile: false,
      isErrorModalShow: false,
      errorContent: {},
      redirect: false,
      isLoading: true,
      previousLoginData: {
        email: "",
        password: ""
      },
      postId: null,
      loginBannerImageId: '',
      loginBannerImage: ''
    };
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    let token = localStorage.getItem('token');
    if (token && token !== null && token !== undefined) {
      const tokenParts = token.split('.');
      const payload = JSON.parse(atob(tokenParts[1]));
      const currentTime = Math.floor(Date.now() / 1000);
      if (payload.exp < currentTime) {
        this.setState({
          isLoading: false
        });
      } else {
        this.setState({
          redirect: true,
          isLoading: false
        })
      }
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false
        });
      }, 1000);
    }
    let loginData = await getStorageData("RememberCredential");
    if (loginData) {
      if (Number.isInteger(parseInt(loginData?.email))) {
        this.setState({
          previousLoginData: loginData,
          isMobile: true
        })
      } else {
        this.setState({
          previousLoginData: loginData,
          isMobile: false
        })
      }
    }
    let id = this.props.location.state;
    let idd = await localStorage.getItem("sharePost");
    this.setState({ postId: id });
    this.getLoginBannerImage();
  }
  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to='/home' />;
    }
  };
  handleOnChange = ({ target: { value } }: { target: any }) => {
    if (parseInt(value).toString() !== value) {
      this.setState({ isMobile: false });
    } else {
      this.setState({ isMobile: true });
    }
  }
  onChange = (e: any) => {
    // console.log('checked = ', e.target.checked);
    this.setState({
      isRemember: e.target.checked,
    });
  };

  handleCloseModal = () => {
    this.setState({ isErrorModalShow: false, errorContent: {} })
  }

  getLoginBannerImage = () => {
    const header = {
      'Content-Type': 'application/json',
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLoginBannerImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLoginBannerImageAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.setState({
      loginBannerImageId: requestMessage.id,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  accountCreationProfileSetup = () => {
    this.setState({
      isErrorModalShow: true,
      errorContent: {
        title: "Success",
        isError: false,
        body: "Login successfully",
      },
    });
    if (this.state.postId) {
      this.props.history.push({
        pathname: `/viewPost/${this.state.postId}`
      });
    } else {
      this.props.history.push({
        pathname: "/home",
        state: this.state.data,
      });
    }
  }

  accountCreationErrorResponse = (createAccountResponseJsonData: any) => {
    this.setState({ data: createAccountResponseJsonData.errors[0] });
    if (
      createAccountResponseJsonData &&
      createAccountResponseJsonData.errors &&
      createAccountResponseJsonData.errors[0] &&
      (!createAccountResponseJsonData.errors[0].email_otp_verified ||
        !createAccountResponseJsonData.errors[0].sms_otp_verified)
    ) {
      if (
        createAccountResponseJsonData.errors[0] && createAccountResponseJsonData.errors[0]?.email_otp_verified != undefined &&
        !createAccountResponseJsonData.errors[0].email_otp_verified
      ) {
        this.setState({ isErrorModalShow: true, errorContent: { title: "error", isError: true, body: createAccountResponseJsonData.errors[0].failed_login } })
        setStorageData("isFromLogin", true);
        this.props.history.push({
          pathname: "/email/varification",
          state: { ...this.state.data, isFromLogin: true },
        });
      } else if (
        createAccountResponseJsonData.errors[0] && createAccountResponseJsonData.errors[0]?.email_otp_verified === true
      ) {
        this.setState({ isErrorModalShow: true, errorContent: { title: "error", isError: true, body: createAccountResponseJsonData.errors[0].failed_login } })
        setStorageData("isFromLogin", true);
        this.props.history.push({
          pathname: "/phone/varification",
          state: { ...this.state.data, isFromLogin: true },
        });
      } else if (createAccountResponseJsonData.errors[0] && createAccountResponseJsonData.errors[0]?.sms_otp_verified != undefined && !createAccountResponseJsonData.errors[0].sms_otp_verified) {
        this.setState({ isErrorModalShow: true, errorContent: { title: "error", isError: true, body: createAccountResponseJsonData.errors[0].failed_login } })
        this.props.history.push({
          pathname: "/phone/varification",
          state: {
            ...this.state.data,
            isFromLogin: true,
          },
        });
      } else {
        this.setState({ isErrorModalShow: true, errorContent: { title: "error", isError: true, body: createAccountResponseJsonData.errors[0].failed_login } })
      }
    }
  }

  createAccountApiReceiveCall = async (createAccountResponseJsonData: any) => {
    this.setState({ loading: false })
    if (createAccountResponseJsonData?.token) {
      localStorage.setItem("token", createAccountResponseJsonData.token);
      await setStorageData('loginUser', createAccountResponseJsonData);
      await setStorageData('role', createAccountResponseJsonData.role);
      if (createAccountResponseJsonData.profile_setup) {
        this.accountCreationProfileSetup();
      }
      else if (
        !createAccountResponseJsonData.profile_setup &&
        createAccountResponseJsonData.role == "business"
      ) {
        this.props.history.push({
          pathname: "/select/industry",
          state: this.state.data,
        });
      } else {
        let data = { name: createAccountResponseJsonData.first_name + " " + createAccountResponseJsonData.last_name, email: createAccountResponseJsonData.email }
        this.props.history.push({
          pathname: "/setup/profile/personal",
          state: data,
        });
      }
    } else if (createAccountResponseJsonData.errors) {
      this.accountCreationErrorResponse(createAccountResponseJsonData);
    } else {
      console.log("something went wrong");
    }
  };

  getLoginBannerImageApiReceiveCall = async (getLoginBannerImageResponseJsonData: any) => {
    if (getLoginBannerImageResponseJsonData.data) {
      this.setState({
        loginBannerImage: getLoginBannerImageResponseJsonData.data[0].attributes.image
      });
    } else {
      this.setState({
        isErrorModalShow: true,
        errorContent: {
          title: "Error",
          isError: true,
          body: "Unable to load Login banner image!"
        }
      });
    }
  };

  handleLogin = (data: {} | any) => {
    if (this.state.isRemember) {
      setStorageData("RememberCredential", { email: data.email, password: data.password })
    } else {
      removeStorageData("RememberCredential")
    }
    this.setState({ loading: true })
    const header = {
      "Content-Type": "application/json",
    };

    const isPN = !isNaN(Number(data.email.toString()));
    console.log("handle Login", isPN, data.email);
    let attrs = {
      data: { type: isPN ? "sms_account" : "email_account" },
      full_phone_number: isPN ? this.state.countryCode + data.email : null,
      email: !isPN ? data.email : null,
      password: data.password,
    };


    const httpBody = attrs;
    // const httpBody = data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createAccountApiCallId) {
          await this.createAccountApiReceiveCall(responseJson);
        } else if (apiRequestCallId == this.getLoginBannerImageApiCallId) {
          this.getLoginBannerImageApiReceiveCall(responseJson);
        }
      } else {
        console.log("something went wrong");
      }
    }
    if (message.id === getName(MessageEnum.CountryCodeMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
      if (apiRequestCallId.isValue) {
        this.setState({
          countryCodePlaceholder: `${apiRequestCallId.result.flag}   | +${apiRequestCallId.result.value
            }`, countryCode: `+${apiRequestCallId.result.value}`
        });
      }
    }
  }
  // Customizable Area End
}
