import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
import { RouterProps } from "react-router";
import { createRef } from "react";
import { Redirect, useHistory } from "react-router-dom";

export const configJSON = require("./../config");
export interface Props extends RouterProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isBusinessUser: boolean;
  token: string | null;
  userProfile: any;
  loginUser: any;
  accountDetails: any;
  redirect: any;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UpgradeAccountController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetAccountDetailsApiId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      isBusinessUser: false,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      loginUser: {},
      // @ts-ignore
      userProfile: getStorageData("userProfile")
        ? getStorageData("userProfile")
        : {},
      accountDetails: {},
      redirect: true,
      isLoading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }

    let data: any = await getStorageData("loginUser").then((res) => {
      this.setState({
        loginUser: res,
        isBusinessUser: res.role === "business",
      });
      console.log("loginUser", res)
    });
    await getStorageData("userProfile").then((res) => {
      console.log("res-->", res);
      this.setState({ userProfile: res });
    });
    await getStorageData("accountData").then((res) => {
      console.log("accountDetails-->", res);
      if (res) {
        this.getAccountDetails(res.attributes?.account?.data?.attributes?.account_id)
      }
    });
  }

  // redirectUser() {
  //   if (this.state.redirect) {
  //     return <Redirect to="/" />;
  //   }
  // }


  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.GetAccountDetailsApiId) {
          await setStorageData("accountData", responseJson.data)
          this.setState({ accountDetails: responseJson.data, isLoading: false });
          console.log("resss----->", this.state.accountDetails)
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }

    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );
    }

    // Customizable Area End
  }

  // Customizable Area Start

  getAccountDetails = async (id: string) => {
    this.setState({ isLoading: true });
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAccountDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAccountDetailsEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAccountDetailsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("getAccount--->" + JSON.stringify(requestMessage));
    return true;
  };

  // Customizable Area End
}
