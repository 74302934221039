import React from "react";
import { imgPasswordInVisible, checkedIcon, levelUp, workStation, editCommunity, addCommunity, createProfile, faceIcon, photoIcon, locationIcon, atIcon, sendIcon, blogIcon } from "./assets";
import CommonListItem from "./commonListItem.web";
import "./userFeed.css";
import {
  Card,
  Row,
  Col,
  Modal,
  Input,
  Typography,
  Avatar,
  Button,
  Upload,
  Empty,
  List,
  Menu,
  Dropdown,
  message,
  Form,
  Select,
  Space,
  Radio,
  Image,
  Spin,
  Skeleton,
  Mentions
} from "antd";
import CustomPost from "./CustomPost.web";
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import CommunityController, { Props } from "./CommunityController.web";
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  EllipsisOutlined,
  LoadingOutlined,
  CheckOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ReactPlayer from 'react-player';
import Picker from 'emoji-picker-react';
// Customizable Area Start
import CommunityAlert from "./CommunityAlert.web"
import GroupsCreateAndEditCommunityNew from "../../Groups/src/CreateAndEditCommunity.web";
const { Option, getMentions } = Mentions;
// Customizable Area End
const { Title, Text } = Typography;
const { TextArea } = Input;

const data = [
  {
    title: 'Ant Design Title 1',
  },
  {
    title: 'Ant Design Title 2',
  },
  {
    title: 'Ant Design Title 3',
  },
  {
    title: 'Ant Design Title 4',
  },
  {
    title: 'Ant Design Title 4',
  },
  {
    title: 'Ant Design Title 4',
  },
  {
    title: 'Ant Design Title 4',
  },
  {
    title: 'Ant Design Title 4',
  },
];
const postData = [
  {
    key: "posts",
    count: "500",
    image: imgPasswordInVisible,
    description: "UI/Ux Designer Community",
  },
  {
    key: "connection",
    count: "500",
    image: imgPasswordInVisible,
    description: "UI/Ux Designer Community"
  },
  {
    key: "followers",
    count: "2k",
    image: imgPasswordInVisible,
    description: "UI/Ux Designer Community"
  },
  {
    key: "following",
    count: "1100",
    image: imgPasswordInVisible,
    description: "UI/Ux Designer Community"
  }
];

export default class CommunityBlock extends CommunityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    const { } = this.state;
    // Customizable Area End
  }

  infoComingSoon = () => {
    message.info('Coming soon');
  };

  // Customizable Area Start

  // image video loads

  renderImage = (id: any, isFullHeight: boolean) => {
    if (this.state.AllMedia[id]?.content_type) {
      return (
        <Card style={{ width: '100%', padding: 0, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px' }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.deleteItem(id)} icon={<CloseOutlined />} />
          <Image src={this.state.AllMedia[id].url} height={isFullHeight ? 380 : 180} width={'100%'}
            style={{ borderRadius: '4px', backgroundColor: 'grey', width: '100%' }}
            wrapperStyle={{ backgroundSize: 'cover', width: '100%' }} />
        </Card>
      )
    } else {
      return (
        <Card style={{ width: '100%', padding: 0, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px' }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.onRemoveImage(id)} icon={<CloseOutlined />} />
          <Image src={this.state.AllMedia[id].filePath} height={isFullHeight ? 380 : 180} width={'100%'}
            style={{ borderRadius: '4px', backgroundColor: 'grey', width: '100%' }}
            wrapperStyle={{ backgroundSize: 'cover', width: '100%' }} />
        </Card>
      )
    }
  }
  renderVideo = (id: any, isFullHeight: boolean) => {
    if (this.state.AllMedia[id]?.content_type) {
      return (
        <Card style={{ width: '100%', padding: 0, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px', backgroundColor: "#FAFAFA" }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.deleteItem(id)} icon={<CloseOutlined />} />
          <ReactPlayer url={this.state.AllMedia[id]?.url}
            controls
            height={isFullHeight ? 380 : 180}
            width={'98%'}
            style={{ borderRadius: '4px', backgroundColor: "#FAFAFA" }} />
        </Card>
      )
    } else {
      return (
        <Card 
        style={{ 
          padding: 0,
          width: '100%', 
        height: isFullHeight ? 400 : 200,
        borderWidth: 0 }} 
        bodyStyle={{ 
          padding: '0px', 
        backgroundColor: "#FAFAFA" 
        }} >
          <Button 
          style={{ 
          right: 0, 
          backgroundColor: 'grey', 
          top: -5,            
          zIndex: 100, 
          position: 'absolute',
          borderRadius: 20 }} 
          size='small' 
          onClick={() => this.onRemoveImage(id)} icon={<CloseOutlined />} />
          <ReactPlayer 
          url={this.state.AllMedia[id].filePath} 
            height={isFullHeight ? 380 : 180}
            controls
            width={'98%'}
            style={{ 
              borderRadius: '4px', 
              backgroundColor: "#FAFAFA" }} />
        </Card>
      )
    }
  }
  checkMedia = (id: any, isFullHeight: boolean) => {
    // return this.state.AllMedia[id].type.slice(0, 5) == "video" ? this.renderVideo(id, isFullHeight) : this.renderImage(id, isFullHeight)
    if (this.state.AllMedia[id]?.content_type) {
      return this.state.AllMedia[id].content_type.slice(0, 5) == "image" ? this.renderImage(id, isFullHeight) : this.renderVideo(id, isFullHeight);
    } else {
      return this.state.AllMedia[id].type.slice(0, 5) == "video" ? this.renderVideo(id, isFullHeight) : this.renderImage(id, isFullHeight);
    }

  }
  renderMedia = (imgArray: any) => {
    switch (imgArray.length) {
      case 0:
        return null;
      case 1:
        return (
          <Row 
          style={{ 
            width: '100%',
            padding: 5 
            }} >
            {/* <Col span={24}  > */}
            {this.checkMedia(0, false)}
            {/* </Col> */}
          </Row>
        )
      case 2:
        return (
          <Row 
          className="flex-row">
            <Col span={12} >
              {this.checkMedia(0, false)}
            </Col>
            <Col 
            span={12} >
              {this.checkMedia(1, false)}</Col>
          </Row>
        )
      case 3:
        return (
          <Row 
          className="flex-row"  >
            <Col 
            span={12} >
              {this.checkMedia(0, true)}
            </Col>
            <Col 
            style={{ 
              flexDirection: 'column' 
              }} span={12} >
              {this.checkMedia(1, false)}
              {this.checkMedia(2, false)}
            </Col>
          </Row>
        )
      case 4:
        return (
          <div >
            <Row 
            style={{ width: '100%' }}>
              <Col 
              span={12} >
                {this.checkMedia(0, false)}
              </Col>
              <Col 
              span={12} >
                {this.checkMedia(1, false)}
              </Col>
            </Row>
            <Row 
            style={{ width: '100%' }}>
              <Col 
              span={12} >{this.checkMedia(2, false)}</Col>
              <Col 
              span={12} > {this.checkMedia(3, false)}</Col>
            </Row>
          </div>
        )
      default:
        return (
          <div >
            <Row 
            style={{ width: '100%' }}>
              <Col span={12} >
                {this.checkMedia(0, false)}
              </Col>
              <Col span={12} >
                {this.checkMedia(1, false)}
              </Col>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col 
              span={12} >
                {this.checkMedia(2, false)}</Col>
              <Col 
              span={12} >
                {this.checkMedia(3, false)}
                <Button 
                size='large' 
                type='text' 
                style={{ marginTop: -100 }} >
                  {`+${this.state.AllMedia.length - 3} More`} 
                  </Button>
              </Col>
            </Row>
          </div>
        )
    }
  };

  //@ts-ignore
  onEmojiClick = (event, emojiObject) => {
    const ref = this.contentRef.current
    ref.focus()
    const start = this.state.createPostDescription.substring(0, ref.selectionStart)
    const end = this.state.createPostDescription.substring(ref.selectionStart)
    const content = start + emojiObject.emoji;
    this.setState({ createPostDescription: content })
    ref.selectionEnd = start.length + emojiObject.emoji.length
  };

  //render post   
  renderPost = (item: any) => {
    return (
      <>
        <CustomPost
          menu={{
            isCommunity: true,
            isCommunityAdmin: this.state?.toggleCommunityProfile?.attributes?.admin,
            isCommunityJoined: this.state?.toggleCommunityProfile?.attributes?.joined || this.state.joinedCommunities.includes(this.state.toggleCommunityProfile?.id)
          }}
          handlePostList={this.handlePostList}
          loginUserId={this.state?.loginUser?.id ? this.state?.loginUser?.id : this.state?.userProfile?.attributes?.profile?.data?.attributes?.account_id}
          data={item.attributes}
          handleMenuClick={(e: any) => {
            if (e === 'block') {
              this.handleMenuClick(this.state?.toggleCommunityProfile?.id, item.attributes?.account?.data?.id)
            } else {
              this.handleMenuClick(e, item.id)
            }
          }}
          isFollowed={this.state.followedItems.includes(item.attributes?.account?.data?.id)}
          toggleFollow={() => this.onClickFollow(item.attributes?.account?.data?.id, this.state.followedItems.includes(item.attributes?.account?.data?.id))}
          handleEditPostOpen={() => {
            this.handleEdit(item)
          }
          }
        />
      </>
    );
  };

  renderSuccessCommunityPost = () => {
    return (
      <Modal width={400} visible={false} footer={null}>
        <Card
          style={{ textAlign: "center", borderWidth: "0px" }}
          bodyStyle={{ padding: "10px" }}
        >
          <CheckCircleFilled
            style={{
              fontSize: "24px",
              color: "#52c41a",
              paddingTop: "5px",
              paddingBottom: "5px"
            }}
          />
          <Title level={5}>Posted Successfully.</Title>
          <Text>You have posted successfully.</Text>
          <br />
          <Text>Your post will be visible once accepted by admin.</Text>
        </Card>
      </Modal>
    );
  };

  renderCreateAndEditCommunityModal = () => {
    return (
      <GroupsCreateAndEditCommunityNew
        isCreateCommunityVisible={this.state.isCreateCommunityVisible}
        isEditCommunityVisible={this.state.isEditCommunityVisible}
        data={this.state.toggleCommunityProfile}
        handleCancel={this.handleCancelOfCreateAndEditCommunity}
        handleOk={this.handleOkOfCreateAndEditCommunity}
        isCreateCommunity={this.state.isCreateCommunity}
        handleCreateAndEditCommunity={this.handleCreateAndEditCommunity}
      />
    );
  };

  onSelectTags = (option: any) => {
    let mention = this.state.selectTags;
    mention.push({ value: option.value, key: option.key });
    this.setState({ selectTags: mention })
  }

  onChangeTags = (value: any) => {
    let getMention = getMentions(value);
    this.setState({
      getMentionsArray: getMention
    });
  }

  charErrorHandling = (createPostDescription:number) => {
    if (createPostDescription >= 1000) {
      return (
        <Row style={{ padding: "10px" }}>
          <Text style={{ color: "red" }}>
            You have used your 1000 characters and one media per post. Please
            upgrade to premium account to avail benefit to post without
            any word or media limit.
            <Text
              onClick={() => { this.props.history.push({ pathname: '/Premium/Account' }) }}
              style={{ color: "#005574", marginLeft: "10px", cursor: "pointer" }}
            >
              {"Upgrade to Premium Account."}
            </Text>
          </Text>
        </Row>
      );
    }
    return null;
  }
  iconClass = {border: "none", outline: "none",margin:0,padding:0}
  handleFooter = (createPostDescription:string,isEmojiPickerEnable:boolean,account_plan:string,AllMedia:any,tagsOpen:boolean,loadLocation:boolean,isEditPost:boolean) => {
    if(!this.state.isPostloader){
      return(
        <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "16px",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "2px"
              }}
            >
             <div>
              <Text
                style={{
                  fontSize: "14px",
                  marginRight: "5px",
                  color: "grey",
                }}
              >{createPostDescription.length > 1000 ? `0 characters left` : `${1000 -
                createPostDescription.length} characters left`}</Text>
            </div>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            <Upload
                name="avatar"
                multiple
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={(file) => this.beforeUploadAttachment(file)}
                onChange={this.handleImageChange}
              >
                <Button
                  style={{ paddingTop: "8px" }}
                  className="post_btn"
                  type="ghost"
                  shape="circle"
                  icon={<AttachmentOutlinedIcon color="disabled" style={{ transform: "rotate(-45deg)" }} width={20} />}

                />
              </Upload>
              <Button
                style={this.iconClass}
                type="ghost"
                shape="circle"
                icon={
                  <img
                    src={faceIcon}
                    alt=""
                    width={20}
                  />
                }
                onClick={() => this.setState({ isEmojiPickerEnable: !isEmojiPickerEnable })}
              />
              <Button
                style={this.iconClass}
                type="ghost"
                shape="circle"
                onClick={() => this.setState({
                  tagsOpen: !tagsOpen,
                  tagged_connection_ids: []
                })}
                icon={<img
                  src={atIcon}
                  alt=""
                  width={20}
                />}
              />
              <Button style={this.iconClass}
        type="ghost"
        shape="circle"
        icon={<img src={locationIcon} alt="" width={14} />}
        onClick={() => {
          this.setState({
            loadLocation: !this.state.loadLocation,
            postLocationOpen : !this.state.postLocationOpen

          })
        }}
      />
              <Button
                style={this.iconClass}
                type="ghost"
                shape="circle"
                icon={<img
                  src={sendIcon}
                  alt=""
                  width={30}
                />}
                size={"large"}
                htmlType="submit"
                disabled={this.state.isPostloader ? true : false}
                onClick={isEditPost ? () => this.editPostCommunity() : () => this.createPostCommunity()}
              />
              </div>
            </div>
      )
    }
    return null
  }
  renderTitle = () => {
    const { toggleCommunityProfile, userProfile } = this.state;
    const { attributes } = userProfile?.attributes?.profile?.data || {};
    const isAdmin = toggleCommunityProfile?.attributes?.admin;
    const name = attributes?.role === 'business' ? attributes?.company_name : attributes?.full_name;
    let additionalInfo;

    if (isAdmin) {
      additionalInfo = 'Admin';
    } else if (attributes?.business) {
      additionalInfo = `${attributes.business}, ${attributes.business_industry}`;
    } else {
      additionalInfo = this.getDomainSkills(attributes?.domain_skills);
    }
  
    return (
      <Row>
        <Col span={24}>
          <Text strong style={{ fontSize: "16px" }}>
            {name}
          </Text>
        </Col>
        <Col span={24}>
          <Typography.Paragraph style={{ fontSize: "14px", color: "#757575" }}>
            {additionalInfo}
          </Typography.Paragraph>
        </Col>
      </Row>
    );
  }
  renderLocation = () =>(
    this.state.postLocationOpen && (
      <Col
                  xs={24}
                  xl={12}
                  md={12}
                  sm={12}
                >
                  {this.state.loadLocation && this.getUserLocation()}
                </Col>
    )
  )
  renderTags = () => {
    return (
      <Col xs={24} lg={15} sm={18}>
              <div style={{ display: 'flex', marginTop: "20px", alignItems: "center" }}>

                <img
                  src={atIcon}
                  alt=""
                  width={20}
                  height={20}
                />
                <Mentions
                  onChange={(value) => this.onChangeTags(value)}
                  onSelect={(value) => this.onSelectTags(value)}
                  placement="top"
                  defaultValue={this.state.getMentionsArray.map(item => `@${item.value}`).join(" ")}
                  style={{ border: 'none' }}
                >
                  {this.state.followers && this.state.followers.map((followersItem: any) => (
                    <Option
                      value={followersItem?.attributes?.role === "business" ?
                        followersItem?.attributes?.profile?.data?.attributes?.company_name :
                        followersItem?.attributes?.full_name}
                      key={followersItem?.attributes?.account_id}
                      style={{ borderBottom: '1px solid #BDBDBD', marginBottom: 5, marginRight: -10, width: 210 }}
                    >
                      <div>
                        <span>
                          <Avatar
                            src={followersItem?.attributes?.photo}
                          />
                        </span>
                        <span style={{ marginLeft: '15px' }}>
                          {followersItem?.attributes?.role === "business" ?
                            followersItem?.attributes?.profile?.data?.attributes?.company_name :
                            followersItem?.attributes?.full_name}
                        </span>
                      </div>
                    </Option>
                  ))
                  }
                </Mentions>

              </div>
            </Col>
    )
  }
  renderCreatePostModal = () => {
    return (
      <Modal
        width={500}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        bodyStyle={{ padding: "0px 13px" }}

        // placement="bottomRight"
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
              fontWeight: "bold",
              borderWidth: "0px"
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            {this.state.isEditPost ? "Edit Post" : "Create Post"}
          </div>
        }
        visible={this.state.isCreatePostVisible || this.state.isEditPost}
        onOk={() => {
          this.setState({
            isCreatePostVisible: false,
            isEditPost: false,
            AllMedia: [],
            tagsOpen: false,
            postlocation: null,
            postLocationOpen: false,
            getMentionsArray: [],
            tagged_connection_ids: [],
            selectTags: []
          });
        }}
        onCancel={() => {
          this.setState({
            isCreatePostVisible: false,
            isEditPost: false,
            AllMedia: [],
            tagsOpen: false,
            selectTags: [],
            postlocation: null,
            postLocationOpen: false,
            getMentionsArray: [],
            tagged_connection_ids: [],
            createPostError: ""
          });
        }}
        footer={this.handleFooter(this.state.createPostDescription,this.state.isEmojiPickerEnable,this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan,this.state.AllMedia,this.state.tagsOpen,this.state.loadLocation,this.state.isEditPost)
        }
      >
        <Card style={{ border: "none" }}>
          <Card.Meta
            avatar={
              <Avatar
                src={this.state.toggleCommunityProfile?.attributes?.admin ? this.state.toggleCommunityProfile?.attributes?.profile_pic : this.state?.userProfile?.attributes?.profile?.data?.attributes?.photo}
                size={40}
              />
            }
            title={
              this.renderTitle()
            }
          />
        </Card>
        <Card style={{ border: "none" }}>
          {this.state.isEmojiPickerEnable && (
            <span ref={this.customClose}>
              <Picker
                onEmojiClick={this.onEmojiClick}
              />
            </span>)}
          {this.state.isPostloader ? (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Spin />
            </div>
          ) : (
            <>
              <TextArea
                ref={this.contentRef}
                name='createPostDescription'
                value={this.state.createPostDescription}
                onChange={(e) => this.setState({ createPostDescription: e.target.value })}
                placeholder={this.placeHolderWhatOnMind}
                autoSize={{ minRows: 4, maxRows: 8 }}
                maxLength={this.state.isBusinessUser ? 1000 : 1000}
                style={{ borderWidth: 1, borderRadius: "6px", fontSize: "16px", borderColor: "#a9bac8" }}
              />
              <p style={{ marginTop: 4, color: 'red' }}>{this.state.createPostError}</p>
            </>
          )}
          {this.charErrorHandling(this.state.createPostDescription.length)}
          {this.state.tagsOpen &&
            this.renderTags()
          }
          {this.renderLocation()}
        </Card>
        <Card style={{ border: "none" }}>
          {this.state.isPostloader ? null : this.state.AllMedia.length ? this.renderMedia(this.state.AllMedia) : null}
        </Card>
      </Modal>
    );
  };

  showInvitationModal = () => {
    return (
      <Modal
        width={450}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        className="Logout_Popup"
        // placement="bottomRight"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px", paddingRight: '24px' }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            {this.state?.invitationUserInfo?.name} wants to connect with {" "}
            {this.state.allInvitations?.data?.map((item: any) => {
              return item?.attributes?.account_id === this.state?.invitationUserInfo?.account_id ? item?.attributes?.sender_relation : null
            })}
          </div>
        }
        visible={this.state.isShowInvitationModal}
        onCancel={() => this.setState({ isShowInvitationModal: false })}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 10px' }}>
            <Button size="large" className="report-button" style={{ width: "45%" }} key="accept"
              onClick={() => this.handleInvitations(this.state?.relationValue, 'accepted',
                this.state.invitationUserInfo?.req_id, this.state?.invitationUserInfo?.cat_id)}>
              Accept Request
            </Button>
            <Button size="large" className="reject-button" style={{ width: "45%" }} key="reject"
              onClick={() => this.handleInvitations(this.state?.relationValue, 'rejected',
                this.state.invitationUserInfo?.req_id,
                this.state?.invitationUserInfo?.cat_id)}>
              Reject Request
            </Button>
          </div>
        ]}
      >
        <Row gutter={12}>
          <Col
            xs={24}
          >
            {/* {!this.state.loadInvitationData ? (
                          <Spin />
                        ) : ( */}
            {/* <Form
              initialValues={{
                name: this.state?.relationValue ? this.state.relationValue : ''
              }}
            > */}<p>Change relationship to</p>
            <Form.Item
             name="relationValue"
            style={{ borderWidth: 1 }}
              //  validateStatus={this.state?.relationValue ? 'success' : "error"}
              rules={[
                {
                  message: "Required field",
                  required: true,
                }
              ]}
              
             
            >
              <Select
                
                style={{
                  
                  borderRadius: '15px',
                  padding: "0 !important",
                }}
                id={"select"}
                placeholder="Select Relationship"
                
                dropdownStyle={{
                  border: "1px solid #7aacd6",
                  borderRadius: "15px",
                  paddingBottom: "0px",
                  paddingTop: "0px",
                }}
                bordered={true}
                // allowClear
                // notFoundContent={
                //   this.state.loadInvitationData ? (
                //     <Spin size="small" />
                //   ) : (
                //     "No Data Found "
                //   )
                // }
                onChange={this.handleChange}
              >
                {this.state.loadInvitationData &&
                  this.state.loadInvitationData.map((item: any) => {
                    // console.log("item32232332====", item);
                    return (
                      <option 
                      key={item}
                      value={item}
                        style={{
                         
                          padding: "10px 20px 10px 20px",
                          borderBottom: "1px solid #7aacd6",
                        }}
                        
                      >
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                            width: "100%"
                            // paddingTop: "0.7rem",
                          }}
                        >
                          <div className="capital_options">{item}</div>
                        </div>
                      </option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    )
  }

  showConnectionModal = () => {
    return (
      <Modal
        width={450}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        className="Logout_Popup"
        // placement="bottomRight"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px", paddingRight: '24px' }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Select a relationship with {this.state?.invitationUserInfo?.name}
          </div>
        }
        visible={this.state.isShowConnectionModal}
        onCancel={() => this.setState({ isShowConnectionModal: false, addConnectionSelectError: "" })}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 10px' }}>
            <Button size="large" className="report-button" style={{ width: "45%" }} key="accept"
              onClick={() => this.addConnection(this.state?.relationValue, 'accepted',
                this.state.invitationUserInfo?.req_id, this.state?.invitationUserInfo?.cat_id)}>
              Send Request
            </Button>
            <Button size="large" className="reject-button" style={{ width: "45%" }} key="reject"
              onClick={() => this.setState({ isShowConnectionModal: false, addConnectionSelectError: "" })}>
              Cancel Request
            </Button>
          </div>
        ]}
      >
        <Row gutter={12}>
          <Col
            xs={24}
          >
            {/* {!this.state.loadInvitationData ? (
                          <Spin />
                        ) : ( */}
            {/* <Form
              initialValues={{
                name: this.state?.relationValue ? this.state.relationValue : ''
              }}
            > */}<p>Change relationship to</p>
            <Form.Item
              //  validateStatus={this.state?.relationValue ? 'success' : "error"}
              rules={[
                {
                  required: true,
                  message: "Required field"
                }
              ]}
              style={{ borderWidth: 1 }}
              name="relationValue"
            >
              <Select
                id={"select"}
                style={{
                  padding: "0 !important",
                  borderRadius: '15px'
                }}
                placeholder="Select Relationship"
                bordered={true}
                dropdownStyle={{
                  borderRadius: "15px",
                  border: "1px solid #7aacd6",
                  paddingTop: "0px",
                  paddingBottom: "0px"
                }}
                onChange={this.handleChange}
              >
                {this.state.loadInvitationData &&
                  this.state.loadInvitationData.map((item: any) => {
                    // console.log("item32232332====", item);
                    return (
                      <option key={item}
                        style={{
                          borderBottom: "1px solid #7aacd6",
                          padding: "10px 20px 10px 20px"
                        }}
                        value={item}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%"
                            // paddingTop: "0.7rem",
                          }}
                        >
                          <div className="capital_options">{item}</div>
                        </div>
                      </option>
                    );
                  })}
              </Select>
              <p style={{ color: "red", marginLeft: 4 }}>{this.state.addConnectionSelectError}</p>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    )
  }
  rendercommunity = () => {
    return (
      this.state.toggleCommunityProfile?.attributes?.joined && !this.state.toggleCommunityProfile?.attributes?.admin ? (
        <>
          <Dropdown
            overlay={() => (
              <Menu key="unjoin" title="">
                <Menu.Item
                  onClick={() => this.onJoinCommunity(this.state.toggleCommunityProfile?.id, true)
                  }
                  key="1"
                >
                  Unjoin Community
                </Menu.Item>
              </Menu>
            )}
          >
            <Button
              shape="circle"
              className="outline follow-label "
              icon={<EllipsisOutlined />}
            />
          </Dropdown>
        </>
      ) : null
    )
  }
  showReportModal = () => {
    return (
      <Modal
        style={{ 
          borderWidth: "0px", 
        borderRadius: "4px", 
        overflow: "hidden" }}
        width={350}
        centered
        // placement="bottomRight"
        title={
          <div 
          onFocus={() => { }}
            onBlur={() => { }}
          style={{ width: "100%", 
          
          fontWeight: "bold",
          cursor: "move",  
          borderWidth: "0px" }}
            
          // end
          >
            Report Post
          </div>
        }
        onCancel={this.handleCancel}
        visible={this.state.isReportIssueVisible}
        
        footer={[
          <Button size="large" 
          style={{ width: "100%" }}
          className="report-button"  
          key="report"
            onClick={() => this.handleReportOk(this.state.value)}>
            Report
          </Button>,
        ]}
      >
        <Radio.Group onChange={this.onChangeValue} value={this.state.value} buttonStyle="outline">
          <Space direction="vertical">
            <Radio style={{ color: "grey" }} value='Fraude or pyramid Marketing'>
              Fraude or pyramid Marketing
            </Radio>
            <Radio style={{ color: "grey" }} value='Promoting/Selling illegal or regulated goods'>
              Promoting/Selling illegal or regulated goods
            </Radio>
            <Radio style={{ color: "grey" }} value='Promotes Terrorism'>
              Promotes Terrorism
            </Radio>
            <Radio style={{ color: "grey" }} value='Discrimination, Harrassment or Bullying'>
              Discrimination, Harrassment or Bullying
            </Radio>
            <Radio style={{ color: "grey" }} value='Child Abuse'>
              Child Abuse
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    )
  }

  renderBlockModal = () => {
    return (
      <Modal
        width={320}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        title={
          <div
            style={{
              width: "100%",
              // cursor: "move",
              borderWidth: "0px",
              textAlign: 'center'
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            <CloseCircleOutlined className='block_icon_alert' />
          </div>
        }
        visible={this.state.isBlocked}
        onOk={() => this.setState({ isBlocked: false })}
        onCancel={() => this.setState({ isBlocked: false })}
        footer={null}
      >
        <div style={{ textAlign: 'center', paddingBottom: '0.6rem' }}>
          <div>
            <Text style={{ fontWeight: "bold", }}>{`Failed To Join`}</Text>
          </div>
          <Text>{this.state.isBlockedMessage}</Text>
        </div>
      </Modal>
    );
  };

  renderPostAlertmodal = () => {
    return (
      <Modal
        width={340}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        title={
          <div
            style={{
              width: "100%",
              // cursor: "move",
              borderWidth: "0px",
              textAlign: 'center'
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            <CheckOutlined className='post_icon_alert' />
          </div>
        }
        visible={this.state.isPostedPost}
        onOk={() => this.setState({ isPostedPost: false })}
        onCancel={() => this.setState({ isPostedPost: false })}
        footer={null}
      >
        <div style={{ textAlign: 'center', paddingBottom: '0.6rem' }}>
          <div>
            <Text style={{ fontWeight: "bold", }}>{`Posted Successfully`}</Text>
          </div>
          <div>
            <Text>You have posted successfully.</Text>
          </div>
          <Text>Your post will be visible once accepted by admin.</Text>
        </div>
      </Modal>
    );
  };

  renderInviteFriendModal = () => {
    // console.log(this.state.inviteFriendslist);
    return (
      <div id="invitefriend_modal">
        <Modal
          width={350}
          style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden", padding: 0 }}
          className="invitation_modal_container"
          centered
          title={
            <div
              style={{
                width: "100%",
                height: "100%",
                borderWidth: "0px",
                // textAlign: 'center'
              }}
              onFocus={() => { }}
              onBlur={() => { }}
            // end
            >
              <h3 style={{ fontSize: 16 }}>Invite Friends</h3>
            </div>
          }
          visible={this.state.isInviteCommunity}
          onOk={() => this.handleInviteFriends()}
          onCancel={() => this.handleInviteFriends()}
          footer={
            <div
              style={{
                width: "100%",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#fff",
                boxShadow: "-1px 5px 15px #888888"
              }}
            >
              <Button
                className='btn_colors'
                type="primary"
                loading={this.state.isAdminInvite}
                // disabled={this.state.sendinvitationsAdmin.length === 0}
                onClick={() => this.sendInvitationsAdminApi()}
                shape="round"
                size={"large"}
                style={{ width: "90%" }}
              >
                Send Invitation
              </Button>
            </div>
          }
        >
          <div style={{ height: "300px", overflowY: "scroll" }}>
            <Skeleton className="invite_friends_skeleton" loading={this.state.isLoadInviteFnds}
              active
              avatar
              paragraph={{ rows: 0 }}>
              {this.state.inviteFriendslist?.length > 0 ? (
                <List
                  itemLayout="horizontal"
                  dataSource={this.state.inviteFriendslist}
                  style={{ overflowY: "scroll" }}
                  renderItem={(item: any) => (
                    <List.Item
                      style={{ background: item.checked ? "rgb(221, 230, 235, 0.3)" : '#fff', opacity: item.checked ? "0.6" : "1" }}
                      onClick={(e: any) => {
                        this.onCheckChange(e, item)
                      }}

                    >
                      <List.Item.Meta
                        style={{ alignItems: "center" }}
                        className="invite_Friends_list"
                        avatar={<div style={{ paddingLeft: '14px' }}><Avatar src={item?.attributes?.photo} /></div>}
                        title={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>{item?.attributes?.role === "business" ? item?.attributes?.profile?.data?.attributes?.company_name : item?.attributes?.full_name}</div>
                          <div style={{ paddingRight: '15px' }}>
                            {/* <Checkbox onChange={(e: any) => this.onCheckChange(e, item)} /> */}
                            {item.checked && <img src={checkedIcon} style={{ height: 21, width: 21 }} />}
                          </div>
                        </div>}
                      // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{
                    backgroundColor: "white",
                    padding: "100px",
                    height: 350,
                    textAlign: "center",
                  }}
                  description={"No Friends to invite"}
                />
              )}
            </Skeleton>
          </div>
        </Modal>
      </div>
    );
  };

  // Customizable Area End
  render() {
    const { navigation } = this.props;
    // console.log(this.state.toggleCommunityProfile);
    return (
      <div style={{
        backgroundColor: "#f6f6f6",
        // backgroundColor: "#fffb8f",

      }}
        className="community_page_layout"
      >
        {this.redirectUser()}
        {/* <CustomHeader {...this.props} dotVisible={false} /> */}
        {this.showInvitationModal()}
        {this.showConnectionModal()}
        {this.showReportModal()}
        {this.renderBlockModal()}
        {this.renderPostAlertmodal()}
        <CommunityAlert
          modal={this.state.openModal}
          modalStatus={this.state.openModalStatus}
          handleCancel={this.handleInviteFriendModal}
          heading={this.state.heading}
          desc={this.state.fetchMessage}
        />
        <div className="invitefriend_modal">
          {this.renderInviteFriendModal()}
        </div>
        <Row gutter={[20, 0]}>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // backgroundColor: "green"
            }}
            // span={6}
            xs={24}
            sm={24}
            md={10}
            lg={8}
            xl={6}
            xxl={6}
          >
            <div style={{
              width: "100%",
              // paddingRight: "8px"
            }}>
              {this.state.adminCommunities?.length > 0 && (
                <CommonListItem
                  isLoading={this.state.loadCommunity}
                  data={this.state.adminCommunities}
                  postCount={this.state.postInfo}
                  onViewAll={() =>
                    this.props.history.push({
                      pathname: '/community/all', state: {
                        heading: 'My Community Forum'
                      }
                    })}
                  label={"My Community Forum"}
                  isCommunityForum={true}
                  description={true}
                  onItemPress={(id: any) => this.onItemPress(id)}
                />
              )}
              <CommonListItem
                isLoading={this.state.loadCommunity}
                data={this.state?.joinnedCommunityData}
                postCount={this.state.postInfo}
                onViewAll={() =>
                  this.props.history.push({
                    pathname: '/community/all', state: {
                      heading: 'Community Forum'
                    }
                  })}
                label={"Community Forum"}
                isCommunityForum={true}
                description={true}
                onItemPress={(id: any) => this.onItemPress(id)}
              />
              <Button
                // type='primary'
                size="large"
                className="create-community-button"
                // onClick={() => this.openCreateEditModal('create')}
                onClick={() => this.setState({
                  isCreateCommunity: true,
                  isCreateCommunityVisible: true,
                  profile_pic: '',
                  cover_pic: '',
                  name: '',
                  topics: '',
                  description: ''
                })}
              >
                Create Community
              </Button>
              {this.renderCreateAndEditCommunityModal()}
              {this.renderSuccessCommunityPost()}
            </div>
          </Col>
          <Col
            // span={18}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // backgroundColor: "green"
            }}
            xs={24}
            sm={24}
            md={14}
            lg={16}
            xl={18}
            xxl={18}
          >
            <div style={{
              width: "100%",
              marginTop: "20px"
              // paddingLeft: "8px"
            }}>
              {this.state.toggleCommunityProfile && (

                <Card
                  style={{ borderRadius: "5px", display: this.state.toggleCommunityProfile?.attributes ? "block" : "none" }}
                  // bodyStyle={{ paddingTop: "10px", paddingBottom: "10px" }}
                  cover={
                    <img
                      src={this.state.toggleCommunityProfile?.attributes?.cover_pic}
                      height={"220px"}
                      style={{ objectFit: "cover", borderRadius: "5px 5px 0px 0px" }}
                    />
                  }
                >
                  <Avatar
                    shape="circle"
                    src={this.state.toggleCommunityProfile ? this.state.toggleCommunityProfile?.attributes?.profile_pic : workStation}
                    size={75}
                    style={{ position: "absolute", top: "181px", left: "20px" }}
                  />
                  <br />
                  <Card.Meta
                    style={{ padding: "25px 10px 10px 10px" }}
                    description={
                      <Row gutter={[8, 8]}>
                        <Col
                          xs={24}
                          sm={24}
                          md={9}
                          lg={9}
                          xl={9}
                          xxl={9}
                        >
                          <Row gutter={[0, 2]}>
                            <Col span={24}>
                              <Text style={{ fontSize: "16px", textTransform: 'capitalize', color: "#1f1f1f", fontWeight: 500 }}>
                                {this.state.toggleCommunityProfile && this.state.toggleCommunityProfile?.attributes?.name}
                              </Text>
                            </Col>
                            <Col span={24}>
                              <Text style={{ fontSize: "12px", textTransform: 'capitalize', color: '#bfbfbf' }}>
                                {this.state.toggleCommunityProfile && this.state.toggleCommunityProfile?.attributes?.topics}
                              </Text>
                            </Col>
                            <Col span={24}>
                              <Text style={{ fontSize: "12px", color: "#8c8c8c", textTransform: 'capitalize' }}>
                                <b style={{ fontWeight: 400 }}>{this.state.toggleCommunityProfile?.attributes?.follower_count}</b> Followers | <b style={{ fontWeight: 400 }}>{this.state.communityPosts?.length}</b> Posts
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs={24}
                          sm={24}
                          md={15}
                          lg={15}
                          xl={15}
                          xxl={15}
                        >
                          {!this.state.loadCommunity && <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              flexWrap: "wrap",
                              gap: "10px"
                            }}
                          >

                            {this.state.toggleCommunityProfile?.attributes?.joined ? (
                              this.state.toggleCommunityProfile?.attributes?.admin ? (
                                <Button
                                  // className="margin-right-16"
                                  type="primary"
                                  className="community_button_join"
                                  shape="round"
                                  style={{
                                    backgroundColor: "rgb(31,130,188)"
                                  }}
                                  onClick={() => {
                                    this.props.history.push({
                                      pathname: "/Community/ManegeRequest",
                                      state: { id: this.state.toggleCommunityProfile?.attributes },
                                    });
                                  }}
                                >
                                  Manage Requests
                                </Button>
                              ) :

                                <Button
                                  loading={this.state.loadFollowCommunity}
                                  // className="margin-right-16"
                                  type="primary"
                                  className="community_button_join"
                                  shape="round"
                                  style={{
                                    // width: "150px",
                                    backgroundColor: "rgb(31,130,188)"
                                  }}
                                  onClick={() => {
                                    this.onFollowCommunity(this.state.toggleCommunityProfile?.id);
                                  }}
                                >
                                  {this.state.toggleCommunityProfile?.attributes?.followed ? 'Unfollow' : 'Follow'}
                                </Button>
                            ) : (
                              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexDirection: "row", flexWrap: "wrap", gap: "10px" }}>
                                {(!this.state.toggleCommunityProfile?.attributes?.joined) && <div>
                                  <Button
                                    loading={this.state.loadJoinCommunity}
                                    type="primary"
                                    className="community_button_join"
                                    shape="round"
                                    style={{
                                      backgroundColor: "rgb(31,130,188)"
                                    }}
                                    onClick={() => {
                                      this.onJoinCommunity(
                                        this.state.toggleCommunityProfile?.id,
                                        this.state.joinedCommunities.includes(this.state.toggleCommunityProfile?.id))
                                    }}
                                  >
                                    {this.state.toggleCommunityProfile?.attributes?.join_request_sent ? "Cancel Join Request" : "join"}
                                  </Button>
                                </div>}
                              </div>
                            )}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexDirection: "row", flexWrap: "wrap", gap: "10px" }}>
                              <Button
                                className="follow-outline follow-label community_button_join"
                                type="default"
                                shape="round"
                                disabled={!this.state.toggleCommunityProfile?.attributes?.joined}
                                onClick={() =>
                                  this.setState({ isInviteCommunity: true })
                                }
                              >
                                Invite Friends
                              </Button>
                              {this.state.toggleCommunityProfile?.attributes?.admin ? (
                                <>
                                  <Dropdown
                                    overlay={() => (
                                      <Menu key="communityAdmin" title="">
                                        <Menu.Item
                                          onClick={() => this.openCreateEditModal()
                                          }
                                          key="1"
                                        >
                                          Edit Community
                                        </Menu.Item>
                                        <Menu.Item key="2" onClick={() =>
                                          this.props.history.push({
                                            pathname: '/community/admin-settings',
                                            state: {
                                              id: this.state.toggleCommunityProfile?.attributes?.id,
                                              name: this.state.toggleCommunityProfile?.attributes?.name,
                                              initialActiveNav: 1
                                            }
                                          })}>Settings</Menu.Item>
                                      </Menu>
                                    )}
                                  >
                                    <Button
                                      shape="circle"
                                      className="outline follow-label "
                                      icon={<EllipsisOutlined />}
                                    />
                                  </Dropdown>
                                </>
                              ) : this.rendercommunity()}
                            </div>
                          </div>}
                        </Col>
                      </Row>
                    }
                  />
                </Card>
              )}

              <Row>
                <Col
                  span={
                    !this.state.isCommunityJoined && postData.length == 0
                      ? 24
                      : 16
                  }
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={
                    !this.state.isCommunityJoined && postData.length == 0
                      ? 24
                      : 14
                  }
                  xxl={
                    !this.state.isCommunityJoined && postData.length == 0
                      ? 24
                      : 16
                  }>

                  {this.state?.communityPosts?.length > 0
                    // && this.state.communityPosts[0]?.data
                    ? (
                      <div>
                        <List
                          className="home-posts"
                          dataSource={this.state.communityPosts}
                          renderItem={(item: any) => {
                            return (
                              <div key={item} style={{ margin: '20px 0px' }}>
                                {this.renderPost(item)}
                              </div>
                            )
                          }}
                        />
                        <div
                          style={{
                            width: "100%",
                            padding: "0px 0px 20px 0px",
                            textAlign: "center",
                            cursor: "pointer",
                            color: "#005574"
                          }}
                          onClick={async () => {
                            await this.setState((prevState) => {
                              return { paginationOfPageCount: prevState.paginationOfPageCount + 1 }
                            });
                            await this.setState({
                              clickToSeeMoreLoading: true
                            });
                            await this.getCommunityPosts(this.state.toggleCommunityProfile?.id);
                          }}
                        >
                          {this.state.clickToSeeMoreLoading ? <Spin /> : this.state.clickToSeeMoreShow ? "Click to see more post" : null}
                        </div>
                      </div>
                    ) : this.state.fetchCommunityPostData ?
                      (<div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px' }}>
                        <Spin />
                      </div>) : (
                        <div style={{ margin: '20px 0px' }}>
                          <div
                            style={{
                              width: "100%",
                              height: "250px",
                              backgroundColor: "white",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              gap: "5px",
                              borderRadius: "5px"
                            }}
                          >
                            <img src={blogIcon} style={{ width: "45px", height: "35px" }} alt="post_Icon" />
                            <div style={{ color: "#929292" }}>No post on this community page</div>
                          </div>
                        </div>
                      )}
                </Col>
                <Col
                  hidden={!this.state.isCommunityJoined && postData.length == 0}
                  span={8}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={10}
                  xxl={8}
                  style={{ paddingLeft: "16px" }}
                >
                  {this.state?.toggleCommunityProfile?.attributes?.joined
                    // || this.state.joinedCommunities.includes(this.state?.toggleCommunityProfile?.attributes?.id.toString())
                    ?
                    (
                      <Button
                        type="text"
                        size="large"
                        shape="round"
                        className="create-community-button"
                        onClick={() => this.setState({
                          isCreatePostVisible: true,
                          createPostDescription: '',
                          AllMedia: [],
                          getMentionsArray: [],
                          selectTags: [],
                          createPostImages: [],
                          videoArrayList: []
                        })}
                      >
                        <div style={{
                          display: "flex",
                          textAlign: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }} >
                          <span style={{ width: "auto" }}>
                            Create Post for&nbsp;
                          </span>
                          <span style={{
                            display: "inline-block",
                            alignItems: 'center',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            textTransform: 'capitalize',
                          }}>
                            {`${this.state?.toggleCommunityProfile?.attributes?.name}`}
                          </span>
                        </div>
                      </Button>
                    ) : <></>}
                  {this.renderCreatePostModal()}

                  {postData.length && (
                    <>
                      {" "}
                      <CommonListItem
                        isLoading={this.state.loadpeopleKnow}
                        data={this.state?.peoplemayKnow?.data ? this.state?.peoplemayKnow?.data : []}
                        onViewAll={() => this.props.history.push('/myNetwork/you_may_know')}
                        onReload={() => this.getPeopleYouknow()}
                        label={"People you may know"}
                        isFromKnownPeople={true}
                        onClickInvitation={this.onFollow}
                        onItemPress={(id: any) => this.onItemPressCheck(id)}
                      />
                      <CommonListItem
                        isLoading={this.state.loadMutualCommunity}
                        data={this.state.newCommunities?.length > 0 ? this.state.newCommunities.slice(0, 4) : []}
                        onViewAll={() =>
                          this.props.history.push({
                            pathname: '/community/all',
                            state: {
                              heading: 'Community people also follow',
                              id: this.state.toggleCommunityProfile?.id
                            }
                          })}
                        label={"Community People also follow"}
                        isFromCommunityPeopleFollow={true}
                        onItemPress={(id: any) => this.onItemPress(id)}

                      />
                      <CommonListItem
                        isLoading={this.state.loadInvitation}
                        data={this.state?.allInvitations?.data ? this.state?.allInvitations.data : []}
                        onViewAll={() => this.props.history.push('/myNetwork/invitation')}
                        label={"Invitations"}
                        isInvitation={true}
                        onClickInvitation={this.handleInvitationModal}
                        onItemPress={(id: any) => this.onItemPressCheck(id)}
                      />
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row >
      </div >
    );
  }

  // async componentDidMount() {}
}
// Customizable Area Start

// Customizable Area End
