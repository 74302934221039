import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { message } from "antd"
// @ts-ignore
import { baseURL } from '../../../framework/src/config.js';
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
import { RouterProps } from "react-router";
import Mixpanel from "../../../components/src/Mixpanel";

export interface Props extends RouterProps {
  // Customizable Area Start
  navigation: any;
  id: string;
  route?: any;
  location: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string | null;
  isPrivate: boolean;
  isPostView: boolean
  isRatingVisible: boolean
  account_id: any;
  screenWidth: any
  createPostImages:any
  loading:boolean;
  AllMedia:any
  loginId: number;
  isModal: boolean;
  isSuccessModal: boolean;
  isModalOpen: boolean;
  usertype: string;
  activeTab: any;
  isLoading: boolean;
  profileData: any;
  cover_image: any;
  isAddReview: boolean;
  isAddAppliedViewRating: boolean;
  isAddRatingRateAsCustomer: boolean;
  category_id: number | null;
  ratingView: Array<any>;
  isRatingViewShow: boolean;
  widthhchange: any;
  imageRotationDegree: any;
  displayCoverImage: any;
  preview: boolean;
  ratingViewName: string;
  ratingViewName_2: {
    name: string,
    npsData: any
  };
  redirect: boolean;
  isReportIssueVisible: boolean;
  postsPerPage: number
  reviewData: Array<any>;
  ratingData: Array<any>;
  ratingData1: Array<any>;
  lastSeen: any;
  ratingOption: boolean
  status: string;
  subCategory: Array<any>
  isSelectCategoryModalVisible: boolean;
  reviewErrorData: string;
  alertReview: boolean;
  errorMessageOnOk: boolean;
  followUnfollowAlert: boolean;
  viewAllModal: boolean;
  viewAllTitle: String;
  viewAllBody: any;
  postsDataArray: any;
  isPostLoading: boolean
  activeTabForPost: string;
  sendRequestRelations: any;
  generalRating: any;
  userRateItems: any;
  isShowInvitationModal: boolean;
  loadInvitationData: any;
  blockAlertOpen: boolean;
  reportOpen: boolean;
  getMessage: string;
  nextPageLoader: boolean;
  isCompleteReview: boolean;
  changeRelationShipAccept: boolean;
  checkModalForAccept: boolean;
  downloadResumeLoading: boolean;
  suspendedAccount: boolean;
  suspendedAccountData: any;
  hideAnonymous: boolean;
  disableAnonymous: boolean;
  noOfPostData: boolean;
  isSuccessfullModal: boolean;
  accountPlan: string;
  modelData: any;
  isApiFailed: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OtherProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetCategoriesId: string = "";
  getBusinessProfileAccountPosts: string = "";
  GetReviewsId: string = "";
  GetRatingId: any
  unsubscribe: any;
  playerRef: string = "";
  backHandler: any;
  GetProfielInformationId: string = "";
  GetCategorySelectionByNetwork: string = "";
  GetOtherBusinessProfileId: string = "";
  getCancelConnectionRequest: string = "";
  followId: string = "";
  connectId: string = "";
  addReviewId: string = "";
  getRequestStatusId: any;
  postBlockedAPIId: any;
  addReport: any;
  removeConnectionId: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.GetCategories("");

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      // Customizable Area Start
      ratingOption: false,
      hideAnonymous: false,
      disableAnonymous: false,
      isPostView: false,
      account_id: "",
      screenWidth: null,
      loginId: 0,
      activeTab: 1,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      isModal: false,
      isSuccessModal: false,
      isModalOpen: false,
      usertype: "business",
      isLoading: true,
      profileData: [],
      createPostImages: [],
      loading: false,
      AllMedia: [],
      cover_image: '',
      isAddReview: false,
      isAddAppliedViewRating: false,
      isAddRatingRateAsCustomer: false,
      category_id: null,
      ratingView: [],
      isPrivate: false,
      isRatingViewShow: false,
      widthhchange: 0,
      imageRotationDegree: 0,
      displayCoverImage: 'none',
      preview: false,
      ratingViewName: "",
      ratingViewName_2: {
        name: "",
        npsData: []
      },
      redirect: false,
      isReportIssueVisible: false,
      reviewData: [],
      ratingData: [],
      ratingData1: [],
      lastSeen: "",
      status: "",
      subCategory: [],
      isSelectCategoryModalVisible: false,
      reviewErrorData: "",
      alertReview: false,
      errorMessageOnOk: false,
      followUnfollowAlert: false,
      viewAllModal: false,
      viewAllTitle: "",
      viewAllBody: [],
      postsDataArray: [],
      postsPerPage: 0,
      isPostLoading: false,
      activeTabForPost: '1',
      sendRequestRelations: [],
      generalRating: { array: [] },
      userRateItems: { array: [] },
      isShowInvitationModal: false,
      loadInvitationData: [],
      isRatingVisible: true,
      blockAlertOpen: false,
      reportOpen: false,
      getMessage: "",
      nextPageLoader: false,
      isCompleteReview: false,
      changeRelationShipAccept: false,
      checkModalForAccept: false,
      downloadResumeLoading: false,
      suspendedAccount: false,
      suspendedAccountData: undefined,
      noOfPostData: true,
      isSuccessfullModal: false,
      modelData: {},
      accountPlan: "",
      isApiFailed: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }
  handleIncreaseWidth = () => {
    this.setState({ widthhchange: this.state.widthhchange + 10 })
  }

  handleDecreaseWidth = () => {
    this.setState({ widthhchange: this.state.widthhchange - 10 })
  }
  handleHideCoverImage = () => {
    this.setState({ displayCoverImage: "none" })
    this.setState({ imageRotationDegree: 0 })
    this.setState({ widthhchange: 0 })
  }

  handleShowCoverImage = () => {
    this.setState({ displayCoverImage: "block" })
  }
  handleShowPreview = () => {
    this.setState({ preview: true })
  }
  handleHidePreview = () => {
    this.setState({ preview: false })
  }

  handleRotateImageRight = () => {
    this.setState({ imageRotationDegree: this.state.imageRotationDegree + 90 })
  }
  handleRotateImageLeft = () => {
    this.setState({ imageRotationDegree: this.state.imageRotationDegree - 90 })
  }

  handleRatingViewCancel = () => {
    this.setState({ isRatingViewShow: false });
  }

  handleViewAllCancel = () => {
    this.setState({ viewAllModal: false });
  }

  handleViewAllOpen = (title: string, body: any) => {
    this.setState({ viewAllModal: true, viewAllTitle: title, viewAllBody: body });
  }

  handleReportOpen = () => {
    this.setState({ reportOpen: true });
  }

  handleReportClose = () => {
    this.setState({ reportOpen: false });
  }

  handleBlockCancel = () => {
    this.setState({ blockAlertOpen: false });
  }

  handleBlockOpen = () => {
    this.setState({ blockAlertOpen: true });
  }


  handleFollowUnfollowCancel = () => {
    this.setState({ followUnfollowAlert: false });
  }

  handleFollowUnfollowOpen = () => {
    this.setState({ followUnfollowAlert: true });
  }

  handleRatingView = (data: any, name: string) => {
    this.setState({ ratingView: data, isRatingViewShow: true, ratingViewName: name })
  }

  handleRatingNPSChart = (name: string, value: any) => {
    if (this.state.ratingViewName_2.name === "") {
      this.setState({ ratingViewName_2: { ...this.state.ratingViewName_2, name: name, npsData: value } });
    }
  }

  handleNPSChartCancel = () => {
    this.setState({ ratingViewName_2: { ...this.state.ratingViewName_2, name: "", npsData: "" } });
  }

  handleOpenModal = () => {
    this.setState({ isModal: true });
  }
  handleSuccessModalOpen = () => {
    this.setState({ isSuccessModal: true });
  }
  handleCancel = () => {
    this.setState({ isModal: false });
  }

  handleAddReviewCancel = () => {
    this.setState({ isAddReview: false, disableAnonymous: false, hideAnonymous: false })
  }

  handleOpenAddReviewModal = () => {
    if (this.state.reviewData.length === 0) {
      this.setState({ isAddReview: true });
    } else {
      const checkReViewFiltered = this.state.reviewData?.filter((item) => item?.attributes?.reviewer_id === this.state.loginId)
      // const checkReviewerId = this.state.reviewData?.map((item) => {
      //   console.log(item?.attributes?.reviewer_id, "djkhaaaf", this.state.loginId, checkReViewFiltered)
      //   // if (item?.attributes?.reviewer_id === this.state.loginId) {
      //   //   return true;
      //   // }
      // })
      console.log(checkReViewFiltered, "sd;ldgaklgjal adskald", this.state.loginId, checkReViewFiltered.length, checkReViewFiltered[0]?.attributes?.anonymous)
      checkReViewFiltered?.length >= 2 ? this.setState({ alertReview: true }) :
        checkReViewFiltered &&
          checkReViewFiltered?.length === 0 ?
          this.setState({ isAddReview: true })
          :
          checkReViewFiltered[0]?.attributes?.anonymous ?
            this.setState({
              isAddReview: true,
              hideAnonymous: true
            }) :
            this.setState({
              isAddReview: true,
              disableAnonymous: true
            })

      // }
    }
  }

  handleAddAppliedViewRatingCancel = () => {
    this.setState({ isAddAppliedViewRating: false })
  }

  handleAddAppliedViewRatingOpen = () => {
    this.setState({ isAddAppliedViewRating: true })
  }

  handleSaveAndNext = () => {
    console.log("svae")
    this.setState({ isAddRatingRateAsCustomer: true })
    this.setState({ isAddAppliedViewRating: false })
  }

  handleAddRatingBack = () => {
    this.setState({ isAddRatingRateAsCustomer: false })
    this.setState({ isAddAppliedViewRating: true })
  }

  handleSelectChange = (value: any) => {
    this.setState({ category_id: value, errorMessageOnOk: false })
  }

  handleSelectCategoryCancel = () => {
    this.setState({ isSelectCategoryModalVisible: false });
  }

  onClickConnect = () => {
    this.handleNetworkRelation()
    this.setState({ isSelectCategoryModalVisible: true });
  }

  handleAlertReviewOk = () => {
    this.setState({ alertReview: false, isCompleteReview: false });
  }

  handleAddRatingRateAsCustomerCancel = () => {
    this.setState({ isAddRatingRateAsCustomer: false })
  }

  handleNetworkRelation = () => {
    const header = {
      "Content-Type": configJSON.getOtherBusinessProfileInfoContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCategorySelectionByNetwork = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNetworkRelation}?account_id=${this.state.account_id}&action_type=send`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getOtherBusinessProfileInfoMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCancelConnectionRequest = () => {
    this.setState({ nextPageLoader: true })
    const header = {
      "Content-Type": configJSON.getOtherBusinessProfileInfoContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCancelConnectionRequest = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCancelConnectionRequest}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getOtherBusinessProfileInfoMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async componentDidMount() {
    let accountData = await getStorageData("accountData", false)
    let token = await localStorage.getItem("token");

    if (!token) {
      this.props.history.push({ pathname: '/' });
    }
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    const loginUser = await getStorageData("loginUser")
    this.setState({ isLoading: true });
    this.setState({
      account_id: this.props?.location?.state,
      loginId: loginUser?.id,
      accountPlan: accountData?.attributes?.account?.data?.attributes?.account_plan
    });
    this.getRelations("send");
    this.GetOtherBusinessProfile();
    this.getuserRateDetails("get");
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.account_id !== prevState.account_id) {
      this.GetOtherBusinessProfile();
    }
    if (this.state.profileData?.invited !== prevState?.profileData?.invited) {
      this.setState({ nextPageLoader: false })
    }
  }

  getBusinessPosts = async (account_id: number) => {
    // console.log("asdjandslg;", configJSON.getOtherBusinessProfileAccountPostsEndPoint)
    // debugger


    const header = {

      token: localStorage.getItem("token")
    };

    this.setState({
      isPostLoading: true
    })

    var httpData = {
      'id': 1961
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBusinessProfileAccountPosts = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUsersPostApiEndPoint + `?id= ${this.state.account_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
      // configJSON.ReviewMethodType
    );
    // console.log(requestMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleBusinessProfileAccountPosts(responseJson: any) {
    if (responseJson?.data) {
      if (responseJson?.data?.length < 4) {
        this.setState({ noOfPostData: false });
      } else {
        this.setState({ noOfPostData: true });
      }
      this.setState({
        postsDataArray: [...this.state.postsDataArray, ...responseJson.data],
        postsPerPage: this.state.postsPerPage + 1,
        isPostLoading: false
      })

    } else {
      this.setState({
        isPostLoading: false,
        noOfPostData: false
      })
    }
  }
  hendleGetCategorySelectionByNetwork(responseJson: any) {
    if (responseJson) {
      this.setState({
        subCategory: responseJson.relations,
        isSelectCategoryModalVisible: true

      })
    }
  }

  handlegetCancelConnectionRequest(responseJson: any) {
    if (responseJson) {
      console.log(responseJson.data, "getCancelConnectionRequest")
      const RemoveRequestId = responseJson?.data?.find((item: any) => item.attributes.account_id === this.state.account_id)
      // console.log(RemoveRequestId,"askaj")
      this.handleRemoveConnection(RemoveRequestId.id)
    }
  }
  handleGetCategoriesId(responseJson: any) {
    const filterData = responseJson?.data.filter((item: any) => {
      if (this.state.profileData?.profile?.data?.attributes?.role === "personal") {
        return item?.attributes?.name === "Personal Connection"
      } else if (this.state.profileData?.profile?.data?.attributes?.role === "business") {
        return item?.attributes?.name === "Business Connection"
      }
    })
    this.setState({ subCategory: filterData[0]?.attributes?.sub_categories });
  }
  handleaddReviewId(responseJson: any) {
    if (!responseJson?.error) {
      this.setState({ reportOpen: false })
      this.getReview();
    } else {
      this.handleReviewAlert()
    }
  }

  handleGetOtherBusinessProfileId(responseJson: any) {
    this.getuserRateDetails({ data: responseJson });
    let isPrivate = responseJson?.data?.attributes?.profile_view?.includes("private_not_connected") ? true : false
    let isRatingVisible = responseJson?.data?.attributes?.profile_view?.includes("public_connected") ||
      responseJson?.data?.attributes?.profile_view?.includes("private_connected") ? false : true
    let isPostView = responseJson?.data?.attributes?.post_view
    this.setState({
      profileData: responseJson?.data?.attributes,
      account_id: responseJson?.data?.attributes?.account_id,
      isPrivate: isPrivate,
      isPostView: isPostView,
      isRatingVisible: isRatingVisible,
      activeTabForPost: isPostView ? "1" : "2",
      cover_image: responseJson?.data?.attributes?.cover_photo !== null ? responseJson?.data?.attributes?.cover_photo : responseJson?.data?.attributes?.photo,
    });
    if (responseJson?.data?.attributes?.status === "suspended") {

      this.setState({
        suspendedAccount: true,
        suspendedAccountData: responseJson?.data?.attributes
      })
    } else {
      this.getCategory();
      this.getReview();
      this.getRating()
    }
    this.setState({
      isLoading: false,
      activeTabForPost: isPostView ? "1" : "2"
    });
    if (!isPrivate) {
      this.getBusinessPosts(this.props?.location?.state)
    }
  }
  handleReceiveApis(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId == this.GetOtherBusinessProfileId) {


      this.handleGetOtherBusinessProfileId(responseJson)
    } else if (apiRequestCallId == this.getBusinessProfileAccountPosts) {
      this.handleBusinessProfileAccountPosts(responseJson)
    } else if (apiRequestCallId == this.followId) {
      this.GetOtherBusinessProfile();
    }
    else if (apiRequestCallId == this.GetCategorySelectionByNetwork) {
      console.log(responseJson)
      this.hendleGetCategorySelectionByNetwork(responseJson)

    } else if (apiRequestCallId == this.getCancelConnectionRequest) {
      this.handlegetCancelConnectionRequest(responseJson)
    }
    else if (apiRequestCallId == this.GetCategoriesId) {
      this.handleGetCategoriesId(responseJson)
    }
    else if (apiRequestCallId == this.connectId) {
      this.setState({
        category_id: null,
        errorMessageOnOk: false
      });
      this.GetOtherBusinessProfile();
    }
    else if (apiRequestCallId == this.GetReviewsId) {
      this.setState({ reviewData: responseJson?.data });
    }
    else if (apiRequestCallId == this.GetRatingId) {
      this.setState({ ratingData: responseJson?.data });

    }
    else if (apiRequestCallId === this.postBlockedAPIId) {
      this.trackMixPanelForBlock(responseJson.message);
      this.setState({ getMessage: responseJson.message });
      this.handleSuccessModalOpen()
      this.GetOtherBusinessProfile();
    }
    else if (apiRequestCallId === this.addReport) {
      Mixpanel.track("Connection Reported")
      this.setState({ getMessage: responseJson?.message })
      this.handleSuccessModalOpen()
      this.GetOtherBusinessProfile();
    }
    else if (apiRequestCallId === this.removeConnectionId) {
      Mixpanel.track("Connection Removed")
      this.setState({ getMessage: responseJson?.message, isSuccessModal: true, nextPageLoader: false })
      this.GetOtherBusinessProfile();
    }
    else if (apiRequestCallId == this.addReviewId) {
      this.handleaddReviewId(responseJson)
    }
    else {
      this.setState({
        isLoading: false
      })
    }
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId && responseJson && !responseJson?.errors) {

        this.handleReceiveApis(apiRequestCallId, responseJson)
      }
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );
    }
    // Customizable Area End
  }

  trackMixPanelForBlock = (message: string) => {
    if (message === "Account blocked successfully.") {
      Mixpanel.track("Connection Blocked")
    } else {
      Mixpanel.track("Connection Unblocked")
    }
  }

  GetOtherBusinessProfile = () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetOtherBusinessProfileId = requestMessage.messageId;
    const endPoint = this.props.location.pathname == '/other/personal/profile' ? `${configJSON.getOtherPersonalProfileInfoEndPoint}/${this.state.account_id}` : `${configJSON.getOtherBusinessProfileInfoEndPoint}/${this.state.account_id}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  downloadResumeWithAxios = async () => {
    this.setState({
      downloadResumeLoading: true
    })
    const id = this.state?.profileData?.account_id
    try {
      const axios = require('axios');
      const config = {
        method: 'get',
        url: `${baseURL}/${configJSON.downloadResumeEndPoint}/personal?account_id=${id}`,
        headers: {
          'token': this.state.token
        },
        responseType: "blob",
      };

      const response = await axios(config)
      console.log(response, "asdflkada")
      if (!response.error) {
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        this.download(fileURL, "Resume")
      }

    } catch (error) {

      this.setState({
        downloadResumeLoading: false
      })
      message.error("Something went wrong")
    }
  }

  download(dataurl: any, filename: any) {
    var link = document.createElement('a');
    link.href = dataurl;
    console.log(link)
    var fileName = filename;
    link.download = fileName;
    link.click();
    this.setState({
      downloadResumeLoading: false
    })
  }
  beforeUploadAttachment(file: any) {
    let isLt2M = false;

    if (file.type.slice(0, 11) == "application") {
      isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('Pdf/Doc must smaller than 10MB!');
      }
    }

    this.getBase64(file, (imageUrl: string) => {
      let ImageArrayList = [...this.state.createPostImages];
      let AllMedia = [...this.state.AllMedia]
      if (isLt2M && (file.type.slice(0, 11) == "application")) {
        AllMedia.push({
          filePath: imageUrl,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
        ImageArrayList.push({
          filePath: imageUrl,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      }
      else {
        message.error("invalid file type!!!");
      }

      this.setState({
        createPostImages: ImageArrayList,
        AllMedia,
        loading: false,
      });
    });

    return true;
  }
  getBase64(file: any, arg1: (imageUrl: string) => void) {
    throw new Error("Method not implemented.");
  }
  // Customizable Area End

  getRelations = async (action_type: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/relations?account_id=${this.state.account_id}&action_type=${action_type}`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      if (response && response?.data) {
        const reltionsArray = response?.data.relations.sort().map((item: any) => ({ name: item, id: item }))
        if (action_type == "accept") {
          this.setState({ loadInvitationData: reltionsArray, isLoading: false });
        } else {
          this.setState({ sendRequestRelations: reltionsArray, isLoading: false });
        }
      }
    } catch (error) {
      console.error(error);
      this.setState({ isLoading: false });
    }
  }

  changeInviteRelationship = (val: any) => {
    const profileData = { ...this.state.profileData };
    const reqData = profileData.received_request;
    reqData["sender_relation"] = val;
    this.setState({ profileData: profileData, changeRelationShipAccept: true });
  }

  acceptRequest = async (dataObj: any) => {
    if (dataObj.type == "openAcceptModal") {
      this.getRelations("accept");
      this.setState({ isShowInvitationModal: true, loadInvitationData: [] });
    } else {
      const relationinDropdown = dataObj?.sender_relation;
      try {
        const response = await axios({
          url: `${baseURL}/bx_block_request_management/requests/${dataObj?.id}`,
          method: 'put',
          headers: {
            'token': this.state.token,
            'Content-Type': 'application/json'
          },
          data: {
            data: {
              "sender_relation": relationinDropdown,
              "status": dataObj.type
            }
          }

        })
        if (response && response?.data) {
          if (dataObj.type) {
            message.success('Request Successfully Accept')
          }
          this.setState({
            changeRelationShipAccept: false,

          })
          this.GetOtherBusinessProfile();
        }
      } catch (error) {
        console.error(error);
      }
    }

  }
  getResponceData = (ratingArray: any, rateName: any, first_rating: any, second_rating: any) => {
    Object.entries(ratingArray).forEach((rateObj: any) => {
      if (rateObj[0] === "Applied View Rating") {
        const getFirstRatingItems: any[] = [];
        rateObj[1].forEach((item: any) => {
          getFirstRatingItems.push({ value: item.score ? Number(item.score) : 0, ...item });
        });
        first_rating = { name: "Applied View Rating", array: getFirstRatingItems };
      }
      if (rateObj[0] === rateName) {
        const getSecondRatingItems: any[] = [];
        rateObj[1].forEach((item: any) => {
          getSecondRatingItems.push({ value: item.score ? Number(item.score) : 0, ...item });
        });
        second_rating = { name: rateName, array: getSecondRatingItems };
      }
      if (rateName === "Leadership") {
        if (rateObj[0] === "Leader") {
          const getSecondRatingItems: any[] = [];
          rateObj[1].forEach((item: any) => {
            getSecondRatingItems.push({ value: item.score ? Number(item.score) : 0, ...item });
          });
          second_rating = { name: "Leader", array: getSecondRatingItems };
        }
      }
    });

    this.setState({ generalRating: first_rating, userRateItems: second_rating });

  }

  getResponce = (response: any) => {
    if (response && response?.data) {
      const rateName = response.data.data.attributes.account.data.attributes.rate_as;
      const ratingArray = response.data.data.attributes.rating.data.attributes.rating;
      const userLastSeen = response?.data?.data?.attributes?.account?.data?.attributes?.last_active
      this.setState({ ratingData1: ratingArray, ratingOption: response.data.data.attributes.account.data.attributes.rating_option, lastSeen: userLastSeen })
      let first_rating, second_rating = {};
      this.getResponceData(ratingArray, rateName, first_rating, second_rating)

    }
  }

  getuserRateDetails = async (response: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/account_block/accounts/${this.state.account_id}`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      this.getResponce(response)


    } catch (error) {
      console.error(error);
    }
  }

  generalRatingChange = async (obj: any) => {
    const generalRating = { ...this.state.generalRating };
    generalRating["array"][obj.index]["value"] = obj.value;
    // console.log("generalRating", generalRating);
    this.setState({ generalRating: generalRating });
  }

  userRatingChange = async (obj: any) => {
    const userRating = { ...this.state.userRateItems };
    userRating["array"][obj.index]["value"] = obj.value;
    // this.setState({ generalRating: generalRating});
  }

  handlePostList = (data: any, status: string) => {
    const postData = this.state.postsDataArray;
    if (status === "update") {
      console.log(status, data);
      const updatePostList = postData?.map((item: any) => {
        if (item.id === data.id) {
          return data
        } else {
          return item
        }
      })
      this.setState({ postsDataArray: updatePostList });
    } else if (status === "delete") {
      const updatePostList = postData?.filter((item: any) => {
        return item.id != data?.id
      })
      this.setState({ postsDataArray: updatePostList });
    }
  }


  submitUsersRating = async () => {

    this.setState({ isAddRatingRateAsCustomer: false });
    const generalRating = { ...this.state.generalRating };
    const userRating = { ...this.state.userRateItems };


    const ratingObj = generalRating.array.reduce((prevObj: any, newObject: any) => {
      if (newObject.name) {
        return ({ ...prevObj, [newObject.name]: newObject.value || 0 });
      } else {
        return prevObj;
      }
    }, {});

    const userRatingObj = userRating.array.reduce((prevObj: any, newObject: any) => {
      if (newObject.name) {
        return ({ ...prevObj, [newObject.name]: newObject.value || 0 });
      } else {
        return prevObj;
      }
    }, {});

    const httpBody = {
      data: {
        attributes: {
          account_id: this.state.account_id,
          rating: {
            [userRating.name]: userRatingObj,
            [generalRating.name]: ratingObj
          }
        }
      }
    }
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_accountscoreranking/ratings`,
        method: 'post',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      if (response && response?.data) {
        this.GetOtherBusinessProfile();
      }
    } catch (error) {
      console.error(error);
    }
  }

  getCategory = () => {
    const header = {
      "Content-Type": configJSON.categoryContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCategoriesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.categoryMethodType
    );
  }

  getReview = () => {
    const header = {
      "Content-Type": configJSON.addReviewContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetReviewsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getReviewsEndPoint
      }?account_id=${this.state.account_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile  // Post
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getRating = () => {
    const header = {
      "Content-Type": configJSON.addRatingContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetRatingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRatingsEndPoint
      }?id=${this.state.account_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile  // Get
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onFinishAddReview = (values: any) => {
    this.setState({ isAddReview: false, isCompleteReview: true })
    const header = {
      "Content-Type": configJSON.addReviewContentType,
      token: localStorage.getItem("token")
    };
    let body = {
      "data": {
        "attributes": {
          "anonymous": values.anonymous,
          "account_id": this.state.account_id,
          "title": values.title,
          "description": values.description
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addReviewId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReviewsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo // Get
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onFinishReport = (values: any) => {
    const header = {
      "Content-Type": configJSON.reportPostApiContentType,
      token: localStorage.getItem("token")
    };
    let body = values;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addReport = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reportPostApiEndpoint}${this.state.account_id}/report`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.reportPostApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRemoveConnection = (requestId?: any) => {
    this.setState({ nextPageLoader: true })
    const header = {
      "Content-Type": configJSON.removeConnectionApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeConnectionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeConnectionApiEndpoint}/${requestId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.removeConnectionApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onHandleFollow = () => {
    this.setState({ followUnfollowAlert: false });
    const header = {
      "Content-Type": configJSON.APPLICATIONJSONContentType,
      token: localStorage.getItem("token")
    };
    let body = {
      "account_id": this.state.account_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followUnFollowEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.followMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUnblock = (value: number) => {
    const token = localStorage.getItem("token");
    this.setState({ blockAlertOpen: false });
    const header = {
      "Content-Type": configJSON.blockedAccountPostApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.postBlockedAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.blockedAccountPostApiEndpoint}/${value}/block`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.blockedAccountPostApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleOnClickConnect = async () => {
    Mixpanel.track("Connection Request")
    this.setState({isApiFailed: false});
    if (this.state.category_id !== null) {
      try {
        const response = await axios({
          url: `${baseURL}/bx_block_request_management/requests`,
          method: 'post',
          headers: {
            'token': this.state.token,
            'Content-Type': 'application/json'
          },
          data: {
            data: {
              "sender_relation": this.state.category_id,
              "account_id": this.state.account_id
            }
          }

        })
        if (response && response?.data) {
          this.setState({ isSelectCategoryModalVisible: false });
          this.GetOtherBusinessProfile();
          this.setState({
            nextPageLoader: true, isModalOpen: true,
            modelData: { relation: response?.data?.data?.attributes?.sender_relation, name: response?.data?.data?.attributes?.role == "business" ? response?.data?.data?.attributes?.profile?.data?.attributes?.company_name : response?.data?.data?.attributes?.full_name }
          })
        }
      } catch (error) {
        this.setState({ isSelectCategoryModalVisible: false, });
        this.setState({isApiFailed: true});
        console.error(error);
      }
    } else {
      this.setState({ errorMessageOnOk: true })
    }
  }

  handleChatPush = () => {
    return this.props.history.push({ pathname: '/chat', state: { userId: this.state.account_id } })
  }
  handleReviewAlert = () => {
    message.error("you already Reviewed")
  }

  getPosts = (values: any) => {
    const header = {
      token: localStorage.getItem("token")
    };
    const getPostRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addReviewId = getPostRequestMessage.messageId;
    getPostRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReviewsEndPoint
    );
    getPostRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getPostRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );

    runEngine.sendMessage(getPostRequestMessage.id, getPostRequestMessage);
  }
  // Customizable Area End
}
