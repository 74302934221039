import React from 'react'
import { Avatar, Space, Card, Typography, List } from 'antd';
const { Title, Text, Paragraph } = Typography;



const ReviewDataView = (props: any) => {
    // Customizable Area Start
    const { reviewData } = props;
    return (
        <div style={{ width: "100%", height: "100%", marginBottom: "1rem" }}>
            {
                reviewData?.map((item: any, id: number) => {
                    const now = new Date(item?.attributes?.created_at).toLocaleDateString('en-IN', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: "2-digit",
                        minute: "2-digit",
                    }).split(' ');

                    return (
                        <Card style={{ width: "100%", height: "100%" }} key={id}>
                            <div
                                style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                            >
                                <Space direction='horizontal' align='center'>
                                    <Avatar size={45} src={item.attributes.anonymous ? "" : item?.attributes?.reviewer?.data?.attributes?.photo} />
                                    <Text style={{ color: "rgba(0, 0, 0, 0.85)" }}>{item.attributes.anonymous ? "Anonymous" : item?.attributes?.reviewer?.data?.attributes?.full_name}</Text>
                                </Space>
                                <Text style={{ color: "rgb(117, 117, 117)" }}>
                                    {
                                        now[0] + " " + now[1].slice(0, 3) + " " + now[2] + " " + now[3] + " " + now[4]
                                    }
                                </Text>
                            </div>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "start", marginTop: "0.5rem" }}>
                                <Title level={5} style={{ color: "rgba(0, 0, 0, 0.85)" }}>{item?.attributes?.title}</Title>
                                <Paragraph style={{ color: "rgb(117, 117, 117)" }}>{item?.attributes?.description}</Paragraph>
                            </div>
                        </Card>
                    )
                })
            }
        </div>

    )
    // Customizable Area End
}

export default ReviewDataView;
