import React from "react";
import PostRatingController, { Props } from "./PostRatingController.web";
import { editer } from "../assets";
import CommonListItem from "../commonListItem.web";
import {
  EnvironmentOutlined,
  CloseOutlined,
  MessageFilled,
  PaperClipOutlined,
  PictureOutlined,
  SendOutlined,
  SmileOutlined,
  FormOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import "./../userFeed.css";
import moment from "moment";
import {
  Card,
  Row,
  Col,
  List,
  Input,
  Typography,
  Avatar,
  Button,
  Upload,
  Image,
  Badge,
  Radio,
  Tabs,
  Space,
  Modal, Form, Checkbox, Empty, message, Spin
} from "antd";
import CustomPost from "../CustomPost.web";
import Picker from "emoji-picker-react";
import ReactPlayer from "react-player";
import { Dimensions, PixelRatio } from "react-native";
import { Divider } from "react-native-elements/dist/divider/Divider";
// import CustomHeader from "../CustomHeader.web";
import SliderView from "./SliderView.web";
import ProfileRatingAppliedProgressBar from "./profileRatingAplliedProgressBar.web";
import {
  UserOutlined,
  // EnvironmentOutlined,
  CloseCircleOutlined,
  CheckCircleTwoTone,
  PlusCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;


const addRatingList = [
  {
    id: 1,
    value: "Attitude",
    color: "green",
  },
  {
    id: 2,
    value: "Performance",
    color: "#b53f3e",
  },
  {
    id: 3,
    value: "Productivity",
    color: "#b53f3e",
  },
  {
    id: 4,
    value: "Leadership",
    color: "#87ceeb",
  },
  {
    id: 5,
    value: "Integrity",
    color: "#87ceeb",
  },
  {
    id: 6,
    value: "Expertise",
    color: "#87ceeb",
  },
  {
    id: 7,
    value: "Diversity",
    color: "#87ceeb",
  },
];


export default class RatingReview extends PostRatingController {
  //Customizable Area Start
  render() {
    const { navigation } = this.props;
    const progressLinearBarData = this.state.ratingSends?.attributes?.rating['General Business Rating']
    const accountPlan = this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan
    const propLocation = this?.props?.location?.state?.home ? null : "My Profile /"
    const rowData = () =>{
      return(
        <Row >
                          {this.state.ratingReceived?.sort((k: any, l: any) => l?.attributes?.created_at?.localeCompare(k?.attributes?.created_at)).map((a: any, index: any) =>
                            <Col
                              lg={{ span: 24 }}
                              key={a.attributes?.id}
                              xl={{ span: 24 }}
                              xs={{ span: 24 }}
                              style={{ 
                                borderBottom: '1px solid #d0d0d0ab' ,
                                padding: '5px 15px', 
                              }}
                            >
                              <div style={{ 
                                alignItems: 'center', 
                                flexDirection: 'row', 
                                display: 'flex', 
                                margin: "8px 0px" ,
                                justifyContent: 'space-between', 
                              }}>
                                {/* {profile rating information} */}
                                <div style={{ 
                                  alignItems: 'center', 
                                  flexDirection: 'row', 
                                  display: 'flex', 
                                  width: '80%' ,
                                }}>
                                  <Avatar
                                    src={a?.attributes?.rater?.data?.attributes?.photo}
                                    size={50}
                                    style={{ marginLeft: "0px", backgroundColor: "lightgrey" }}
                                  />
                                  <div style={{ marginLeft: '1%' }}>
                                    <div><Text style={{
                                      color: 'grey',
                                      fontSize: '15px',
                                      letterSpacing: '0.2px'
                                    }}

                                    > <span
                                      style={{
                                        color: '#000',
                                        fontWeight: 600
                                      }}
                                    >{a?.attributes?.rater?.data?.attributes?.full_name}</span> has rated you as {' '}
                                      <span
                                        style={{
                                          color: '#000',
                                          fontWeight: 600,
                                          textTransform: 'lowercase'
                                        }}
                                      >{a?.attributes?.rater?.data?.attributes?.connected_as ? a?.attributes?.rater?.data?.attributes?.connected_as : "community"}</span></Text></div>
                                    <div><Text
                                      style={{
                                        color: 'grey',
                                        fontSize: '12px',
                                        opacity: '0.5',
                                        letterSpacing: '0.2px'
                                      }}
                                    >{moment(a?.attributes?.created_at).startOf('seconds').fromNow()}</Text></div>
                                  </div>
                                </div>
                              </div>
                            </Col>

                          )}
                        </Row>
      )
    }
    return (
      <div
        className="background"
        style={{
          backgroundColor: "rgb(246, 246, 246)",
        }}
      >
        {this.redirectUser()}
        {/* <CustomHeader {...this.props} dotVisible={false} /> */}
        <Row
          style={{
            paddingTop: "15px", marginBottom: 7, marginLeft: 20
          }}
        >
          <Text
            className="textlabel_subheadingText"
          >
            {propLocation}
          </Text>
          <Text
            className="textlabel_subheadingText2"
          >
            {" "}
            Ratings
          </Text>
        </Row>
        <span className="textlabel_headingText" style={{ marginLeft: 20 }} >My Ratings</span>
        <Card style={{ width: "100%", backgroundColor: "rgb(246, 246, 246)", border: 'none' }}>
          <Tabs
            defaultActiveKey={`${this?.props?.location?.state?.keyValue}`}
            size="large"
            tabBarStyle={{
              color: "#919191",
              backgroundColor: "#dee5eb",
              height: "3rem"
            }}
            style={{
              borderRadius: "3px",
              backgroundColor: "#f1f0f5"
            }}
          >
            <TabPane
              tab="Rating Sent"
              key="1"
              style={{
                width: "100%",
                height: "100%",
                // margin: "auto"
              }}
            >
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  // marginBottom: "1rem",
                  backgroundColor: "#f1f0f5"
                }}
              >
                <>
                  {this.state.EditRatingLoader ? <Spin /> : <div>
                    {this.state.ratingSends?.length !== 0 ?
                      this.state.ratingSends &&
                      <>
                        <Row >
                          {this.state.ratingSends?.sort((k: any, l: any) => l?.attributes?.created_at?.localeCompare(k?.attributes?.created_at)).map((a: any, index: any) =>
                            <Col
                              key={a.attributes?.id}
                              xs={{ span: 24 }}
                              lg={{ span: 24 }}
                              xl={{ span: 24 }}
                              style={{ padding: '5px 15px', borderBottom: '1px solid #d0d0d0ab' }}
                            >
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: "8px 0px" }}>
                                {/* {profile rating information} */}
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '80%' }}>
                                  <Avatar
                                    src={a?.attributes?.account?.data?.attributes?.photo}
                                    size={50}
                                    style={{ marginLeft: "0px", backgroundColor: "lightgrey" }}
                                  />
                                  <div style={{ marginLeft: '1%' }}>
                                    <div><Text style={{
                                      color: 'grey',
                                      fontSize: '15px',
                                      letterSpacing: '0.2px'
                                    }}

                                    >
                                      You have rated<span
                                        style={{
                                          color: '#000',
                                          fontWeight: 600
                                        }}
                                      >{' '}{a?.attributes?.account?.data?.attributes?.full_name}</span> as{' '}
                                      <span
                                        style={{
                                          color: '#000',
                                          fontWeight: 600,
                                          textTransform: 'lowercase'
                                        }}
                                      >{a?.attributes?.account?.data?.attributes?.connected_as}</span></Text></div>
                                    <div><Text
                                      style={{
                                        color: 'grey',
                                        fontSize: '12px',
                                        opacity: '0.5',
                                        letterSpacing: '0.2px'
                                      }}
                                    >{moment(a?.attributes?.created_at).startOf('seconds').fromNow()}</Text></div>
                                  </div>
                                </div>
                                {/* {Edit button } */}
                              </div>
                            </Col>

                          )}
                        </Row>
                      </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                  </div>
                  }</>

              </Card>
            </TabPane>
            <TabPane
              tab="Rating Received"
              key="2"
              style={{
                width: "100%",
                height: "100%",
                // margin: "auto"
              }}
            >

              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  // marginBottom: "1rem",
                  backgroundColor: "#f1f0f5"
                }}
              >
                <>
                  {this.state.ReceivedRatingLoader ? <Spin /> : <div>
                    {this.state.ratingReceived?.length !== 0 ?
                      this.state.ratingReceived &&
                      <>
                        {rowData()}
                      </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                  </div>}
                </>

              </Card>
              
            </TabPane>
          </Tabs>
        </Card>
      </div>
    );
  }
  //Customizable Area End
}
