import React, { useEffect, useState } from "react";
import "./../userFeed.css";
import {
    Typography,
    Box,
    TextField,
    FormControl,
    Button
} from "@material-ui/core";
import { truncateSync } from "fs";

import { message } from "antd"

export default function QuestionComponent(props: any) {

    const { questObj, classes, index, saveChanges, profileDetails, handleSaveNext, tabname } = props;
    const [values, setValues] = useState({
        old_name: questObj.name,
        name: questObj.name,
        description: questObj.description
    });
    const [disable, setDisableSave] = useState(false)
    const [ErrorName, setErrorName] = useState("")
    const [fieldDisabled, setFieldDisabled] = useState(true)
    const [ErrorDescription, setErrorDescription] = useState("")
    useEffect(() => {
        setValues({
            old_name: questObj.name,
            name: questObj.name,
            description: questObj.description
        });
    }, [questObj]);
    // console.log("disabledfield", disabledfield, handleChanges)

    const valueChange = (event: any) => {
        let oldValues: any = { ...values };
        if (event.target.name) {
            oldValues[event.target.name] = event.target.value ? event.target.value : "";
            setValues(oldValues);
        }

        if (oldValues.name === "") {
            setErrorName("Name can't be empty")
        } else {
            setErrorName("")
        }
        if (oldValues.description === "") {
            setErrorDescription("Description can't be empty")
        } else {
            setErrorDescription("")
        }
    };

    const getTabWiseObjectNameForAPI = (tabName: string) => {
        return tabName
    }

    const saveButton = async () => {
        if (values.name && values.description) {
            await saveChanges(values, getTabWiseObjectNameForAPI(tabname))
            setFieldDisabled(true)
        } else {
            message.error("Input field should not be empty!", 1)
            setDisableSave(true)
            setTimeout(() => {
                setDisableSave(false)
            }, 1000)
        }
    }

    return (
        <React.Fragment>
            <div className="" style={{ background: 'white', padding: "20px 10px", borderRadius: '4px' }}>
                <FormControl fullWidth className={classes.formController}>
                    <TextField
                        fullWidth
                        variant="standard"
                        label={`Rating Parameter ${index + 1}`}
                        type="text"
                        name='name'
                        id={questObj.name}
                        className={classes.fieldInput1}
                        disabled={fieldDisabled}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: classes.labelProps
                            }
                        }}
                        InputProps={{
                            disableUnderline: true,
                            classes: { root: classes.cssOutlinedInput1 },
                        }}
                        value={values.name}
                        onChange={valueChange}
                        data-test-id="name"
                    />
                    <p style={{ color: 'red', fontSize: 12 }}>{ErrorName && ErrorName}</p>
                </FormControl>

                <FormControl fullWidth className={classes.formController}>
                    <TextField
                        fullWidth
                        variant="standard"
                        label={`Rating Parameter ${index + 1} Information`}
                        type="text"
                        name='description'
                        id={questObj.title2}
                        className={classes.fieldInput1}
                        disabled={fieldDisabled}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: classes.labelProps
                            }
                        }}
                        InputProps={{
                            disableUnderline: true,
                            classes: { root: classes.cssOutlinedInput2 },
                        }}
                        value={values.description}
                        multiline
                        rows={4}
                        onChange={valueChange}
                        data-test-id="description"
                    />
                    <p style={{ color: 'red', fontSize: 12 }}>{ErrorDescription && ErrorDescription}</p>
                </FormControl>
                <div style={{ padding: '0px 10px', display: 'flex' }}>
                    {fieldDisabled ?

                        <Button
                            data-testid="Edit_button"
                            disabled={profileDetails?.attributes?.account?.data?.attributes?.account_plan !== "premium"}
                            onClick={() => {
                                if (profileDetails?.attributes?.account?.data?.attributes?.account_plan === "premium") {
                                    setFieldDisabled(false);
                                    handleSaveNext(false);
                                }
                            }}
                            variant="contained"
                            className={classes.editButton}
                        >
                            Edit
                        </Button>
                        : <Button
                            data-testid="save_Button"
                            disabled={profileDetails?.attributes?.account?.data?.attributes?.account_plan !== "premium"}
                            onClick={() => {
                                if (profileDetails?.attributes?.account?.data?.attributes?.account_plan === "premium") saveButton()
                            }}
                            variant="contained"
                            className={classes.editButton}
                        >
                            Save
                        </Button>}

                </div>
            </div>
        </React.Fragment>
    )

}
// Customizable Area Start
// Customizable Area End
