import React from 'react';
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { message, Mentions } from "antd";

// Customizable Area Start
import { getStorageData, setStorageData, removeStorageData, isEmpty } from "../../../framework/src/Utilities";
import axios from "axios";
import { cloneDeep } from "lodash";
import { Redirect } from "react-router-dom";
// @ts-ignore
import { baseURL } from '../../../framework/src/config.js';
interface AddConnectionErrorType {
  response: {
    status: number;
  }
}
// Customizable Area End
const googleAPIkey = "AIzaSyCBH92YX2oCbI7PChqFNrgzw-PolHAiKZc";
import { RouterProps } from "react-router";
import { createRef } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { profile } from "console";
import Mixpanel from '../../../components/src/Mixpanel';
const { Option, getMentions } = Mentions;
export const configJSON = require("./config");
export interface Props extends RouterProps {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isBusinessUser: boolean;
  createPostDescription: any;
  postList: any[];
  videoArrayList: any;
  isloader: boolean;
  token: string | null;
  isCreatePostVisible: boolean;
  fullAddress: string;
  createPostImages: any;
  loading: boolean;
  likedItems: number[];
  followedItems: number[];
  userProfile: any;
  isExpanded: boolean;
  loginUser: any;
  accountDetails: any
  isReportIssueVisible: boolean;
  isShowInvitationModal: boolean;
  isShowConnectionModal: boolean;
  reportPostID: any;
  value: any;
  relationValue: any;
  getMentionsArray: any;
  getMentionsArrayList: Array<string>;
  GetConnectionArrayAcc: any;
  tagged_connection_ids: any;
  isEmojiPickerEnable: boolean;
  premiumPlanValidationModal: boolean;
  postComments: any;
  AllMedia: any
  knownPeople: any
  allCommunity: any
  joinnedCommunities: any
  birthAnniversaries: any;
  workAnniversaries: any;
  peoplemayKnow: any;
  businessMayKnow: {
    data: {
      accounts: {
        data: {
          id: string;
          type: string;
          attributes: {
            full_name: string;
            profile: {
              data: {
                id: string;
                type: string;
                attributes: {
                  company_name: string;
                  photo: string;
                }
              }
            }
          }
        }[]
      }
    }
  }[];
  allInvitations: any;
  businessConnection: any;
  personalConnection: any;
  invitationUserInfo: any;
  loadInvitationData: any;
  redirect: boolean;
  isMounted: boolean;
  loadcommunity: boolean;
  loadUpcoming: boolean;
  loadpeopleKnow: boolean;
  loadBusinessKnow: boolean;
  loadInvitation: boolean;
  loadLocation: boolean;
  postlocation: any;
  latitude: any,
  longitude: any,
  followersAndFollowingOpen: boolean;
  createPostOpen: boolean;
  keyValue: string;
  followers: any;
  tagsOpen: boolean;
  editPostOpen: boolean;
  editData: any;
  isCommentloader: boolean;
  isloadEditPost: boolean;
  address: any;
  location: boolean;
  loadComments: any;
  editortext: any;
  open: boolean;
  suggestions: any
  deleteExistingMedias: any;
  deleteId: any;
  postReportSave: string;
  followersTag: any;
  postHide: any;
  loadAccountData: boolean;
  profileInformationId: any;
  postPagination: number;
  postPaginationLoading: boolean;
  selectTags: any;
  AniversaryFullData: any;
  addConnectionSelectError: string;
  isModel: boolean;
  responseHandler: any
  userAnalytics: any
  showLoadMore: boolean;
  showLoadMoreLoading: boolean;
  // Customizable Area End
}

// interface allInvitationsData{
//   data: [],
//   total_requests_count: number;
// }

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class USerFeedController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  labelLogOut: string;
  labelProfile: string;
  labelSetting: string;
  labelApplied: string;
  labelHome: string;
  labelMyNetwork: string;
  labelCommunity: string;
  labelChat: string;
  labelAt: string;
  labelCommunityForum: string;
  labelWorkAnniversaries: string;
  labelBirthAnniversaries: string;
  labelKnownPeople: string;
  businessKnownPeople: string;
  labelInvitations: string;
  placeHolderSearchContact: string;
  placeHolderWhatOnMind: string;
  btnLearnMore: string;
  SearchUserDataGetId: any;
  PostID: any;
  getHomeFeedPostsApiCallId: any;
  getallHomeFeedPostsApiCallId: any;
  getPendingInvitationsApiCallId: any;
  getKnownPeopleApiCallId: any;
  getNetworkConnectionAppliedId: any;
  getAllCommunityApiCallId: any;
  addCommentInPost: any;
  addReplyCommentInPost: any;
  getCommentInPostApiCallID: any;
  getEditPostApiCallID: any;
  unsubscribe: any;
  getConnectionApiCallId: any;
  deletePostApiCallId: any;
  geteditFeedPostApiCallId: any;
  editPostID: any;
  premiumPlanValidationModal: boolean;
  getFollowersApiCallId: any;
  getUserAnalyticsApiCallId: any;
  GetCategorySelectionByNetworkforConnection: any
  GetCategorySelectionByNetwork: any
  savePostApiCall: any
  GetAccountDetailsApiId: any;
  GetProfielInformationId: any;
  followId: any
  userAnalytics: any
  // Customizable Area End
  contentRef = createRef<any>();
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      isBusinessUser: false,
      createPostDescription: EditorState.createEmpty(),
      postList: [],
      videoArrayList: [],
      isloader: true,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      isCreatePostVisible: false,
      fullAddress: "",
      createPostImages: [],
      loading: true,
      premiumPlanValidationModal: false,
      likedItems: [],
      followedItems: [],
      loginUser: {},
      userProfile: {},
      isExpanded: false,
      profileInformationId: {},
      isReportIssueVisible: false,
      isShowInvitationModal: false,
      isShowConnectionModal: false,
      reportPostID: "",
      value: '',
      relationValue: '',
      GetConnectionArrayAcc: [],
      getMentionsArray: [],
      getMentionsArrayList: [],
      selectTags: [],
      tagged_connection_ids: [],
      isEmojiPickerEnable: false,
      postComments: [],
      AllMedia: [],
      accountDetails: {},
      knownPeople: [],
      allCommunity: [],
      joinnedCommunities: [],
      peoplemayKnow: [],
      businessMayKnow: [],
      allInvitations: [],
      birthAnniversaries: [],
      workAnniversaries: [],
      businessConnection: [],
      personalConnection: [],
      invitationUserInfo: {},
      loadInvitationData: [],
      redirect: false,
      isMounted: false,
      loadcommunity: true,
      loadUpcoming: true,
      loadpeopleKnow: true,
      loadBusinessKnow: true,
      loadInvitation: true,
      loadLocation: false,
      latitude: null,
      longitude: null,
      postlocation: '',
      followersAndFollowingOpen: false,
      createPostOpen: false,
      keyValue: "",
      followers: [],
      tagsOpen: false,
      editPostOpen: false,
      editData: {},
      isCommentloader: false,
      isloadEditPost: false,
      address: null,
      location: false,
      loadComments: false,
      editortext: '',
      open: false,
      suggestions: [],
      deleteExistingMedias: [],
      deleteId: '',
      postReportSave: '',
      followersTag: [],
      postHide: '',
      loadAccountData: false,
      postPagination: 1,
      postPaginationLoading: false,
      AniversaryFullData: [],
      addConnectionSelectError: "",
      isModel: false,
      responseHandler: {},
      userAnalytics: undefined,
      showLoadMore: true,
      showLoadMoreLoading: false,
    };

    this.getUserLocation = this.getUserLocation.bind(this);
    this.getCordinates = this.getCordinates.bind(this);
    this.reverseGeoCodeCordinates = this.reverseGeoCodeCordinates.bind(this);
    this.handleLocationError = this.handleLocationError.bind(this);

    this.btnLearnMore = configJSON.btnLearnMore;
    this.placeHolderWhatOnMind = configJSON.placeHolderWhatOnMind;
    this.placeHolderSearchContact = configJSON.placeHolderSearchContact;
    this.labelLogOut = configJSON.labelLogOut;
    this.labelProfile = configJSON.labelProfile;
    this.labelSetting = configJSON.labelSetting;
    this.labelApplied = configJSON.labelApplied;
    this.labelHome = configJSON.labelApplied;
    this.labelMyNetwork = configJSON.labelMyNetwork;
    this.labelCommunity = configJSON.labelCommunity;
    this.labelChat = configJSON.labelChat;
    this.labelAt = configJSON.labelAt;
    this.labelCommunityForum = configJSON.labelCommunityForum;
    this.labelWorkAnniversaries = configJSON.labelWorkAnniversaries;
    this.labelBirthAnniversaries = configJSON.labelBirthAnniversaries;
    this.labelKnownPeople = configJSON.labelKnownPeople;
    this.businessKnownPeople = configJSON.businessKnownPeople;
    this.labelInvitations = configJSON.labelInvitations;

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentWillMount() {
    // await this.GetProfileInformation();
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setState({ isMounted: true });
    // console.log(this.props.history, 'history');
    let token = await localStorage.getItem("token");
    let log = await getStorageData("loginUser")
    let updatedProfile = await getStorageData("accountData")
    // console.log(updatedProfile);
    await this.getAccountDetails(log?.id)

    await this.GetProfileInformation();
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    this.getFollowers();
    if (this.state.isMounted) {
      await this.getHomeFeedPosts();
      await this.getAllCommunity();
      await this.getUpcomingWorkAnniversaries();
      await this.getPeopleYouknow();
      await this.getBusinessYouknow();
      await this.getInvitations();
      this.getNetworkConnectionApplied();
    }

    this.getConnections();
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }
  getUserAnalytics = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserAnalyticsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserAnalyticsApiCallId
    );
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: localStorage.getItem("token")
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GETMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleFollowersAndFollowingCancel = () => {
    this.setState({ followersAndFollowingOpen: false })
  }

  handlecreatePostOpenCancel = () => {
    this.setState({ createPostOpen: false })
  }
  handlecreatePostOpen = () => {
    this.setState({
      createPostOpen: true,
      getMentionsArray: [],
      getMentionsArrayList: []
    })
  }
  handleEditPostOpen = (id: any) => {
    this.setState({
      editPostOpen: true,
      isCreatePostVisible: false,
      createPostDescription: EditorState.createEmpty(),
      tagged_connection_ids: [],
      tagsOpen: false,
      getMentionsArray: [],
      selectTags: [],
      getMentionsArrayList: []
    })
    this.getFeedPost(id);
  }
  handleLearnMoreClick = () => {
    this.setState({
      isExpanded: !this.state.isExpanded
    })
  }
  handleFollowersAndFollowingOpen = (data: string) => {
    console.log(data)
    if (data.match(/followers/gi)) {
      this.props.history.push({ pathname: "/followers/following", state: { keyValue: "1" } });
    } else if (data.match(/Following/gi)) {
      this.props.history.push({ pathname: "/followers/following", state: { keyValue: "2" } });
    } else if (data.match(/Posts/gi)) {
      // console.log(this.state.userProfile?.attributes?.profile?.data?.attributes?.role ==="business",'userProfile__')
      if (this.state.userProfile?.role === "business") {
        return this.props.history.push({ pathname: "/MyBusiness/Profile" });
      } else {
        return this.props.history.push({ pathname: "/MyPersonalProfile/Profile" });
      }
    } else if (data.match(/Connections/gi)) {
      this.props.history.push({ pathname: "/myNetwork" });
    } else if (data.match(/Ratings Received/gi)) {
      this.props.history.push({ pathname: "/Rating", state: { keyValue: "2", home: true } });
    } else if (data.match(/Ratings Sent/gi)) {
      this.props.history.push({ pathname: "/Rating", state: { keyValue: "1", home: true } });
    } else if (data.match(/Resume Downloads/gi)) {
      this.props.history.push({ pathname: "/UserAnalytics", state: { keyValue: "1" } });
    } else if (data.match(/Rating Users/gi)) {
      this.props.history.push({ pathname: "/UserAnalytics", state: { keyValue: "2" } });
    } else if (data.match(/Review/gi)) {
      this.props.history.push({ pathname: "/UserAnalytics", state: { keyValue: "3" } });
    } else if (data.match(/Profile Visit/gi)) {
      this.props.history.push({ pathname: "/UserAnalytics", state: { keyValue: "4" } });
    }
  }
  getFeedPost = async (id: number) => {
    this.setState({
      AllMedia: [], createPostImages: [], videoArrayList: [], tagged_connection_ids: [],
      isloadEditPost: true,
      deleteExistingMedias: []
    });
    // this.setState({ isloader: true });
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.geteditFeedPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.getUserPostsApiEndpoint
      `${configJSON.getPostsApiEndpoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  handleDataUpdateAfterEditorDelete = async (post: any, str?: any) => {
    await this.GetProfileInformation()
    let list = this.state.postList.map((item: any) => {
      if (item?.attributes?.id != post?.attributes?.id) {

        return item
      } else {

        if (str === 'delete') {
          return
        } else {
          return post
        }
      }
    })
    this.setState({
      postList: list.filter((item: any) => item !== undefined)
    })

  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson?.errors) {
        if (responseJson?.errors[0]?.token) {
          await localStorage.removeItem("token");
          await removeStorageData("loginUser");
          await removeStorageData("userProfile");
          await removeStorageData("accountData");
          await removeStorageData("signUpUser");
          await removeStorageData("signUpUserResponse");
          await removeStorageData("verificationData");
          await removeStorageData("signUpUserID");
          await removeStorageData("isFromLogin");
          await removeStorageData("updatedProfile");
          await removeStorageData("userProfile-1");

          this.props.history.push({
            pathname: "/"
          });

        }
      }
      this.handleFollowResponce(apiRequestCallId, responseJson)
      //get Edit data
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.geteditFeedPostApiCallId) {
          // console.log(responseJson, "responseJson", this.state.getMentionsArray, this.state.selectTags)
          let mention = this.state.selectTags
          responseJson.data?.attributes?.taggings?.data.map((item: any) => {

          })
          let tags: any = []
          responseJson.data?.attributes?.taggings?.data.map((item: any) => {
            const name = item?.attributes?.role === "business" ? `@${item?.attributes?.profile?.data?.attributes?.company_name}` :
              `@${item?.attributes?.full_name}`
            tags = [...tags, ...getMentions(name)]
          })
          console.log(tags, "tags asdlasjk lkad")
          this.setState({
            getMentionsArray: tags
          })
          this.setState({
            editData: responseJson.data, isloadEditPost: false,
            address: responseJson.data?.attributes?.location || null,

          })
          this.setState({ createPostDescription: EditorState.createWithContent(ContentState.createFromText(responseJson.data?.attributes?.body)) });
          // this.setState({ tagged_connection_ids: responseJson.data?.attributes?.outside_taggings?.data });
          this.setState({ AllMedia: responseJson.data?.attributes?.media });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getallHomeFeedPostsApiCallId) {
          await this.setState({
            postList: responseJson?.data,
            // likedItems:,
            isloader: false,
            editPostOpen: false,
            isloadEditPost: false,
            getMentionsArray: [], selectTags: [],
            deleteExistingMedias: [],
            AllMedia: [], createPostImages: [], videoArrayList: [], tagged_connection_ids: [],
          });
          if (responseJson?.data) {
            // this.setState({
            //   postList: [...responseJson?.data],
            // })
            let value = responseJson.data.map((c: any) => c.attributes.liked && this.setState((prevState) => ({
              likedItems: [...prevState.likedItems, c.id],
            })));
            responseJson?.data.map((ele: any) => console.log(ele))
            let valueFollowed = responseJson.data.map((c: any) => c.attributes.account.data.attributes.following && this.setState((prevState) => ({
              followedItems: [...prevState.followedItems, c?.attributes?.account?.data?.id],
            })));
          }
          console.log(responseJson.data, 'home feed responseJson.data')
        } else if (apiRequestCallId === this.getHomeFeedPostsApiCallId) {

          if (responseJson?.data) {
            if (!responseJson.data?.length) {
              this.setState({
                showLoadMore: false
              })
            }

            responseJson.data.map((c: any) => c.attributes.liked && this.setState((prevState) => ({
              likedItems: [...prevState.likedItems, c.id],
            })));
            responseJson.data.map((c: any) => c.attributes.account.data.attributes.following && this.setState((prevState) => ({
              followedItems: [...prevState.followedItems, c?.attributes?.account?.data?.id],
            })));
          }
          await this.setState({
            postList: [...this.state.postList,...responseJson?.data],
            showLoadMoreLoading: false,
            isloader: false,
            editPostOpen: false,
            isloadEditPost: false,
            getMentionsArray: [], selectTags: [],
            deleteExistingMedias: [],
            postPaginationLoading: false,
            AllMedia: [], createPostImages: [], videoArrayList: [], tagged_connection_ids: [],
          });
          // this.getallHomeFeedPosts();
          // console.log(responseJson.data, 'home feed responseJson.data')
        } else if (apiRequestCallId === this.getallHomeFeedPostsApiCallId) {
          await this.setState({
            postList: responseJson?.data,
            // likedItems:,
            isloader: false,
            editPostOpen: false,
            getMentionsArray: [], selectTags: [],
            isloadEditPost: false,
            deleteExistingMedias: [],
            AllMedia: [], createPostImages: [], videoArrayList: [], createPostDescription: EditorState.createEmpty(), isCreatePostVisible: false, tagged_connection_ids: [],
          });
          if (responseJson?.data) {
            this.setState({
              postList: [...responseJson?.data],
            })
            let value = responseJson.data.map((c: any) => c.attributes.liked && this.setState((prevState) => ({
              likedItems: [...prevState.likedItems, c.id],
            })));
            // responseJson?.data.map((ele: any) => console.log(ele))
            let valueFollowed = responseJson.data.map((c: any) => c.attributes.account.data.attributes.following && this.setState((prevState) => ({
              followedItems: [...prevState.followedItems, c?.attributes?.account?.data?.id],
            })));
          }
        }
        else if (apiRequestCallId === this.editPostID) {
          console.log("create posts: ", JSON.stringify(responseJson));
          if (responseJson) {
            this.handleDataUpdateAfterEditorDelete(responseJson?.data)
            this.setState({
              AllMedia: [], createPostImages: [], videoArrayList: [], tagged_connection_ids: [],
              // postList: responseJson.data,
              tagsOpen: false,
              location: false,
              address: null,
              createPostDescription: EditorState.createEmpty(),
              isloadEditPost: false,
              editPostOpen: false,
              getMentionsArray: [], selectTags: [],
              isloader: false,
              isCreatePostVisible: false
            });
          }
          // this.getHomeFeedPosts();
        } else if (apiRequestCallId === this.PostID) {
          await this.GetProfileInformation()
          this.setState({
            deleteExistingMedias: [],
            AllMedia: [], createPostImages: [], videoArrayList: [], createPostDescription: EditorState.createEmpty(),
            // postList: responseJson.data,
            getMentionsArray: [], selectTags: [],
            tagsOpen: false,
            address: null,
            location: false,
            isloader: false,
            isCreatePostVisible: false
          }, () => { this.getHomeFeedPosts() });
        } else if (apiRequestCallId === this.savePostApiCall) {
          // console.log("create posts: ", JSON.stringify(responseJson));
          this.setState({
            deleteExistingMedias: [],
            AllMedia: [], createPostImages: [], videoArrayList: [], createPostDescription: EditorState.createEmpty(),
            // postList: responseJson.data,
            isloader: false,
          });
          // window.location.reload();
          if (this.state.postHide === "hide") {
            this.handleDataUpdateAfterEditorDelete(responseJson?.data, "delete")
          }
          this.getHomeFeedPosts();

        } else if (apiRequestCallId === this.addCommentInPost) {
          this.setState({
            isCommentloader: false
          });
          if (responseJson?.data) {
            this.getComments(responseJson?.data?.attributes?.commentable_id);
          }
          this.getallHomeFeedPosts();
          // window.location.reload();
        } else if (apiRequestCallId === this.addReplyCommentInPost) {
          this.setState({
            isloader: false,
            // loadComments: false
          });
          // this.getHomeFeedPosts();
          // window.location.reload();
        } else if (apiRequestCallId === this.GetCategorySelectionByNetworkforConnection) {
          this.setState({
            isShowConnectionModal: true,
            loadInvitationData: responseJson.relations.sort()
          })

        } else if (apiRequestCallId === this.GetCategorySelectionByNetwork) {
          this.setState({
            isShowInvitationModal: true,
            loadInvitationData: responseJson.relations.sort()
          })
        }
        else if (apiRequestCallId === this.getCommentInPostApiCallID) {
          // console.log("get comments: ", responseJson);
          let list = this.state.postList.map((value) =>
            value.id == responseJson.data[0]?.attributes?.commentable_id
              ? { ...value, attributes: { ...value.attributes, comments: { data: responseJson.data } } }
              : value
          );

          this.setState({
            // loadComments: false,
            postComments: responseJson.data,
            postList: list
          });
        } else if (apiRequestCallId === this.getPendingInvitationsApiCallId) {
          // console.log("network request: ", JSON.stringify(responseJson));
          this.setState({
            // postList: responseJson.data,
            isloader: false,
          });
        } else if (apiRequestCallId === this.getKnownPeopleApiCallId) {
          // console.log("konown people: ", JSON.stringify(responseJson));
          this.setState({
            knownPeople: responseJson.data,
            isloader: false,
          });
        } else if (apiRequestCallId === this.getNetworkConnectionAppliedId) {
          let receivedData = responseJson.data;
          let finalFriendsData: any = [];
          receivedData &&
            receivedData.forEach((item: any, index: any) => {
              if (item && item.attributes && item.attributes.full_name) {
                let finalItem = item;
                finalItem["name"] = item.attributes.full_name;
                finalFriendsData.push(finalItem);
              }
            });
          // console.log("finalFriendsData - ", finalFriendsData);
          this.setState(
            {
              GetConnectionArrayAcc: finalFriendsData,
            },
            () => {
              // console.log("responseJson.data rcvd = ", responseJson.data);
              if (
                this.state.GetConnectionArrayAcc &&
                this.state.GetConnectionArrayAcc.length > 0
              ) {
                // console.log("categories: ", this.state.GetConnectionArrayAcc);
                const connectedFriends = this.state.GetConnectionArrayAcc.map(
                  (item: any) => item.attributes.connected_as
                )
                  .filter((v: any, i: any, a: any) => a.indexOf(v) == i)
                  .filter((x: any) => x !== null)
                  .map((x: any) => {
                    return { value: x };
                  });
              }
            }
          );
        } else if (apiRequestCallId === this.getUserAnalyticsApiCallId) {
          this.setState({
            userAnalytics: responseJson,
          })
        }

        if (apiRequestCallId && responseJson) {
          if (apiRequestCallId === this.GetAccountDetailsApiId) {
            // removeStorageData('accountData');
            await setStorageData("accountData", responseJson.data);
            this.setState({ loadAccountData: false });
            if (this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "premium") {
              this.getUserAnalytics()
            }
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }

        if (apiRequestCallId && responseJson) {
          if (apiRequestCallId === this.GetProfielInformationId) {
            const res = responseJson?.data?.attributes?.profile?.data?.attributes;
            this.setState({
              userProfile: res,
              profileInformationId: responseJson?.data?.attributes
            });
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }
        // get connections
        if (apiRequestCallId && responseJson) {
          if (apiRequestCallId === this.getConnectionApiCallId) {
            this.setState({ followers: responseJson.data });
            console.log(this.state.followers, "folllllll")
          }
        } else {
          this.parseApiErrorResponse(responseJson);
          this.setState({
            editPostOpen: false, isloadEditPost: false, getMentionsArray: [], selectTags: []
          })
        }
        if (apiRequestCallId && responseJson) {
          if (apiRequestCallId === this.getFollowersApiCallId) {
            this.setState({ followersTag: responseJson.data });
            console.log(this.state.followers, "folllllll")
          }
        } else {
          this.parseApiErrorResponse(responseJson);
          this.setState({
            editPostOpen: false, isloadEditPost: false, getMentionsArray: [], selectTags: []
          })
        }
        if (apiRequestCallId === this.deletePostApiCallId) {

          if (responseJson) {
            const data = { attributes: { id: this.state.deleteId } }
            // this.handleDataUpdateAfterEditorDelete(data, 'delete')
            this.GetProfileInformation()
          }
        }
      }
      else {
        this.parseApiErrorResponse(responseJson);
        this.setState({
          editPostOpen: false, isloadEditPost: false, getMentionsArray: [], selectTags: []
        })
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );
    }

    let data: any = await getStorageData("loginUser")
    // console.log(res, 'loginUser');
    if (data) {
      this.setState({
        loginUser: data,
        isBusinessUser: data?.role === "business",
      });
    }

    await getStorageData("userProfile").then((res) => {
      const response = res?.attributes?.profile?.data?.attributes;
      console.log("userProfile res-->", response);
      this.setState({ userProfile: response },
        () => console.log(this.state.userProfile, 'userProfile')
      );
      // console.log(res.attributes.profile.data ? 'user okay': 'not okay','check user')
      // if (res.attributes.profile.data) {
      //   this.props.history.replace('/')
      // }
    });
    await getStorageData("accountData").then((res) => {
      // console.log("accountDetails-->", res);
      this.setState({ loading: true });
      this.setState({ accountDetails: res, loading: false }
        // , () => console.log(this.state.accountDetails, 'accountDetails')
      );
      if (res?.attributes?.account?.data?.attributes?.profile_setup === false) {
        if (res?.attributes?.account?.data?.attributes?.role === "business") {
          return this.props.history.replace('/setup/profile/business');
        } else {
          return this.props.history.replace('/setup/profile/personal');
        }
      }
    });
    // Customizable Area End
  }

  // Customizable Area Start

  handlePostList = (data: any, status: string) => {
    const postData = this.state.postList;
    if (status === "update") {
      console.log(status, data);
      const updatePostList = postData?.map((item: any) => {
        if (item.id === data.id) {
          return data
        } else {
          return item
        }
      })
      this.setState({ postList: updatePostList });
    } else if (status === "delete") {
      const updatePostList = postData?.filter((item: any) => {
        return item.id != data?.id
      })
      this.setState({ postList: updatePostList });
      this.handleDataUpdateAfterEditorDelete(data)
    }
  }

  getDomainSkills(userDataHere: any) {
    if (
      isEmpty(userDataHere)
      // isEmpty(userDataHere.attributes) ||
      // isEmpty(userDataHere.attributes.domain_skills)
    )
      return "";
    const domainSkillsPresent = userDataHere;
    const domainSkillsStr = domainSkillsPresent.join(", ");
    // console.log("domainSkillsStr = ", domainSkillsStr);
    return domainSkillsStr;
  }

  getUserLocation() {
    this.setState({ loadLocation: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.getCordinates,
        this.handleLocationError
      );
    } else {
      console.log("geo location is not supported in browser");
      this.setState({ loadLocation: false });
    }
  }

  getCordinates(position: any) {
    console.log(position.coords.latitude);
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      // userAddress
    });
    this.reverseGeoCodeCordinates();
  }

  handleNetworkRelation = async (account_id: any, action: string) => {
    const user = await getStorageData('loginUser')
    const header = {
      "Content-Type": configJSON.getRequestsApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCategorySelectionByNetwork = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNetworkRelation}?account_id=${account_id}&action_type=${action}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRequestsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNetworkRelationforConnection = async (account_id: any, action: string) => {
    const user = await getStorageData('loginUser')
    const header = {
      "Content-Type": configJSON.getRequestsApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCategorySelectionByNetworkforConnection = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNetworkRelation}?account_id=${account_id}&action_type=${action}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRequestsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFollowResponce = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.followId) {
      if (responseJson?.data) {
        await this.getPeopleYouknow()
        await this.getBusinessYouknow();
        await this.GetProfileInformation()
        this.updateFollowStatus(responseJson?.data?.id, responseJson?.data?.attributes?.following)
      }
    }
  }

  async reverseGeoCodeCordinates() {
    let key = "AIzaSyCBH92YX2oCbI7PChqFNrgzw-PolHAiKZc";
    await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.latitude
      },${this.state.longitude}&sensor=false&key=${key}`
    )
      .then((response) => response.json())
      .then((data) => {
        let newData = data.results[0].address_components;
        let place = newData.filter(
          (ac: { types: string | string[] }) => ~ac.types.indexOf("sublocality_level_1")
        )[0].long_name;
        let city = newData.filter(
          (ac: { types: string | string[] }) => ~ac.types.indexOf("locality")
        )[0].long_name;
        let country = newData.filter(
          (ac: { types: string | string[] }) => ~ac.types.indexOf("country")
        )[0].long_name;
        this.setState({ postlocation: place + ', ' + city + ", " + country })
      })
      .catch((error) => {
        this.setState({ loadLocation: false });
        alert(`error while location, ${error}`);
      });
    this.setState({ loadLocation: false });
  }

  handleLocationError(error: any) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        this.setState({ loadLocation: false });
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        this.setState({ loadLocation: false });
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        this.setState({ loadLocation: false });
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        this.setState({ loadLocation: false });
        alert("An unknown error occurred.");
        break;
    }
  }

  getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  beforeUpload(file: any) {
    console.log("filetype", file);
    const isJpgOrPng =
      file.type.slice(0, 5) == "image" ||
      file.type.slice(0, 5) == "video";

    let isLt2M = false;
    if (file.type.slice(0, 5) == "image") {
      isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error('Image must smaller than 5MB!');
      }
    } else if (file.type.slice(0, 5) == "video") {
      isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('Video must smaller than 10MB!');
      }
    }

    this.getBase64(file, (imageUrl: string) => {
      let ImageArrayList = [...this.state.createPostImages];
      let VideoArray = [...this.state.videoArrayList];
      let AllMedia = [...this.state.AllMedia]
      if (isLt2M && (file.type.slice(0, 5) == "video" || file.type.slice(0, 5) == "image")) {

        AllMedia.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      }
      if (file.type.slice(0, 5) == "video" && isLt2M) {
        VideoArray.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      } else if (file.type.slice(0, 5) == "image" && isLt2M) {
        ImageArrayList.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      } else {
        message.error("invalid file type!!!");
      }

      this.setState({
        createPostImages: ImageArrayList,
        videoArrayList: VideoArray,
        AllMedia,
        loading: false,
      });
      console.log("arrays" + JSON.stringify(this.state.createPostImages));
    });

    return true;
  }

  beforeUploadAttachment(file: any) {
    let isLt2M = false;

    const isExcelFile = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";

    if (isExcelFile) {
      message.error("invalid file type!!!");
      return false;
    }
    if (file.type.slice(0, 11) == "application") {
      isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('Pdf/Doc must smaller than 10MB!');
      }
    }

    this.getBase64(file, (imageUrl: string) => {
      let ImageArrayList = [...this.state.createPostImages];
      let AllMedia = [...this.state.AllMedia]
      if (isLt2M && (file.type.slice(0, 11) == "application")) {
        AllMedia.push({
          filePath: imageUrl,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
        ImageArrayList.push({
          filePath: imageUrl,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      }
      else {
        message.error("invalid file type!!!");
      }

      this.setState({
        createPostImages: ImageArrayList,
        AllMedia,
        loading: false,
      });
    });

    return true;
  }
  handleImageChange = (info: any) => {
    if (info.file.status === "done") {

    } else if (info.file.status === "error") {
      console.log("FILE INFO", info, info.file);
    }
  };
  onRemoveImage = (index: number) => {
    let data = this.state.AllMedia;
    data.splice(index, 1);
    this.setState({ AllMedia: data });
  };

  deleteItem = (index: any) => {
    let deleteExistingMedias: any = cloneDeep(this.state.deleteExistingMedias);
    this.state.AllMedia.forEach((item: any, indexHere: any) => {
      if (index === indexHere && item.blob_id) {
        deleteExistingMedias.push(item);
      }
    });

    console.log("Deleting: ", index);
    let clonedArr = [...this.state.AllMedia].filter(
      (el: any, i: number) => index !== i
    );
    this.setState({ AllMedia: clonedArr, deleteExistingMedias });
  };


  infoComingSoon = () => {
    message.info('Coming soon');
  };

  //community forum
  communityForum = () => {
    this.props.history.push({ pathname: '/community/all', state: { communityData: this.state.joinnedCommunities, heading: 'Community Forum' } })
  }
  //work anniversies

  workAnniversaries = () => {
    this.props.history.push({
      pathname: "/upcoming/anniversaries",
      state: {
        key: '1',
        aniversaryData: this.state.AniversaryFullData
      },
    });
  }

  //Birth anniversies

  birthAnniversaries = () => {
    this.props.history.push({
      pathname: "/upcoming/anniversaries",
      state: {
        key: '2',
        aniversaryData: this.state.AniversaryFullData,
      },
    });
  }

  // invitations

  onViewAllInvitations = () => {
    // this.infoComingSoon();
    this.props.history.push('/myNetwork/invitation')
    // this.props.history.push({
    //   pathname: "/AllView/Invitation",
    //   // state: newData,
    // });
  };

  //people invite

  onViewAllInvite = async () => {
    // this.infoComingSoon();
    await setStorageData("type", "personal");
    this.props.history.push('/myNetwork/you_may_know')
    // this.props.history.push({
    //   pathname: "/AllView/InvitePeople",
    //   // state: newData,
    // });
  };

  onViewAllInviteFromBusiness = async () => {
    await setStorageData("type", "business");
    this.props.history.push('/myNetwork/you_may_know')
  };

  // *** api calls ******

  getHomeFeedPosts = async () => {
    console.log("call--");
    
    // this.setState({ isloader: true })
    this.setState({
      showLoadMoreLoading: true
    })
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHomeFeedPostsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}?page=${this.state.postPagination}&per_page=5`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getallHomeFeedPosts = async () => {
    // this.setState({ isloader: true });
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.APPLICATIONJSONContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getallHomeFeedPostsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GETMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSubmit = async () => {
    var ii: any = this.state.getMentionsArray;
    var jj: any = this.state.selectTags;
    var tags = [];
    for (var i = 0; i < ii.length; i++) {
      for (var j = 0; j < jj.length; j++) {
        let a = ii[i].value;
        let b = jj[j].value;
        let c = b.substring(0, b?.split(" ")[0].length)
        if (a == c) {
          tags.push(jj[j].key)
        }
      }
    }
    let t = this.state.tagged_connection_ids;
    t.push(...tags);
    this.setState({ tagged_connection_ids: t });
    this.setState({
      isloader: true,
    })
    const header = {
      token: this.state.token,
    };

    let data = {
      status: "pending",
    };
    let trimmedStr = this.state.editortext.trim();

    var form = new FormData();

    await form.append("body", trimmedStr.toString());

    if (this.state.address && this.state.address !== "") {
      await form.append("location", this.state.address);
    }

    if (this.state.tagged_connection_ids) {
      const uniqueTaggedIds = Array.from(
        new Set(this.state.tagged_connection_ids)
      );
      uniqueTaggedIds.forEach((taggedId: any) => {
        form.append("taggings_attributes[]account_id", taggedId);
      });
    }


    if (this.state.AllMedia.length > 0) {
      this.state.AllMedia.forEach((item: any) => {
        if (item.hasOwnProperty("blob_id")) delete item.blob_id;
        if (item.data) form.append("media[]", item.data);
        if (item.content_type && item.uri) form.append("media[]", item);
      });
    }

    if (this.state.deleteExistingMedias.length > 0) {
      this.state.deleteExistingMedias.forEach((item: any) => {
        if (item.blob_id) form.append("blob_ids[]", item.blob_id);
      });
    }

    var options = { content: form };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      createPostDescription: EditorState.createEmpty(),
      isCreatePostVisible: false
    })
    return true;
  };
  // edit post function
  handleEditSubmit = async (id: any) => {
    var ii: any = this.state.getMentionsArray;
    var jj: any = this.state.selectTags;
    var tags = [];
    for (var i = 0; i < ii.length; i++) {
      for (var j = 0; j < jj.length; j++) {
        let a = ii[i].value;
        let b = jj[j].value;
        let c = b.substring(0, b?.split(" ")[0].length)
        if (a == c) {
          tags.push(jj[j].key)
        }
      }
    }
    let t = this.state.tagged_connection_ids;
    t.push(...tags);
    this.setState({ tagged_connection_ids: t });
    console.log("value", id)
    this.setState({ isloadEditPost: true })
    const header = {
      // "Content-Type": configJSON.createPostApiContentType,
      // "Content-Type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
      token: this.state.token,
    };

    let data = {
      status: "pending",
    };
    console.log("header: ", header);
    console.log("description: ", this.state.createPostDescription);
    let trimmedStr = this.state.editortext.trim();

    var form = new FormData();
    console.log("trim", typeof trimmedStr);

    await form.append("body", trimmedStr.toString());
    console.log("FormData2-->", form);

    // formdata.append("name", 'ABC');
    console.log("FormData3-->", form.get("body"));
    if (this.state.address && this.state.address !== "") {
      await form.append("location", this.state.address);
    }
    if (this.state.tagged_connection_ids.length > 0) {
      const uniqueTaggedIds = Array.from(
        new Set(this.state.tagged_connection_ids)
      );
      uniqueTaggedIds.forEach((taggedId: any) => {
        form.append("taggings_attributes[]account_id", taggedId);
      });
      console.log(uniqueTaggedIds, "uniqueTaggedIds")
    }

    if (this.state.AllMedia.length > 0) {
      this.state.AllMedia.forEach((item: any) => {
        if (item.hasOwnProperty("blob_id")) delete item.blob_id;
        if (item.data) form.append("media[]", item.data);
        if (item.content_type && item.uri) form.append("media[]", item);
      });
    }

    if (this.state.deleteExistingMedias.length > 0) {
      this.state.deleteExistingMedias.forEach((item: any) => {
        if (item.blob_id) form.append("blob_ids[]", item.blob_id);
      });
    }

    // const httpBody = {
    //   data: data,
    // };
    var options = { content: form };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editPostID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postendpoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form
      // {"_parts": [["body", "Parth"]]}
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUTMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("Getconnection--->" + JSON.stringify(requestMessage));
    // return true;
  };

  likePost = async (id: number) => {
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/like`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  dislikePost = async (id: number) => {
    const token = await this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/dislike`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  toggleLike = (id: number, liked: boolean) => {
    if (liked) {
      this.setState({
        // postLike: false
        likedItems: this.state.likedItems.filter(function (el) {
          return el !== id;
        }),
      });
      this.dislikePost(id);
    } else {
      // console.log("like");
      // this.setState({postLike:true})
      this.setState((prevState) => ({
        likedItems: [...prevState.likedItems, id],
      }));
      this.likePost(id);
    }
  };

  toggleCommentLike = (id: number, liked: boolean, itemId?: any) => {
    if (liked) {
      this.dislikedComment(id, itemId);
    } else {
      this.likedComment(id, itemId);
    }
  };

  onClickFollow = async (id: number, isFollowed: boolean) => {
    if(isFollowed){
    Mixpanel.track("UnFollow")
    }
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_follow/follows?account_id=${id}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      if (isFollowed) {
        this.setState({
          // postLike: false
          followedItems: this.state.followedItems.filter(function (el) {
            return el !== id;
          }),
        });
      } else {
        this.setState((prevState) => ({
          followedItems: [...prevState.followedItems, id],
        }));
        this.getPeopleYouknow()
        this.getBusinessYouknow();
        // this.getAccountDetails(this.state.accountDetails?.attributes?.account?.data?.id)
      }
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };

  getFollowers = async () => {
    let id = this.state.userProfile?.account_id
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFollowersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFollowersApiCallIdEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addComment = (id: number, comment: any) => {
    this.setState({
      isCommentloader: true
    });
    // this.setState({ loadComments: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    let body = {
      data: {
        attributes: {
          post_id: id,
          comment: comment,
          // "taggings_attributes": [
          //     {
          //         "account_id": 2
          //     }
          // ]
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addCommentInPost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addReplyComment = (id: number, comment: any) => {
    console.log(id, "vvvv", comment);
    // this.setState({ loadComments: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    let body = {
      data: {
        attributes: {
          comment_id: id,
          comment: comment,
          // "taggings_attributes": [
          //     {
          //         "account_id": 2
          //     }
          // ]
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addReplyCommentInPost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getComments = async (id: number) => {

    // this.setState({ loadComments: true });
    const token = this.state.token;
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommentInPostApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}?post_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  likedComment = async (id: number, itemId?: any) => {
    this.setState({ isloader: true });
    const token = await this.state.token;
    const header = {
      // "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}/${id}/like`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isloader: false });
    this.getComments(itemId);
    return true;
  };
  dislikedComment = (id: number, itemId?: any) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      // "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}/${id}/dislike`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isloader: false });
    this.getComments(itemId);
    return true;
  };

  getKnownPeople = () => {
    const header = {
      token: this.state.token,
    };

    // console.log("getKnownPeople");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getKnownPeopleApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getKnownPeopleEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNetworkApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log(
    //   "Getconnection Invitations--->" + JSON.stringify(requestMessage)
    // );
  };

  // get all communities
  getAllCommunity = async () => {
    this.setState({ loadcommunity: true });
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_groups/community_forums/?admin=false&joined=true`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      if (response?.data?.communities) {
        response.data.communities?.data?.map((c: any) => {
          if (c?.attributes?.joined && c?.attributes?.admin === false) {
            this.setState((prevState) => ({
              joinnedCommunities: [...prevState.joinnedCommunities, c],
              loadcommunity: false,
            }));
          }
        });
        // this.setState({ allCommunity: response.data.communities });
      }
    } catch (error) {
      console.error(error);
      this.setState({ loadcommunity: false });
    }
    this.setState({ loadcommunity: false });
  }

  // Upcoming Work anniversaries
  getUpcomingWorkAnniversaries = async () => {
    this.setState({ loadUpcoming: true });
    try {
      const response = await axios({
        url: `${baseURL}/account_block/accounts/anniversaries`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      //   console.log(response.data, 'all getUpcomingWorkAnniversaries');
      if (response.data) {
        // birthAnniversaries
        let birthDates: any = [];
        let workDates: any = [];
        response.data.birth?.today?.data?.map((c: any) => birthDates.push(c));
        response.data.birth?.this_month?.data?.map((c: any) => birthDates.push(c));
        response.data.birth?.next_month?.data?.map((c: any) => birthDates.push(c));
        response.data.work?.today?.data?.map((c: any) => workDates.push(c));
        response.data.work?.this_month?.data?.map((c: any) => workDates.push(c));
        response.data.work?.next_month?.data?.map((c: any) => workDates.push(c));
        return this.setState({
          birthAnniversaries: birthDates,
          workAnniversaries: workDates,
          loadUpcoming: false,
          AniversaryFullData: response?.data
        })
      }
    } catch (error) {
      console.error(error);
      this.setState({ loadUpcoming: false });
    }
  }
  updateFollowStatus = (id: number, isFollowed: boolean) => {
    if (isFollowed) {
      Mixpanel.track("Follow")
      this.setState((prevState) => ({
        followedItems: [...prevState.followedItems, id],
      }));
    } else {
      Mixpanel.track("UnFollow")
      this.setState({
        followedItems: this.state.followedItems.filter(function (el) {
          return el !== id;
        }),
      });
    }
  }
  //People you know
  getPeopleYouknow = async () => {
    this.setState({ peoplemayKnow: [], loadpeopleKnow: true });
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/suggestions?per_page=5`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      console.log(response?.data)
      // const response = await axios.get('');
      this.setState({ peoplemayKnow: response?.data?.accounts?.data, loadpeopleKnow: false })
      // console.log(response.data.accounts.data, 'all getPeopleYouknow');
    } catch (error) {
      console.error(error);
      this.setState({ loadpeopleKnow: false });
    }
  }

  getBusinessYouknow = async () => {
    this.setState({ businessMayKnow: [], loadBusinessKnow: true });
    try {
      const response = await axios({
        url: `${baseURL}/account_block/accounts/business_suggestions?per_page=5`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      this.setState({ businessMayKnow: response?.data?.accounts?.data, loadBusinessKnow: false })
    } catch (error) {
      this.setState({ loadBusinessKnow: false });
    }
  }

  // Invitations
  getInvitations = async () => {
    this.setState({ allInvitations: [], loadInvitation: true });
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests?status=pending&per_page=5`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        // data: JSON.stringify(httpBody)
      })
      // const response = await axios.get('');
      this.setState({ allInvitations: response.data, loadInvitation: false },
        () => console.log(this.state.allInvitations, 'all Invitations')
      );
      await this.getCategories();
    } catch (error) {
      console.error(error);
      this.setState({ loadInvitation: false });
    }
  }

  getCategories = async () => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_categories/categories`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        // data: JSON.stringify(httpBody)
      })
      if (response && response?.data) {
        let dropDownDetail = response && response.data;
        // console.log(dropDownDetail,' **')
        let checkBusinessConnc = dropDownDetail.data.filter(
          (item: any) => item.attributes.name === "Business Connection");

        let checkPersonalConnc = dropDownDetail.data.filter(
          (item: any) => item.attributes.name === "Personal Connection");

        this.setState(
          {
            businessConnection: checkBusinessConnc[0].attributes.sub_categories,
            personalConnection: checkPersonalConnc[0].attributes.sub_categories,
          },
          // () => {
          //   console.log(
          //     "@@@@@@checkConnections=======212222222222",
          //     this.state.businessConnection,
          //     this.state.personalConnection
          //   );
          // }
        );
      }
      // console.log(response.data,'** all categories *')
      // this.setState({allInvitations:response.data},()=> console.log(this.state.allInvitations,'all Invitations'))
    } catch (error) {
      console.error(error);
    }
  }

  // Invitations
  updateInvitations = async (status: any, senderId: any) => {
    let httpBody = {
      data: {
        status: status,
        sender_category_id: senderId
      }
    }
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests`,
        method: 'put',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      // console.log(response.data,'** res for updated invitations')
      // this.setState({allInvitations:response.data},()=> console.log(this.state.allInvitations,'all Invitations'))
    } catch (error) {
      console.error(error);
    }
  }

  // getAllCommunity = () => {
  //   const header = {
  //     "Content-Type": configJSON.getCommunitiesApiContentType,
  //     token: this.state.token,
  //   };
  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );
  //   this.getAllCommunityApiCallId = requestMessage.messageId;
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     configJSON.getCommunitiesApiEndpoint
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     header
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.getCommunitiesApiMethod
  //   );
  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  //   return true;
  // };

  //addConnection
  addConnection = async (value: any, status: any, req_id: any, cat_id: any) => {
    // console.log(value, status, req_id, cat_id, 'test 123');
    this.setState({ isShowConnectionModal: false });
    let httpBody = {
      data: {
        account_id: req_id,
        sender_relation: this.state.relationValue
      }
    }
    // console.log(value,'*', status,'*', id);
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests`,
        method: 'post',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      if (response?.data) {
        // window.location.reload();
        this.getPeopleYouknow();
        this.getBusinessYouknow();
        return this.setState({ isShowConnectionModal: false });
      }
      // console.log(response.data, 'report submitted');
    } catch (error) {
      console.error(error);
      this.setState({ isShowConnectionModal: false });
    }
    this.setState({ isShowConnectionModal: false });
  }

  onHandleFollow = (data: any) => {
    const header = {
      "Content-Type": configJSON.APPLICATIONJSONContentType,
      token: localStorage.getItem("token")
    };
    let body = {
      "account_id": data.account_id
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followUnFollowEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleInvitationModal = (data: any, role: any) => {
    this.getRelations("accept", data?.account_id);
    this.setState({ isShowInvitationModal: true, invitationUserInfo: data, loadInvitationData: [] });
  }

  getRelations = async (type: any, account_id: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/relations?account_id=${account_id}&action_type=${type}`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      if (response && response?.data) {
        const reltionsArray = response?.data.relations.sort().map((item: any) => ({ name: item, id: item }))
        console.log(reltionsArray)
        this.setState({ loadInvitationData: response?.data.relations.sort() });
      }
    } catch (error) {
      console.error(error);
    }
  }


  sendRequest = async (relation: any, account_id: any) => {
    try {
      if (!relation || !account_id) {
        this.setState({ addConnectionSelectError: "Please select relationship first" })
        return
      }
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests`,
        method: 'post',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: {
          data: {
            "sender_relation": relation,
            "account_id": account_id
          }
        }

      })
      if (response && response?.data) {
        this.setState({ isShowConnectionModal: false, addConnectionSelectError: "" })
        this.getPeopleYouknow();
        this.getBusinessYouknow();
      }
    } catch (error) {
      if ((error as AddConnectionErrorType).response.status) {
        // this.setState({ addConnectionSelectError: "" })
      }
      console.error(error);
    }
  }

  handleMenuClick = async (e: any, id: any) => {
    console.log("click =>", e, id);
    if (e === "save") {
      // setDropDownVisible(true);
      await this.savePost(id, "save");
      // console.log("save success")
      this.setState({
        postReportSave: 'Success : Post Save'
      })
    }
    if (e === "hide") {
      await this.savePost(id, "hide");
      this.setState({
        postReportSave: 'Success : Post Hide',
        postHide: "hide"
      })
    }
    if (e === "report") {
      // setDropDownVisible(true);
      this.setState({
        isReportIssueVisible: true, reportPostID: id,
        postReportSave: 'Post has been reported successfully'
      });
    }
  }

  handleOk = async (value: any) => {
    let httpBody = {
      data: {
        attributes: {
          context: value
        }
      }
    }
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_posts/posts/${this.state.reportPostID}/report`,
        method: 'post',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      // console.log(response.data, 'report submitted');
      message.success(this.state.postReportSave)
      this.setState({
        isReportIssueVisible: false,
        postReportSave: ''
      });
    } catch (error) {
      console.error(error);
      this.setState({ isReportIssueVisible: false });
    }
    this.setState({ isReportIssueVisible: false });
    // setDropDownVisible(false);
  };

  handleCancel = (e: any) => {
    // console.log(e);
    this.setState({ isReportIssueVisible: false, reportPostID: "" });
  };
  onChangeValue = (e: any) => {
    // console.log("radio checked", e.target.value);
    this.setState({ value: e.target.value });
  };
  savePost = async (id: number, endString: string) => {
    // this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.savePostApiCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/${endString}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // handleInvitationModal = (data: any, role: any) => {
  //   // console.log(role , '***');
  //   this.handleNetworkRelation(data?.account_id, "accept")
  //   if (role && role === 'business') {
  //     this.setState({ invitationUserInfo: data, });
  //   } else {
  //     this.setState({ invitationUserInfo: data, });
  //   }
  // }

  closeInvitationModal = () => {
    // console.log(status, catId);
    this.setState({ isShowInvitationModal: false });
  }

  handleChange = (event: any) => {
    // console.log("@@@ e======hello", event);
    this.setState({ relationValue: event, addConnectionSelectError: "" });
  }

  handleInvitations = async (value: any, status: any, req_id: any, cat_id: any) => {
    if (!this.state.relationValue || !status) {
      this.setState({ addConnectionSelectError: "Please select relationship first" })
      return
    }

    let httpBody = {
      data: {
        status: status,
        sender_relation: this.state.relationValue
      }
    }
    // console.log(value,'*', status,'*', id);
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/${req_id}`,
        method: 'put',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      if (response?.data) {
        if (status === "accepted") {
          Mixpanel.track("Connection Accepted")
        } else
          Mixpanel.track("Connection Rejected")
        this.setState({
          responseHandler: {
            status: status,
            full_name: response?.data?.data?.attributes?.role == "business" ? response?.data?.data?.attributes?.profile?.data?.attributes?.company_name : response?.data?.data?.attributes?.full_name,
            sender_relation: this.state.relationValue
          }
        })

        this.getInvitations();
        this.getPeopleYouknow();
        this.getBusinessYouknow();
        // window.location.reload();
        return this.setState({ isShowInvitationModal: false, isModel: true });
      }
      // console.log(this.state.allInvitations,'*')
      // this.setState({
      //   // postLike: false
      //   allInvitations: this.state.allInvitations.data.filter(function(el:any) {
      //     return el.id === req_id;
      //   },()=> console.log(this.state.allInvitations,'***')),
      // });
      // console.log(response.data, 'report submitted');
    } catch (error) {
      console.error(error);
      this.setState({ isShowInvitationModal: false, isModel: true });
    }
    this.setState({ isShowInvitationModal: false, isModel: true });
  }

  // displayUserRelation = (value: any) => {
  //   let t1 = value.split(" ");
  //   return t1[t1.length - 1];
  // }

  getNetworkConnectionApplied = async () => {
    // console.log(
    //   "Getting new friends list, token = ",
    //   await getStorageData("token")
    // );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
      // token: 'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzUxLCJleHAiOjE2MjIyODE3OTV9.VeU0sy4U9YiEJXyATrMzJWwFPlgaSm9KqwQVwoX7ZmLJ8SXW7oQQY3NgQ5oolFrKyhron8Bha9RZ0XCP7QNPnQ',
    };
    //   let formdata: any = new FormData();
    //   formdata.append("[token]", 'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NTI4LCJleHAiOjE2MjIxMjU0MDd9.GxLPNGdjH_WraIR5jIR4eURIPVsXogq2VKcYebGSvTfC407qLv2PKtDW6c9IuFXneT0lKsV_POuSQJepNErovA');
    let data = {
      status: "pending",
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNetworkConnectionAppliedId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNetworkEndPoint + "?status=accepted"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    //   requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),httpBody);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNetworkApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("Getconnection--->" + requestMessage);
    return true;
  };

  redirectCommunity = (id: string) => {
    this.updateUnseenCount(id);
    return this.props.history.push({
      pathname: '/community/block',
      state: { communityId: id }
    })
  }

  // unseen post count
  updateUnseenCount = async (id: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_groups/community_forums/${id}`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
      })
      console.log(response, 'updateUnseenCount');
    } catch (error) {
      console.error(error, 'error in updateUnseenCount');
    }
  }
  onItemPress = (id: string) => {
    console.log(id);
    // if (this.state.birthAnniversaries?.account?.data?.attributes?.account_id === this.state.userProfile?.account_id) {
    //   return null
    // } else {
    //   if (this.state.birthAnniversaries?.account?.data?.attributes?.role === "personal") {
    //     return this.props.history.push({ pathname: "/other/personal/profile", state: this.state.birthAnniversaries?.account?.data?.attributes?.account_id });
    //   } else if (this.state.birthAnniversaries?.account?.data?.attributes?.role === "business") {
    //     return this.props.history.push({ pathname: "/other/business/profile", state: this.state.birthAnniversaries?.account?.data?.attributes?.account_id });
    //   }
    // }
  }

  // get connections
  getConnections = async () => {
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getConnectionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getConnectionApiCallIdEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // delete post 
  deletePost = async (id: any) => {
    this.setState({
      deleteId: id
    })
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deletePostEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETEMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAccountDetails = async (id: any) => {
    // console.log("account", id);
    this.setState({ loadAccountData: true })
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAccountDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAccountDetailsEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAccountDetailsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("getAccount--->" + JSON.stringify(requestMessage));
    return true;
  };

  GetProfileInformation = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetProfielInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetProfileInfoEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SearchAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("GetProfileInfo--->" + JSON.stringify(requestMessage));
    return true;
  }

}

// Customizable Area End
