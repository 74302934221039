import React, { Suspense } from "react";
import MyPersonalProfileController, { Props } from "./MyPersonalProfileController.web";
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import {
  postsImgsIcon, connectionsIcon, followersIcon,infoIcon,
  followingIcon, ratingsReceivedIcon, ratingSentIcon, three_dot, createProfile, faceIcon, photoIcon, sendIcon, atIcon, locationIcon, whatsappIcon, facebookIcon, gmailIcon, linkdinIcon
} from "./../assets";

import "./../userFeed.css";
import "./myprofilestyling.css";
import moment from 'moment';
import {
  Card, Row, Col, List, Input, Typography, Avatar, Button, Upload, Image, Badge, Radio,
  Tabs, Space, Modal, Form, Empty, message, Dropdown, Progress, Mentions, Spin, Tooltip
} from "antd";
import {
  SearchOutlined,ExclamationCircleOutlined,CloseOutlined,FileTextOutlined,StarOutlined,EditOutlined,UserOutlined
} from '@ant-design/icons';
import { FacebookShareButton, WhatsappShareButton, EmailShareButton, LinkedinShareButton } from 'react-share'
import ReactPlayer from 'react-player'
import { Dimensions, PixelRatio } from "react-native";
import { Divider } from "react-native-elements/dist/divider/Divider";
import ProfileRatingViewsPercentage from "./ProfileRatingViewsPercentage.web";
import ProfileRatingAppliedProgressBar from "./profileRatingAplliedProgressBar.web";
import FollowersAndFollowing from "../../../user-profile-basic/src/CommonUI/FollowersAndFollowing.web";
const CustomPost = React.lazy(() => import('./../CustomPost.web'));
import Editor from "@draft-js-plugins/editor";
import '@draft-js-plugins/emoji/lib/plugin.css';
import createMentionPlugin, {
  defaultSuggestionsFilter
} from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import createEmojiPlugin from '@draft-js-plugins/emoji';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { isEmpty } from "../../../../framework/src/Utilities";
import { convertToRaw } from "draft-js";
import {
  Box
} from "@material-ui/core";
const { Title, Text } = Typography;
const { Option, getMentions } = Mentions;
const { TabPane } = Tabs;
const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};
let screenWidth = Dimensions.get('window').width;
let screenHeight = Dimensions.get('window').height;

//emoji
const emojiPlugin = createEmojiPlugin(
  {
    selectButtonContent: <img src={faceIcon} width={20} />
  }
);
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
//Artboard Dimension 
let artBoardHeightOrg = 1024;
let artBoardWidthOrg = 1440;
//Re calculated Artboard Dimension 
let artBoardWidth = isSameRatio() ? artBoardWidthOrg : screenWidth;
let artBoardHeight = isSameRatio() ? artBoardHeightOrg : screenHeight;
// To check if Artboard and Device screen has same ratio 
function isSameRatio(): boolean {
  return artBoardWidthOrg / artBoardHeightOrg < 1 && screenWidth / screenHeight < 1
};

//Top or Bottom nav spaces or any extra space occupied by os e.g Status bar, Notch 
let extraSpace = 0;

function deviceBasedDynamicDimension(originalDimen: number, compareWithWidth: boolean, resizeFactor: number): number | undefined {
  if (originalDimen != null) {
    if (resizeFactor != null) {
      originalDimen *= resizeFactor;
    }
    const compareArtBoardDimenValue = compareWithWidth ? artBoardWidth : artBoardHeight;
    const artBoardScreenDimenRatio = (originalDimen * 100) / compareArtBoardDimenValue;
    let compareCurrentScreenDimenValue = compareWithWidth ? screenWidth : screenHeight - extraSpace;
    return PixelRatio.roundToNearestPixel((artBoardScreenDimenRatio * compareCurrentScreenDimenValue) / 100,);
  }
  return;
};
export interface PlacesAutocompleteProps {
  getInputProps?: any;
  suggestions?: any;
  getSuggestionItemProps?: any;
  loading: any;
}
export default class MyPersonalProfile extends MyPersonalProfileController {
  mentionPlugin: any;
  editor: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    const { } = this.state;
    this.mentionPlugin = createMentionPlugin();
    // Customizable Area End
  }


  handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    this.setState({ address: value });
    // setCoordinates(latLng);
  };

  // Customizable Area Start
  renderPledgeTracking = () => {
    return (
      <Card bordered={false}>
        <p data-testid="textlabel_headingText" className="textlabel_headingText" >Profile Update Status</p>
        <div>
          <p><Text style={{ fontSize: 14, marginBottom: 12, color: 'rgb(196, 196, 196)', textTransform: 'capitalize' }}>profile set Up <span style={{ fontSize: 18, color: "#4080c0" }}>{this.state.pledgeTracking.completion_percent}%</span> done</Text></p>
        </div>
        <Row>
          <Col span={18} xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
            <div style={{ width: '90%' }}>
              <Progress
                strokeColor="linear-gradient(#00536c, #007491)"
                percent={this.state.pledgeTracking.completion_percent}
                showInfo={false} />
            </div>
          </Col>
          <Col span={6} xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
            <div style={{
              color: "#4080c0",
              cursor: 'pointer'
            }}
              onClick={() => this.props.history.push({ pathname: '/edit/profile/personal', state: this.state.userProfile })}
            >Complete Your Profile</div>
          </Col>
        </Row>
      </Card>
    )
  }
  //locations
  getUserLocattion = () => {
    return (
      <div style={{ position: 'relative' }}>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={(address) => this.setState({ address })}
          onSelect={this.handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }: PlacesAutocompleteProps) => (
            <div>
              <div className="autocomplete-dropdown-container">
                {/* {loading ? <div>...loading</div> : null} */}
                {<div style={{
                  // position: "fixed",
                  zIndex: 999999999,
                  boxShadow: "0px 2px 7px 0px #888888",
                  maxHeight: '120px',
                  overflowY: "auto"
                }}>
                  {suggestions.map((suggestion: { terms: any; formattedSuggestion: any; active: any; description: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                      padding: '14px',
                      borderBottom: '1px solid rgb(0, 0, 0, 0.05)'
                    };

                    return (
                      <div className="input-suggestion"
                        {...getSuggestionItemProps(suggestion, { style })}>
                        <img src={locationIcon} width={14} />
                        <span style={{ paddingLeft: "1rem" }}>
                          {
                            suggestion.terms.length == 1 ? suggestion.terms[0].value :
                              suggestion.terms.length == 2 ? suggestion.terms[0].value + ", " + suggestion.terms[1].value :
                                suggestion.terms.length >= 3 && suggestion.terms[0].value + ", " + suggestion.terms[suggestion.terms.length - 2].value + ", " + suggestion.terms[suggestion.terms.length - 1].value
                          }
                        </span>
                      </div>
                    );
                  })}
                </div>}
              </div>
              <div className="input-location">
                <img src={locationIcon} width={14} />
                <input {...getInputProps({
                  // placeholder: "Type address",
                  className: 'location-search-input',
                })} />

              </div>

            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }

  renderUSerProfile = () => {
    var startDate = moment("2018-10-10", "YYYY.MM.DD"), // $('[name="date-start"]').val() === "13.04.2016"
      endDate = moment("2020-11-12", "YYYY.MM.DD"); // $('[name="date-end"]').val() === "28.04.2016"

    var diff = startDate.diff(endDate);
    const { userProfile } = this.state
    return (
      <>
        <Card bordered={false}>
          <Row className="myProfie_RenderUser" style={{ alignItems: 'center', padding: '6px 0px' }}>
            <Col>
              <Avatar src={userProfile?.photo} size={80} style={{ backgroundColor: "grey" }} />
            </Col>
            <Col className="myProfie_RenderUser_userDetails">
              <Text style={{ fontWeight: 600, fontSize: "16px", color: "rgba(0, 0, 0, 0.85)", textTransform: "capitalize" }}>
                {userProfile?.full_name}
              </Text><br />
              <span style={{ fontWeight: 500, fontSize: "14px", color: "rgba(0, 0, 0, 0.85)" }}>
                {
                  userProfile?.designation?.title ?
                    <>
                      {userProfile?.designation?.title}
                      {
                        userProfile?.designation?.company ?
                          <span style={{ color: "#919191" }}> at </span> : null
                      }
                      {userProfile?.designation?.company}
                    </>
                    : null
                }
              </span>
              <br />
              <span className="domain_skill">
                {
                  userProfile?.domain_skills ?
                    <>
                      {
                        userProfile?.domain_skills?.map((item: any, id: number) => {
                          if (id + 1 < userProfile?.domain_skills.length) {
                            return `${item}, `
                          } else {
                            return `${item}`
                          }
                        })
                      }
                    </>
                    : null
                }
              </span><br />

              <span >
                {userProfile?.experience_years > 0 &&
                  <Text className="domain_skill" >{userProfile?.experience_years}+ years of experience</Text>
                }
              </span><br />
            </Col>
          </Row>
        </Card></>
    );
  };
  //editpostrender
  rendereditPost = () => {
    const { userProfile } = this.state;
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin, emojiPlugin];
    const mentions = this.state.followers?.map(function (followersItem: any) {
      return ({
        name: ` @${followersItem?.attributes?.full_name} `,
        avatar: followersItem?.attributes?.photo,
        userId: followersItem?.id
      })
    })

    const onSearchChange = ({ value }: { value: any }) => {

      this.setState({
        suggestions: defaultSuggestionsFilter(value, mentions)
      });
    };
    const focus = () => {
      this.editor.focus();
    };
    return (
      <div style={{ borderWidth: "0px", borderRadius: 4 }}>
        <div className='editor' onClick={focus}>
          <Editor
            editorState={this.state.createPostDescription}
            //@ts-ignore
            handleBeforeInput={this.handleBeforeInput}
            //@ts-ignore
            handlePastedText={this.handlePastedText}
            onChange={this.onChangeText}
            placeholder="Text here..."
            plugins={plugins}
            ref={(element) => {
              this.editor = element;
            }}
          />
          <MentionSuggestions
            onSearchChange={onSearchChange}
            suggestions={this.state.suggestions}
            onOpenChange={this.onOpenChange}
            open={this.state.open}
          />
        </div>

        <Card style={{ borderWidth: "0px" }}>
          <Row style={{ padding: "0px" }}>

            {!this.state.location && <div style={{ marginLeft: 5, marginTop: 10, display: 'flex', alignItems: 'center' }}>
              {this.state.address && <img src={locationIcon} width={14} />}
              <Text style={{ color: "#757575", marginLeft: 18, fontSize: 14, fontWeight: 600 }}>  {this.state.address && this.state.address}</Text>
            </div>}
            {this.state.getMentionsArray.length !== 0 &&
              <div style={{ marginLeft: 5, marginTop: 10, display: 'flex', alignItems: 'center' }}>
                {
                  this.state.getMentionsArrayList.map((item: any, index: any) => <Text key={index} style={{ color: "#757575", marginLeft: 18, fontSize: 14, fontWeight: 600 }}>@{item}{this.state.getMentionsArray.length - 1 > index ? ", " : null}</Text>)
                }
              </div>}
            <EmojiSuggestions />
            {this.state.AllMedia.length ?
              <Row style={{ paddingTop: "10px", width: '100%' }}>
                {this.renderMedia(this.state.AllMedia)}
              </Row> : null}
            {
              this.state.bodyText && <Row style={{ padding: "10px", }}>
                <Text style={{ color: "red" }}>Body can't be blank</Text>
              </Row>
            }

            {(this.state.editortext).length > 1000 && this.state.data?.account_plan == "free" ? (
              <Row style={{ padding: "10px", }}>
                <Text style={{ color: "red" }}>
                  You have used your 1000 characters and one media per post. Please
                  upgrade to premium account to avail benefit to post without
                  any word or media limit.
                  <Text onClick={() => { this.props.history.push({ pathname: '/Premium/Account' }) }}
                    style={{ color: "#005574", marginLeft: "10px", cursor: "pointer" }}>
                    {"Upgrade to Premium Account."}
                  </Text>
                </Text>
              </Row>
            ) : null}
          </Row>
        </Card>
        <Card
          style={{
            // position: "absolute",
            width: "100%",
            top: "26px"
          }}
        // className="MyProfile_CreatePost"
        >
          {this.state.isloader ? <Spin /> :
            <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
              
                  {this.state.tagsOpen &&
                    <Col
                      xs={24}
                      xl={12}
                      md={12}
                      sm={12}
                    >
                        <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
                          <img src={atIcon} alt="" width={20} height={20} />
                          <Mentions
                            onChange={(value) => this.onChangeTags(value)}
                            onSelect={(value) => this.onSelectTags(value)}
                            placement="top"
                            style={{ border: 'none' }}
                          >
                            {this.state.followers && this.state.followers.map((followersItem: any) => (
                              <Option
                                value={followersItem?.attributes?.role === "business" ?
                                  followersItem?.attributes?.profile?.data?.attributes?.company_name :
                                  followersItem?.attributes?.full_name
                                }
                                key={followersItem?.attributes?.account_id}
                                style={{
                                  borderBottom: '1px solid #BDBDBD',
                                  marginBottom: 5,
                                  width: '100%',
                                  minWidth: '260px',
                                  maxHeight: 280,
                                  height: '100%',
                                  padding: "4px"
                                }}
                              >
                                <div style={{ padding: 8 }}>
                                  <span>
                                    <Avatar
                                      src={followersItem?.attributes?.photo}
                                    />
                                  </span>
                                  <span style={{ marginLeft: '15px' }}>
                                    {followersItem?.attributes?.role === "business" ?
                                      followersItem?.attributes?.profile?.data?.attributes?.company_name :
                                      followersItem?.attributes?.full_name
                                    }
                                  </span>
                                </div>
                              </Option>
                            ))
                            }
                          </Mentions>
                        </div>
                      
                    </Col>}
                    {this.state.location &&
                   <Col
                      xs={24}
                      xl={12}
                      md={12}
                      sm={12}
                    >
                      {this.state.location && this.getUserLocattion()}
                    </Col>}
              
               <Col
                    xs={24}
                    xl={12}
                    md={12}
                    sm={12}
                    style={{ padding: "10px" }}
                  >
                    {this.state.data?.account_plan == "free" && <Text
                      style={{
                        fontSize: "14px",
                        marginRight: "5px",
                        color: "grey",
                      }}
                    >{(this.state.editortext).length > 1000 ? "0 characters left" : `${1001 -
                      (this.state.editortext).length} characters left`}</Text>
                    }
              </Col>
              <Col
                xs={24}
                sm={12}
                md={12}
                xl={12}
                style={{
                  display: "flex", justifyContent: "flex-end",
                  padding: "10px", width: '100%'
                }}
              >
                <Upload
              name='file'
              showUploadList={false}
              multiple={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={(file) => this.beforeUploadAttachment(file)}
              onChange={this.handleImageChange}
            >
              <Button
                className="post_btn"
                type="ghost"
                shape="circle"
                icon={<AttachmentOutlinedIcon color="disabled" style={{ transform: "rotate(-45deg)" }} width={20} />}
                disabled={
                  this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" && this.state.AllMedia.length === 1
                }                
              />
            </Upload>
                <EmojiSelect />
                <Upload
                  name='file'
                  showUploadList={false}
                  multiple={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={(file) => this.beforeUpload(file)}
                  onChange={this.handleImageChange}
                >
                  <Button
                    className="post_btn"
                    type="ghost"
                    shape="circle"
                    icon={<img src={photoIcon} width={20} />}
                    disabled={
                      this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" ?
                        this.state.AllMedia.length == 1 ? true : false : false
                    }
                  />
                </Upload>
                <Button className="post_btn"
                  type="ghost"
                  shape="circle"
                  onClick={() => this.setState({
                    tagsOpen: !this.state.tagsOpen,
                    location: false
                  })}
                  icon={<img src={atIcon} width={20} />}
                />
                <Button
                  className="post_btn"
                  type="ghost"
                  shape="circle"
                  icon={<img src={locationIcon} width={14} />}
                  onClick={() => {
                    this.setState({
                      tagsOpen: false,
                      location: !this.state.location
                    })
                  }}
                />
                <Button
                  className="post_btn"
                  type="ghost"
                  shape="circle"
                  disabled={this.state.data?.account_plan === "free" && this.state.editortext.length > 1001}
                  icon={<img src={sendIcon} width={"100%"} />}
                  onClick={() => this.handleEditSubmit(this.state.editData?.id)}
                />
              </Col>
            </Row>}
        </Card>
      </div>
    );
  };

  getLengthOfSelectedText = () => {
    const currentSelection = this.state.createPostDescription.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.createPostDescription.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);

      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        };
      }
    }

    return length;
  }

  handleBeforeInput = () => {
    if (this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free") {
      const currentContent = this.state.createPostDescription.getCurrentContent();
      const currentContentLength = currentContent.getPlainText('').length;
      const selectedTextLength = this.getLengthOfSelectedText();

      if (currentContentLength - selectedTextLength >= 1001 - 1) {
        // this.infoComingSoon()

        return 'handled';
      }
    }
  }

  handlePastedText = (pastedText: string | any[]) => {
    if (this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free") {
      const currentContent = this.state.createPostDescription.getCurrentContent();
      const currentContentLength = currentContent.getPlainText('').length;
      const selectedTextLength = this.getLengthOfSelectedText();

      if (currentContentLength + pastedText.length - selectedTextLength > 1000) {

        // this.infoComingSoon()

        return 'handled';
      }
    }
  }

  renderCreatePost = () => {
    const { userProfile } = this.state;
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin, emojiPlugin];
    const mentions = this.state.followers?.map(function (followersItem: any) {
      return ({
        name: ` @${followersItem?.attributes?.full_name} `,
        avatar: followersItem?.attributes?.photo,
        userId: followersItem?.attributes?.account_id
      })
    })
    const onSearchChange = ({ value }: { value: any }) => {

      this.setState({
        suggestions: defaultSuggestionsFilter(value, mentions)
      });
    };
    const focus = () => {
      this.editor.focus();
    };
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "10px"
      }}>
        <div className='editor' onClick={focus}>
          <Editor
            editorState={this.state.createPostDescription}
            //@ts-ignore
            handleBeforeInput={this.handleBeforeInput}
            //@ts-ignore
            handlePastedText={this.handlePastedText}
            onChange={this.onChangeText}
            placeholder="Text here..."
            plugins={plugins}
            ref={(element) => {
              this.editor = element;
            }}
          />
          <MentionSuggestions
            onSearchChange={onSearchChange}
            suggestions={this.state.suggestions}
            onOpenChange={this.onOpenChange}
            open={this.state.open}
          />
        </div>
        {
          this.state.address ?
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <img src={locationIcon} width={10} />
              <Text
                style={{
                  color: "#757575",
                  fontSize: 14,
                  fontWeight: 600,
                  marginLeft: "5px"
                }}
              >
                {this.state.address}
              </Text>
            </div> : null
        }
        {this.state.getMentionsArray.length !== 0 &&
          <div>
            {
              this.state.getMentionsArrayList.map((item: any, index: any) => <Text
                key={index}
                style={{
                  color: "#757575",
                  fontSize: 14,
                  fontWeight: 600
                }}
              >
                @{item}{this.state.getMentionsArray.length - 1 > index ? ", " : null}
              </Text>)
            }
          </div>}
        <EmojiSuggestions />
        {this.state.AllMedia.length ?
          this.renderMedia(this.state.AllMedia)
          : null}
        {
          this.state.bodyText ?
            <Text style={{ color: "red" }}>Body can't be blank</Text>
            : null
        }

        {(this.state.editortext).length > 1000 && this.state.data?.account_plan == "free" ? (
          <Text style={{ color: "red" }}>
            You have used your 1000 characters and one media per post. Please
            upgrade to premium account to avail benefit to post without
            any word or media limit.
            <Text onClick={() => { this.props.history.push({ pathname: '/Premium/Account' }) }}
              style={{ color: "#005574", marginLeft: "10px", cursor: "pointer" }}>
              {"Upgrade to Premium Account."}
            </Text>
          </Text>
        ) : null}
      </div>
    );
  };

  renderAboutContainer = () => {
    const { userProfile } = this.state;

    return (
      this.state.userProfile?.description
      &&
      <Card bordered={false}>
        <Divider />
        <Col style={{ marginLeft: 12, marginTop: 12 }} >
          <p><Text style={{ fontSize: 14, fontWeight: "bold" }}>About {userProfile?.full_name}</Text></p>
          <p><Text style={{ fontSize: 14, marginBottom: 12, color: 'rgb(196, 196, 196)' }}>{this.state.userProfile?.description && this.state.userProfile?.description}</Text></p>
        </Col>
      </Card>
    )
  };

  renderCreatePostPopup = () => {
    return (<Modal
      bodyStyle={{
        width: "100%",
        height: "100%",
        padding: "0px 23px 10px 23px",
      }}
      centered
      title={
        <Space
          style={{
            width: "100%",
            cursor: "move",
            fontWeight: "bold",
            color: "#414141"
          }}
        >
          Create New Post
        </Space>
      }
      visible={this.state.createPostOpen}
      onCancel={() => this.handlecreatePostOpenCancel()}
      footer={
        <Row
          style={{
            padding: "20px",
            borderTop: "1px solid #f0f0f0",
            textAlign: "left"
          }}
        >
          <Col
            span={12}
          >
               { this.state.tagsOpen &&
                  <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
                    <img src={atIcon} alt="" width={20} height={20} />
                    <Mentions
                      onChange={(value) => this.onChangeTags(value)}
                      onSelect={(value) => this.onSelectTags(value)}
                      placement="top"
                      style={{ border: 'none' }}
                    >
                      {this.state.followers && this.state.followers.map((followersItem: any) => (
                        <Option
                          value={followersItem?.attributes?.role === "business" ?
                            followersItem?.attributes?.profile?.data?.attributes?.company_name :
                            followersItem?.attributes?.full_name
                          }
                          key={followersItem?.attributes?.account_id}
                          style={{
                            borderBottom: '1px solid #BDBDBD',
                            marginBottom: 5,
                            width: '100%',
                            minWidth: '260px',
                            maxHeight: 280,
                            height: '100%',
                            padding: "4px"
                          }}
                        >
                          <div style={{ padding: 8 }}>
                            <span>
                              <Avatar
                                src={followersItem?.attributes?.photo}
                              />
                            </span>
                            <span style={{ marginLeft: '15px' }}>
                              {followersItem?.attributes?.role === "business" ?
                                followersItem?.attributes?.profile?.data?.attributes?.company_name :
                                followersItem?.attributes?.full_name
                              }
                            </span>
                          </div>
                        </Option>
                      ))
                      }
                    </Mentions>
                  </div>}
                  <div>
                    {this.state.location && this.getUserLocattion()}
                  </div>
                 
                  {this.state.data?.account_plan == "free" && <Text
                    style={{
                      fontSize: "14px",
                      marginRight: "5px",
                      color: "grey",
                    }}
                  >{(this.state.editortext).length > 1000 ? "0 characters left" : `${1001 -
                    (this.state.editortext).length} characters left`}</Text>
                  }
          </Col>
          
          <Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >  
            <Upload
              name='file'
              showUploadList={false}
              multiple={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={(file) => this.beforeUploadAttachment(file)}
              onChange={this.handleImageChange}
            >
              <Button
                className="post_btn"
                type="ghost"
                shape="circle"
                icon={<AttachmentOutlinedIcon color="disabled" style={{ transform: "rotate(-45deg)" }} width={20} />}
                disabled={
                  this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" && this.state.AllMedia.length === 1
                }                
              />
            </Upload>
            <EmojiSelect />
            <Upload
              name='file'
              showUploadList={false}
              multiple={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={(file) => this.beforeUpload(file)}
              onChange={this.handleImageChange}
            >
              <Button
                className="post_btn"
                type="ghost"
                shape="circle"
                icon={<img src={photoIcon} width={20} />}
                disabled={
                  this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "free" ?
                    this.state.AllMedia.length == 1 ? true : false : false
                }
              />
            </Upload>
            <Button className="post_btn"
              type="ghost"
              shape="circle"
              onClick={() => this.setState({
                tagsOpen: !this.state.tagsOpen,
                location: false
              })}
              icon={<img src={atIcon} width={20} />}
            />
            <Button
            style={{margin:0,padding:0}}
              className="post_btn"
              type="ghost"
              shape="circle"
              icon={<img src={locationIcon} width={14} />}
              onClick={() => {
                this.setState({
                  tagsOpen: false,
                  location: !this.state.location
                })
              }}
            />
            <Button
              className="post_btn"
              type="ghost"
              shape="circle"
              disabled={this.state.data?.account_plan === "free" && this.state.editortext.length > 1001}
              icon={<img src={sendIcon} width={"100%"} />}
              onClick={() => this.handleSubmit()}
            />
          </Col>
        </Row>
      }
    >
      {this.renderCreatePost()}
    </Modal>
    )
  }
  //editpostpopup
  renderEditPostPopup = () => {
    return (<Modal
      footer={null}
      className="Edit_Modal_Layout"
      bodyStyle={{
        width: "100%",
        height: "100%",
        maxHeight: "60vh",
        minHeight: "40vh",
        overflowY: "auto"
      }}
      centered
      title={
        <Space
          style={{
            width: "100%",
            cursor: "move",
            fontWeight: "bold",
            color: "#414141"
          }}
        >
          Edit the post
        </Space>
      }
      visible={this.state.editPostOpen}
      onCancel={() => this.handleEditPostCancel()}
    >
      {this.rendereditPost()}
    </Modal>
    )
  }

  renderFollowingFollowersModal = () => {
    return (<Modal
      footer={null}
      bodyStyle={{
        width: "100%",
        height: "80vh",
        overflowY: "auto"
      }}
      centered
      title={
        <Space
          style={{
            width: "100%",
            cursor: "move",
            fontWeight: "bold",
            color: "#414141"
          }}
        >
          Followers/Following
        </Space>
      }
      visible={this.state.followersAndFollowingOpen}
      onCancel={() => this.handleFollowersAndFollowingCancel()}
    >
      <FollowersAndFollowing keyValue={this.state.keyValue} />
    </Modal>
    )
  }

  renderPostConnectionDetails = () => {
    let postData = [
      {
        key: "posts",
        count: this.state.profileData?.attributes?.posts_count,
        image: postsImgsIcon,
        description: "UI/Ux Designer Community",
        nav: 1
      },
      {
        key: "connections",
        count: this.state.profileData?.attributes?.friends_count,
        image: connectionsIcon,
        description: "UI/Ux Designer Community",
        nav: '/myNetwork'
      },
      {
        key: "followers",
        count: this.state.profileData?.attributes?.followers_count,
        image: followersIcon,
        description: "UI/Ux Designer Community",
        nav: '/Followers/Following',
        keyValue: "1"
      },
      {
        key: "following",
        count: this.state.profileData?.attributes?.following_count,
        image: followingIcon,
        description: "UI/Ux Designer Community",
        nav: '/Followers/Following',
        keyValue: "2"
      },
      {
        key: "Ratings Received",
        count: this.state.profileData?.attributes?.ratings_received,
        image: ratingsReceivedIcon,
        description: "UI/Ux Designer Community",
        nav: '/Rating',
        keyValue: 2
      },
      {
        key: "Ratings Sent",
        count: this.state.profileData?.attributes?.ratings_given,
        image: ratingSentIcon,
        description: "UI/Ux Designer Community",
        nav: '/Rating',
        keyValue: 1
      },
    ];
    const premiumData = [
      {
        key: "Resume Downloads",
        count: this.state.userAnalytics?.cv_downloaded_users_count,
        image: <FileTextOutlined />,
        description: "UI/Ux Designer Community",
        nav: "/UserAnalytics",
        keyValue: "1"
      },
      {
        key: "Rating Users",
        count: this.state.userAnalytics?.rated_users_count,
        image: <StarOutlined />,
        description: "UI/Ux Designer Community",
        nav: "/UserAnalytics",
        keyValue: "2"
      },
      {
        key: "Review Given",
        count: this.state.userAnalytics?.reviewers_count,
        image: <EditOutlined />,
        description: "UI/Ux Designer Community",
        nav: "/UserAnalytics",
        keyValue: "3"
      },
      {
        key: "Profile Visit",
        count: this.state.userAnalytics?.visited_users_count,
        image: <UserOutlined />,
        description: "UI/Ux Designer Community",
        nav: "/UserAnalytics",
        keyValue: "4"
      },
    ]
    if(this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "premium"){
      postData.push(...premiumData)
    }
    return (
      <Card style={{ borderRadius: 4 }}>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Text style={{ fontSize: 14, fontWeight: "bold" }}>Stats</Text>
        </div>

        <div>
          <List
            className="stats_responsive"
            grid={{ gutter: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 2, xxl: 2 }}
            dataSource={postData}
            renderItem={(item) => (
              <List.Item style={{ cursor: "pointer" }}
                // onClick={() => this.handleFollowersAndFollowingOpen(item.key)}
                onClick={() => item.nav === 1 ?
                  this.setState({ defaultActiveKey: 1 })
                  : item.keyValue ?
                    this.props.history.push({
                      //@ts-ignore
                      pathname: `${item.nav && item.nav}`,
                      state: { keyValue: item.keyValue }
                    })
                    :
                    this.props.history.push({
                      //@ts-ignore
                      pathname: `${item.nav && item.nav}`
                    })
                }
              >
                <List.Item.Meta
                  avatar={
                    <div className={"post-connection-content ant-row-middle"}>
                      <Avatar style={{ padding: "5px" , display:"flex", justifyContent:"center" }} src={item.image} size={40} />
                    </div>
                  }
                  title={<h4 className="post-connection-title" style={{ overflow: "hidden" }}>{item.key.charAt(0).toUpperCase() + item.key.slice(1)}</h4>}
                  description={
                    <h2 className="post-connection-count">
                      <b>{item.count ? item.count : "0"}</b>
                    </h2>
                  }
                  style={{ height: 64, justifyContent: 'center', alignItems: "center", backgroundColor: "rgb(221,230,235)", marginRight: "5px", borderRadius: "4px" }}
                />
              </List.Item>
            )}
          />
        </div>
      </Card>
    );
  };

  showReportModal = () => {
    return (
      <Modal
        width={350}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        // placement="bottomRight"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px" }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Report Post
          </div>
        }
        visible={this.state.isReportIssueVisible}
        onCancel={this.handleCancel}
        footer={[
          <Button size="large" className="report-button" style={{ width: "100%" }} key="report" onClick={this.handleOk}>
            Report
          </Button>,
        ]}
      >
        <Radio.Group onChange={this.onChangeValue} value={this.state.value} buttonStyle="outline">
          <Space direction="vertical">
            <Radio style={{ color: "grey" }} value={1}>
              Fraude or pyramid Marketing
            </Radio>
            <Radio style={{ color: "grey" }} value={2}>
              Promoting/Selling illegal or regulated goods
            </Radio>
            <Radio style={{ color: "grey" }} value={3}>
              Promotes Terrorism
            </Radio>
            <Radio style={{ color: "grey" }} value={4}>
              Discrimination, Harrassment or Bullying
            </Radio>
            <Radio style={{ color: "grey" }} value={5}>
              Child Abuse
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    )
  };

  renderPost = (item: any) => {
    if (this.state.isPostCreateLoading) {
      return <></>;
    }
    return (
      // <>
      //   {JSON.stringify(this.state.followedItems)}
      <Suspense
        fallback={<Card
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin />
        </Card>
        }
      >
        <div style={{ paddingTop: "20px", display: this.state.isPostCreateLoading ? "none" : "block" }}>
          <CustomPost
            menu={{ isCommunity: false }}
            loginUserId={this.state?.loginUser?.id ? this.state?.loginUser?.id : this.state?.userProfile?.account_id}
            handlePostList={this.handlePostList}
            data={item?.attributes}
            handleMenuClick={(e: any) => this.handleMenuClick(e, item.id)}
            isFollowed={this.state.followedItems.includes(item.attributes?.account?.data?.id)}
            toggleFollow={() => this.onClickFollow(item.attributes?.account?.data?.id, this.state.followedItems.includes(item.attributes?.account?.data?.id))}
            AllMedia={this.state.AllMedia}
            isCreatePostVisible={this.state.isCreatePostVisible}
            handleEditPostOpen={(id: any) => this.handleEditPostOpen(id)}
          />
        </div>
      </Suspense>

      // </>
    );
  };

  renderRating = () => {
    return (
      <div>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Text style={{ fontWeight: 'bold' }}>Ratings {this.state.shiftRatings === 1 ? "Received" : "Send"}</Text>
          {/* <Col>
                  <Text style={{marginRight: 20}}>Ratings Received</Text>
                  <Text>Rating Send</Text>
                  </Col> */}
          {this.state.shiftRatings === 2 &&
            <div style={{ width: 185, marginLeft: 50 }}>
              <Input
                placeholder="Search Sent Ratings"
                //@ts-ignore
                prefix={<SearchOutlined />}
                style={{ border: "none", borderRadius: 50, height: 30, background: '#c1dbef' }}
              />
            </div>
          }
          <Radio.Group onChange={this.ratingOnchange} value={this.state.shiftRatings}>
            <Space>
              <Radio value={1}>Ratings Received</Radio>
              <Radio value={2}>Rating Send</Radio>
            </Space>
          </Radio.Group>
        </Row>
        {this.state.shiftRatings === 2
          ?
          <Row style={{ justifyContent: 'space-between', alignItems: 'center', paddingTop: 20 }} >
            <Row style={{ alignItems: 'center' }}>
              <Col>
                <Avatar src={this.state.loginUser.profile_pic ? this.state.loginUser.profile_pic : require("../../assets/image_profile.png")} size={40} style={{ backgroundColor: "grey" }} />
              </Col>
              <Row justify="center" style={{ paddingTop: "0px", paddingLeft: 10, paddingBottom: 10 }}>
                <p style={{ width: "100%", marginBottom: "0", fontSize: 12, fontWeight: 600 }}><Text type="secondary"> you have rated</Text> Jason Price<Text type="secondary"> as</Text> leader</p>
                <br />
                <Text style={{ width: "100%", fontSize: 12 }} type="secondary">13 days ago</Text>
              </Row>
            </Row>
            <Button type="primary" ghost style={{ borderRadius: 30 }} onClick={() => { this.props.navigation.navigate("RatingSent") }}>View Ratings</Button>
          </Row>
          :
          <Row style={{ justifyContent: 'space-between', alignItems: 'center', paddingTop: 20 }} >
            <Row style={{ alignItems: 'center' }}>
              <Col>
                <Avatar src={this.state.loginUser.profile_pic ? this.state.loginUser.profile_pic : require("../../assets/image_profile.png")} size={40} style={{ backgroundColor: "grey" }} />
              </Col>
              <Row justify="center" style={{ paddingTop: "0px", paddingLeft: 10, paddingBottom: 10 }}>
                <p style={{ width: "100%", marginBottom: "0", fontSize: 12, fontWeight: 600 }}>Jason Price <Text type="secondary"> has rated you as</Text> Google</p>
                <br />
                <Text style={{ width: "100%", fontSize: 12 }} type="secondary">13 days ago</Text>
              </Row>
            </Row>
          </Row>
        }
        <Divider />
      </div>
    )
  };

  renderPremiumModal = () => {
    return (
      <Modal
        visible={this.state.premiumModal}
        onCancel={() => this.setState({
          premiumModal: false
        })}
        width={350}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        // placement="bottomRight"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px" }}
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            Premium Account
          </div>
        }
        footer={null}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-evenly", flexDirection: "column", gap: "20px" }}>
          <Text>Your account have already premium subscription</Text>
          <Button size="large" className="report-button" style={{ width: "90%", fontSize: "14px" }} key="report" onClick={() => {
            this.setState({
              premiumModal: false
            })
          }}>
            Cancel
          </Button>
        </div>

      </Modal>
    )
  }

  renderReviews = () => {
    // console.log("responseJson.datareviews", this.state.myReviews)
    const getDomainSkills = (domain_skills: any) => {
      if (
        isEmpty(domain_skills)
      )
        return "";
      const domainSkillsPresent = domain_skills;
      const domainSkillsStr = domainSkillsPresent.join(", ");
      // console.log("domainSkillsStr = ", domainSkillsStr);
      return domainSkillsStr;
    }
    return (
      <div>
        {this.state.myReviews &&
          this.state.myReviews.length !== 0 ? this.state.myReviews?.map((rev: any) =>
            <div className="follow_main" key={rev.id}>
              <div className="review_content">
                <div className='d-f'>
                  <div className="follow_profile">
                    <Avatar src={rev?.attributes?.reviewer?.data?.attributes ? rev?.attributes?.reviewer?.data?.attributes?.photo :
                      createProfile
                    } size={40} />
                    {
                      rev.attributes?.reviewer ?
                        <div style={{ marginLeft: 10 }}>
                          <Text style={{ fontSize: 16, fontWeight: "bold", color: '#4B4B4B' }}>
                            {rev.attributes?.reviewer?.data?.attributes?.first_name}
                          </Text>
                          <div>
                            {rev.attributes?.reviewer?.data?.attributes?.designation && <Text style={{ fontWeight: 500, color: '#4f4f4f', fontSize: 14 }}>
                              {rev.attributes?.reviewer?.data?.attributes?.designation?.title}
                              <span style={{ fontWeight: 500, color: '#D4D4D4', fontSize: 14 }}> at </span>
                              {rev.attributes?.reviewer?.data?.attributes?.designation?.company}
                              <span style={{ fontWeight: 500, color: '#D4D4D4', fontSize: 14 }}> | </span>
                            </Text>}
                            {rev.attributes?.reviewer?.data?.attributes?.designation?.profile?.data?.attributes?.domain_skills ?
                              <Text style={{ fontWeight: 400, color: '#4f4f4f', fontSize: 14 }}>
                                {" "}
                                {getDomainSkills(rev.attributes?.reviewer?.data?.attributes?.designation?.profile?.data?.attributes?.domain_skills)}
                              </Text> : null
                            }
                          </div>
                        </div>
                        : <div style={{ marginLeft: 10 }}>
                          <Text style={{ fontSize: 16, fontWeight: "bold", color: '#4B4B4B' }}>
                            Anonymous
                          </Text>
                        </div>
                    }

                  </div>
                  <div className=''>
                    <span style={{ fontWeight: 400, color: '#D4D4D4', fontSize: 14 }}>{moment(rev.attributes?.created_at).format("Do")} {moment(rev.attributes?.created_at).format("MMMM")}, {moment(rev.attributes?.created_at).format("YYYY")}</span>
                  </div>
                </div>
                <div style={{ marginTop: 10 }}>
                  <h4>{rev.attributes?.title}</h4>
                  <p>{rev.attributes?.description}</p>
                </div>
              </div>
            </div>
            //   <div style={{ paddingLeft: "20px", paddingTop: 10, backgroundColor: 'white', paddingBottom: 10 }}>
            //   <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            //     <Row style={{ alignItems: 'center' }}>
            //       <Col>
            //         <Avatar src={this.state.loginUser.profile_pic ? this.state.loginUser.profile_pic : require("../../assets/image_profile.png")} size={40} style={{ backgroundColor: "grey" }} />
            //       </Col>
            //       <Row justify="center" style={{ paddingTop: "10px", paddingLeft: 10, paddingBottom: 10 }}>
            //         <p style={{ width: "100%", marginBottom: "0", fontSize: 12, fontWeight: 600 }}>{review?.attributes?.reviewer?.data?.attributes?.first_name} <Text type="secondary"> has reviewed you</Text></p>
            //         <br />
            //         <Text style={{ width: "100%", fontSize: 12 }} type="secondary">{review.attributes.created_at}</Text>
            //       </Row></Row>
            //     <Col style={{ marginRight: 20 }}>
            //       <Text style={{ width: "100%", fontSize: 12 }} type="secondary">{review.attributes.created_at}</Text>
            //     </Col>
            //   </Row>
            //   <Row>
            //     <Text style={{ fontSize: 14, fontWeight: 500 }}>{review.attributes.title}</Text>
            //     <br/>
            //     <Text>{review.attributes.description}</Text>
            //   </Row>
            // </div>
          )
          : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }

      </div>
    )
  };
  rendetvideoCloseOutlined = () => {
    return <CloseOutlined />
  }

  renderImage = (id: any, isFullHeight: boolean) => {
    if (this.state.AllMedia[id]?.content_type) {
      return (
        <Card style={{ width: 'auto', padding: 3, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px' }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.deleteItem(id)} icon={<CloseOutlined />} />
          <Image src={this.state.AllMedia[id].url} height={isFullHeight ? 380 : 180}
            style={{ borderRadius: '4px', backgroundColor: 'grey' }}
            wrapperStyle={{ backgroundSize: 'cover' }} />
        </Card>
      )
    } else {
      return (
        <Card style={{ width: 'auto', padding: 3, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px' }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.onRemoveImage(id)} icon={this.rendetvideoCloseOutlined()} />
          <Image src={this.state.AllMedia[id].filePath} height={isFullHeight ? 380 : 180}
            style={{ borderRadius: '4px', backgroundColor: 'grey' }}
            wrapperStyle={{ backgroundSize: 'cover' }} />
        </Card>
      )
    }
  }

  renderVideo = (id: any, isFullHeight: boolean) => {
    if (this.state.AllMedia[id]?.content_type) {
      return (
        <Card style={{ width: '100%', padding: 3, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px', backgroundColor: "#FAFAFA" }} >
          <Button style={{ backgroundColor: 'grey', right: 0, top: -5, position: 'absolute', zIndex: 100, borderRadius: 20 }} size='small' onClick={() => this.deleteItem(id)} icon={this.rendetvideoCloseOutlined()} />
          <ReactPlayer url={this.state.AllMedia[id]?.url}
            controls
            height={isFullHeight ? 380 : 180}
            width={'98%'}
            style={{ borderRadius: '4px', backgroundColor: "#FAFAFA" }} />
        </Card>
      )
    } else {
      return (
        <Card style={{ width: '100%', padding: 3, height: isFullHeight ? 400 : 200, borderWidth: 0 }} bodyStyle={{ padding: '0px', backgroundColor: "#FAFAFA" }} >
          <Button style={{ backgroundColor: 'grey', right: 0, position: 'absolute', zIndex: 100, top: -5, borderRadius: 20 }} size='small' onClick={() => this.onRemoveImage(id)} icon={<CloseOutlined />} />
          <ReactPlayer url={this.state.AllMedia[id].filePath} controls
            height={isFullHeight ? 380 : 180}
            width={'98%'}
            style={{ borderRadius: '4px', backgroundColor: "#FAFAFA" }} />
        </Card>
      )
    }
  }
  checkMedia = (id: any, isFullHeight: boolean) => {
    if (this.state.AllMedia[id]?.content_type) {
      console.log(this.state.AllMedia[id],)
      return this.state.AllMedia[id].content_type.slice(0, 5) == "image" ? this.renderImage(id, isFullHeight) : this.renderVideo(id, isFullHeight);
    } else {
      return this.state.AllMedia[id].type.slice(0, 5) == "video" ? this.renderVideo(id, isFullHeight) : this.renderImage(id, isFullHeight);
    }
  }

  renderMedia = (imgArray: any) => {
    switch (imgArray.length) {
      case 0:
        return null;
      case 1:
        return (
          <Row style={{ padding: 5, width: '100%' }} >
            {/* <Col span={24}  > */}
            {this.checkMedia(0, false)}
            {/* </Col> */}
          </Row>
        )
      case 2:
        return (
          <Row className="flex-row">
            <Col span={12} >{this.checkMedia(0, false)}</Col>
            <Col span={12} >{this.checkMedia(1, false)}</Col>
          </Row>
        )
      case 3:
        return (
          <Row className="flex-row"  >
            <Col span={12}>{this.checkMedia(0, true)}</Col>
            <Col style={{ flexDirection: 'column' }} span={12} >
              {this.checkMedia(1, false)}
              {this.checkMedia(2, false)}
            </Col>
          </Row>
        )
      case 4:
        return (
          <div >
            <Row style={{ width: '100%' }}>
              <Col span={12} >{this.checkMedia(0, false)}</Col>
              <Col span={12} >{this.checkMedia(1, false)}</Col>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col span={12} >{this.checkMedia(2, false)}</Col>
              <Col span={12} > {this.checkMedia(3, false)}</Col>
            </Row>
          </div>
        )
      default:
        return (
          <div >
            <Row style={{ width: '100%' }}>
              <Col span={12}>{this.checkMedia(0, false)}</Col>
              <Col span={12}>{this.checkMedia(1, false)}</Col>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col span={12}>{this.checkMedia(2, false)}</Col>
              <Col span={12}> {this.checkMedia(3, false)}
                <Button size='large' type='text' style={{ marginTop: -100 }} >{`+${this.state.AllMedia.length - 3} More`} </Button>
              </Col>
            </Row>
          </div>
        )
    }
  };

  //oncopylinkforinvitefriends
  oncopyInviteFrdiends = () => {
    this.setState({ isModalInviteFriends: false })
    navigator.clipboard.writeText("https://appliedapp.page.link/welcome")
    message.info("Url Copied")
  }


  //@ts-ignore
  onEmojiClick = (event, emojiObject) => {
    const ref = this.contentRef.current
    ref.focus()
    const start = this.state.createPostDescription.substring(0, ref.selectionStart)
    const end = this.state.createPostDescription.substring(ref.selectionStart)
    const content = start + emojiObject.emoji;
    this.setState({ createPostDescription: content })
    ref.selectionEnd = start.length + emojiObject.emoji.length
  };

  //@ts-ignore
  onChange = (value) => {
    let data = this.state.createPostDescription + value

    this.setState({ createPostDescription: value });
  };

  onChangeText = (editorState: any) => {
    this.setState({ createPostDescription: editorState });
    const contentState = editorState.getCurrentContent();
    // console.log(contentState, "contentState")
    const raw = convertToRaw(contentState);
    if (raw?.blocks[0]?.text.trim().length > 0) {
      this.setState({
        bodyText: false
      })
    }
    const name = raw?.blocks.map((line: any) => line?.text);
    console.log(name)
    var stringValue = ""
    name.map((item: any) => {
      stringValue = stringValue + item + "\n"
      return stringValue
    })
    this.setState({ editortext: stringValue })
    // console.log(raw?.blocks[0]?.text, "text");

    const raws = convertToRaw(contentState);
    let mentionedUsers = [];
    for (let key in raws.entityMap) {
      const ent = raws.entityMap[key];
      if (ent.type === "mention") {
        mentionedUsers.push(ent.data.mention);
      }
    }
    const mentiontag = mentionedUsers.map(function (followersItem: any) {
      return followersItem?.userId
    })
    let t = this.state.tagged_connection_ids;
    t.push(...mentiontag)
    this.setState({ tagged_connection_ids: t });
  };

  onOpenChange = (_open: any) => {
    this.setState({
      open: _open
    });
  };
  //editchange
  onEditChange = (value: any) => {
    console.log(value, "editvalue")
    this.setState({ createPostDescription: value });
    console.log(this.state.createPostDescription, "editData value")
  };
  //tags
  onSelectTags = (option: any) => {
    let mention = this.state.selectTags;
    mention.push({ value: option.value, key: option.key });
    this.setState({ selectTags: mention })
  }

  onChangeTags = (value: any) => {
    // console.log(value.split("@").trim(), value, "value");
    const data = value.split("@").filter((item: string) => {
      if (item) {
        return item.trim();
      } else {
        return null;
      }
    })
    let getMention = getMentions(value);
    console.log(data);
    this.setState({
      getMentionsArray: getMention,
      getMentionsArrayList: data
    });
  }

  //newsSubscribe
  submitNewsSubscribe = () => {
    this.NewsletterSubscribe(this.state.newsEmail)
  }
  //newsUnSubscribe
  submitNewsUnSubscribe = () => {
    this.NewsletterUnSubscribe()
    this.setState({ isModalNewsletter: false })
  }

  leaderRatingScore = (rating: any) => {
        if (rating?.rating) {
      return Math.round(this.state.data?.rating?.data?.attributes?.rating['Leader'][this.state.data?.rating?.data?.attributes?.rating?.['Leader']?.length-1]?.score)
    } else {
      return 0
    }
  }

  teamRatingScore = (rating: any) => {
      if (rating?.rating) {
      return Math.round(this.state.data?.rating?.data?.attributes?.rating['Team'][this.state.data?.rating?.data?.attributes?.rating?.['Team']?.length-1]?.score)
    } else {
      return 0
    }
  }

  customerRatingScore = (rating: any) => {
    if (rating?.rating) {
      return Math.round(this.state.data?.rating?.data?.attributes?.rating['Customer'][this.state.data?.rating?.data?.attributes?.rating?.['Customer']?.length-1]?.score)
    } else {
      return 0
    }
  }

  communityRatingScore = (rating: any) => {
    if (rating?.rating) {
      return Math.round(this.state.data?.rating?.data?.attributes?.rating['Community'][this.state.data?.rating?.data?.attributes?.rating?.['Community'].length-1]?.score)
    } else {
      return 0
    }
  }

    renderModel =() =>{
    return  <Modal
                width={375}
                style={{
                    borderRadius: "4px",
                }}
                bodyStyle={{
                  paddingBottom: '16px',
                  textAlign: 'center',                  
                  marginTop: '12px',
              }}            
                onCancel={()=>{this.setState({isModelOpen:false})}}
                footer={null}
                visible={this.state.isModelOpen}
            
                
           >
               <>        
                    <img  
                       style={{
                          paddingBottom: '16px',
                           fontSize: '44px',
                           height:"60px",
                           width:"60px",
                           color: '#00536c',                         
                       }}  
                       src={infoIcon}      
                   />
                   <Typography style={{  textTransform: "capitalize", fontWeight: 'bold',marginBottom:"8px",fontSize: 18 }}>
                       Please upgrade to premium account
                   </Typography>
                   <div style={{ display:"flex",gap:"8px",flexDirection:"column", }}>
                   <Button style={{background:"#00536c",color:"white",padding:"4px",borderRadius:"20px",height:"40px"}} onClick={()=>this.goToUpgrade()} >Upgrade</Button>
                   <Button  style={{height:"40px",padding:"4px",borderRadius:"20px",color:"#00536c",}} onClick={()=>this.setState({isModelOpen:false})} >Cancel</Button>
                   </div>       
               </>
            </Modal>
   }

   checkPremiumPlan = ()=>{
    if(this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan !== "premium"){
       this.setState({isModelOpen:true})
    }else{
      this.props.history.push({
        pathname: "/myProfile/updateQuestionnaire",
      })
    }    
   }

  handleClickOption = () => {
    if (this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan == "free") {
      this.goToUpgrade()
    } else {
      this.setState({
        premiumModal: true
      })
    }
  }
  getItemScoreColor = (item: { score: number }) => {
    return item?.score < 79 ? "#3a87a8" : "#3c9b83"
  }
  getItemScoreForColor = (item: { score: number }) => {
    return item?.score < 59 ? "#b24040" : this.getItemScoreColor(item)
  }
  getItemScoreValue = (item: any) => {
    return item.score === null ? 0 :  Math.trunc(item.score);
  }
  renderProfileQuestionnaireTabsWeb = () => {
    if (this.state.data?.rating) {
      return (
        <>
          <Col xs={24} md={24} xl={24} lg={24} >
            <div style={{ height: '100%', width: '100%', background: '#fff', }}>
              <div style={{ background: "rgb(245, 245, 245)", padding: '8px 24px' }}>
                <h3 style={{ fontSize: 14, color: "#1890ff", }}>{this.state.updateQuestionnaire?.ratingName}</h3>
              </div>
              {this.state.updateQuestionnaire?.data?.slice(0, this.state.updateQuestionnaire?.data?.length - 1).map((item: any) => {
                return (
                  <div key={item} style={{ padding: '0px 24px', display: 'flex', alignItems: 'center', marginTop: 16 }}>

                    <Box display="inline-flex" position="relative"
                    >
                      <Progress
                        type="circle"
                        strokeColor={this.getItemScoreForColor(item)}
                        percent={Math.round(item.score)}
                        strokeWidth={9}
                        width={50}
                        className="rating-progress"
                        style={{
                          cursor: 'pointer',
                          fontSize: 11,
                          fontWeight: 600
                        }}
                      />
                      {item?.description &&
                            <Tooltip
                            title={ <div
                              dangerouslySetInnerHTML={{
                                __html: item?.description.replace(/\n/g, '<br />')
                              }}
                            />}
                             >
                                <ExclamationCircleOutlined
                                    style={{ marginLeft: "0.4rem", cursor: "pointer", color: "#919191"}}
                                />
                            </Tooltip>}
                    </Box>
                    <h3 style={{ marginLeft: '20px', color: "rgba(0, 0, 0, 0.6)", fontSize: 14, fontWeight: 'bold' }}>{item?.name}</h3>
                  </div >
                )
              })}
              <div style={{ textAlign: "end" }}>
                {this.state.accountDetails?.attributes?.account?.data?.attributes?.account_plan == "premium" && <Button
                  data-testid="edit_questionnaire"
                  htmlType="submit"
                  style={{ margin: "10px 15px 15px 15px" }}
                  className="model-button-copy"
                  onClick={() => this.props.history.push({ pathname: '/myProfile/updateQuestionnaire', state: { title: this.state.updateQuestionnaire?.ratingdata } })}
                >
                  Edit Questionnaire
                </Button>}
              </div>
            </div>
          </Col >
        </>
      )
    } else {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    }
  }
  // Customizable Area End 
  closeHandler = (e: any) => {
    e.stopPropagation();
    this.setState({ drawerVisible: !this.state.drawerVisible })
  }

  NewsletterSubscribeModal = () => {
    const newletterTitle = this.state.isNewsLetterSubscription ? "UnSubscribe to our Newsletter" : "Subscribe to our Newsletter"
    return (
      <Modal title={this.state.isNewsLetterSubscription === null ? null : newletterTitle}
        footer={null}
        data-testid="newsLetterSubscription"
        onOk={() => this.setState({ isModalNewsletter: false })}
        onCancel={() => this.setState({ isModalNewsletter: false })}
        visible={this.state.isModalNewsletter} >
        {this.state.isNewsLetterSubscription === null ? <Spin /> :
          <>
            {this.state.isNewsLetterSubscription ?
              <>
                <p style={{ fontSize: 18, color: '#d9d9d9' }}>You have already Subscribed!</p>
                <Button
                  htmlType="submit"
                  style={{ width: 180, paddingLeft: 35, textAlign: "center" }}
                  className="model-button-copy"
                  data-testid="unSubscribe"
                  onClick={() => this.submitNewsUnSubscribe()}
                >
                  UnSubscribe
                </Button>
              </>
              :
              <>
                {
                  this.state.newsubscribe
                    ? (<>
                      <p style={{ fontSize: 16, color: '#B8B8B8' }}>Thank You!</p>
                      <p style={{ fontSize: 16, color: '#B8B8B8' }}>Before receiving Newsletter from applied, you need to confirm your email address.</p>
                      <p style={{ fontSize: 16, color: '#B8B8B8' }}>Please check your mailbox.</p>
                    </>)
                    : (<>
                      <p style={{ fontSize: 16, color: '#B8B8B8' }}>Get handpicked articles once a month to get latest news from your industry</p>
                      <Form
                        name="basic"
                        // initialValues={{ anonymous: false }}
                        onFinish={(value) => console.log(value, "value of subscribe")}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout='inline'
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "baseline",
                          justifyContent: "space-between"
                        }}
                        data-testid="form_basic"
                      >
          <Form.Item
            rules={[{ required: true, message: "Required field" },{ type: 'email', message: 'Please enter a valid email address' },]}
            name="email"
             data-testid="form_item"
            getValueProps={() => {
              return this.state.newsEmail
            }}
          >
            <Input
              name="email"
              value={this.state.newsEmail}
              type="email"
              size="large"
              style={{ width: 285,border:"1px solid #d9d9d9", height:"50px",color:"#4080c0",borderRadius:"50px" }}
              placeholder="Enter email address"
              onChange={(e) => this.setState({ newsEmail: e.target.value })}
            />
          </Form.Item>

                        <Form.Item>
                          <Button
                            htmlType="submit"
                            data-testid="subcribButton"
                            style={{ width: 150, paddingLeft: 35 }}
                            className="model-button-copy"
                            onClick={() => this.submitNewsSubscribe()}
                          >
                            Subscribe
                          </Button>
                        </Form.Item>
                      </Form>
                    </>)
                }
              </>}
          </>
        }
      </Modal>)
  }
  downloadButtonText = () => {
    return this.state.downloadResumeLoading ? <Spin /> : <span style={{ padding: '0px 0px', fontSize: "90%" }}>Download Resume</span>
  }
  render() {
    const progressCircleBarData = [
      {
        id: 1,
        title: "Leadership View",
        ratingName: "Leadership Rating",
        subTitle: "Leadership View",
        value: this.leaderRatingScore(this.state.data),
        size: 120,
        data: this.state.data?.rating?.data?.attributes?.rating['Leader'],
        ratingdata: "Leader"
      },
      {
        id: 2,
        title: "Team View",
        ratingName: "Team Rating",
        subTitle: "Team View",
        value: this.teamRatingScore(this.state.data),
        size: 120,
        data: this.state.data?.rating?.data?.attributes?.rating['Team'],
        ratingdata: "Team"
      },
      {
        id: 3,
        title: "Customer View",
        ratingName: "Customer Rating",
        subTitle: "Customer View",
        value: this.customerRatingScore(this.state.data),
        size: 120,
        data: this.state.data?.rating?.data?.attributes?.rating['Customer'],
        ratingdata: "Customer"
      },
      {
        id: 4,
        title: "Community View",
        ratingName: "Community Rating",
        subTitle: "Community View",
        value: this.communityRatingScore(this.state.data),
        size: 120,
        data: this.state.data?.rating?.data?.attributes?.rating['Community'],
        ratingdata: "Community"
      }
    ];
    const handleChangeTab = (value: any) => {
      this.setState({ defaultActiveKey: value });
    }
    const url = "https://appliedapp.page.link/welcome"
    return (
      <div style={{ backgroundColor: "#f6f6f6" }}>
        {this.redirectUser()}
        {/* <CustomHeader {...this.props} dotVisible={false} /> */}
        <Row style={{ paddingTop: "15px", marginBottom: 7, marginLeft: 16 }}>
          <Text
            className="textlabel_subheadingText"
          >
            My Profile
          </Text>
        </Row>
        <Row style={{ backgroundColor: "#f6f6f6" }}>
          <Col span={6} xs={24} sm={24} md={24} lg={7} xl={8} xxl={6} style={{ paddingLeft: "16px", paddingRight: '16px' }}>
            <p className="textlabel_headingText" >My Profile</p>
            {this.renderUSerProfile()}
            {this.renderAboutContainer()}
            {this.renderPostConnectionDetails()}
            <Card bodyStyle={{ textAlign: "center" }}
              style={{ borderRadius: 4 }}
            >
              <Row
                // style={{ display: "flex", alignItems: "center", , flexDirection: "row", flexWrap: "wrap", gap: "10px" }}
                // gutter={[10, 10]}
                style={{ justifyContent: "space-between" }}
              >
                {/* <a 
                 href={require("../../assets/dummyPDF.pdf")}
                 download="dummyPDF"
                > */}

                <Button htmlType="submit" shape="round" size={"large"}
                  // href={url}
                  className="MyBusiness_Download" style={{ paddingTop: "0px", paddingBottom: '0px' }}
                  onClick={() => this.downloadResumeWithAxios()}
                >
                  {this.downloadButtonText()}
                </Button>

                {/* </a> */}
                <Button htmlType="submit" shape="round" size={"large"}
                  className="MyBusiness_Update" style={{}}
                  onClick={() => this.checkPremiumPlan()}
                >
                  <span style={{ padding: '0px 0px', fontSize: "90%" }}>Update Questionnaire</span>
                </Button>
                <Modal
                  title="Invite Friends"
                  footer={null}
                  onOk={() => this.setState({ isModalInviteFriends: false })}
                  onCancel={() => this.setState({ isModalInviteFriends: false })}
                  visible={this.state.isModalInviteFriends} >
                  {/* <p style={{ fontSize: 18, color: '#d9d9d9' }}>Invite your Friends</p> */}
                  <Row style={{ justifyContent: "space-around", marginLeft: 20, marginRight: 20 }} >
                    <LinkedinShareButton
                      url={url}
                      title={`${this.state?.updatedDetails?.full_name} has invited you to the appliedview application`}
                      summary="Description of the shared page"
                      source="Source of the content (e.g. your website or application name)"
                    >
                      <Col>
                        {/* @ts-ignore */}
                        <div className="model-img">
                          <img src={linkdinIcon} alt="img" />
                        </div>
                        <p style={{ fontSize: 18, color: '#d9d9d9' }} >Linkedin</p>
                      </Col>
                    </LinkedinShareButton>

                    <WhatsappShareButton
                      url={url}
                      title={`${this.state?.updatedDetails?.full_name} has invited you to download the appliedview application`}
                      separator=""
                    >
                      <Col>
                        {/* @ts-ignore */}
                        <div className="model-img">
                          <img src={whatsappIcon} alt="img" />
                        </div>
                        <p style={{ fontSize: 18, color: '#d9d9d9' }} >Whatsapp</p>
                      </Col>
                    </WhatsappShareButton>

                    <FacebookShareButton
                      url={url}
                      quote={`${this.state?.updatedDetails?.full_name} has invited you to the appliedview application`}
                      hashtag=""
                    >
                      <Col>
                        {/* @ts-ignore */}
                        <div className="model-img">
                          <img src={facebookIcon} alt="img" />
                        </div>
                        <p style={{ fontSize: 18, color: '#d9d9d9' }} >facebook</p>
                      </Col>
                    </FacebookShareButton>

                    <EmailShareButton
                      url={url}
                      subject={`${this.state?.updatedDetails?.full_name} has invited you to the appliedview application`}
                      body=""
                      separator=""
                    >
                      <Col>
                        {/* @ts-ignore */}
                        <div className="model-img">
                          <img src={gmailIcon} alt="img" />
                        </div>
                        <p style={{ fontSize: 18, color: '#d9d9d9' }} >Gmail</p>
                      </Col>
                    </EmailShareButton>
                  </Row>
                  <Row style={{ justifyContent: "space-around", marginBottom: 10 }} >
                    <Col style={{ width: 300 }}><Input placeholder={url} className="model-input" value={url} /></Col>
                    <Col style={{ width: 150 }}><Button className="model-button-copy" onClick={this.oncopyInviteFrdiends}>Copy Url</Button></Col>
                  </Row>
                </Modal>
                {this.NewsletterSubscribeModal()}

                {!this.state.isModalInviteFriends && !this.state.isModalNewsletter &&  <Dropdown
                  className="MyProfile_Dropdown_css"
                  overlay={
                    <ul className="dropdown" style={{ listStyleType: "none" }}>
                       <li><a onClick={() => this.props.history.push({
                        pathname: '/edit/profile/personal', state: this.state.userProfile
                      })} >Edit Profile</a></li>
                      <li><a data-testid="Premium_Account" onClick={this.handleClickOption}>Premium Account</a></li>
                      <li><a onClick={() => this.props.history.push({
                        //@ts-ignore
                        pathname: '/Rating'
                      })} >My Ratings</a></li>
                      <li><a onClick={() => this.props.history.push({
                        //@ts-ignore
                        pathname: '/Followers/Following'
                      })} >Followers/Following</a></li>
                      <li><a onClick={() => this.props.history.push('/MySaved/Posts')} >My Saved Posts</a></li>
                      <li><a onClick={() => this.props.history.push('/hide/Posts')} >My Hidden Posts</a></li>
                      <li><a onClick={() => this.props.history.push({
                        //@ts-ignore
                        pathname: '/upcoming/anniversaries'
                      }
                      )}>Anniversaries</a></li>
                      <li><a data-testid="NewsLetterSubscription" onClick={() => this.setState({ isModalNewsletter: true })}>Newsletter Subscription</a></li>
                      <li><a onClick={() =>
                        this.setState({ isModalInviteFriends: true })
                      }
                      >Invite Friends</a></li>
                    </ul>
                  } placement="bottomRight" trigger={['click']}>
                  <a onClick={e => e.preventDefault()}
                  >
                    <Space>
                      <img src={three_dot} className="Dropdown_image_myProfile" />
                    </Space>
                  </a>
                </Dropdown>}

              </Row>
            </Card>
            <button
              className="create-new-post"
              onClick={() => this.handlecreatePostOpen()}>Create New Post</button>
          </Col>
          <Col span={12} xs={24} sm={24} md={24} lg={17} xl={14} xxl={14}>
            {
              this.state.pledgeTracking?.completion_percent && this.state.pledgeTracking.completion_percent < 100 && (
                <div style={{ padding: '0px 0px 10px' }}>
                  {this.renderPledgeTracking()}
                </div>
              )
            }
            <Tabs
              activeKey={`${this.state.defaultActiveKey}`}
              centered={true}
              size="large"
              tabBarStyle={{
                color: "#919191",
                backgroundColor: "#dee5eb",
                height: "3rem"
              }}
              style={{
                borderRadius: "3px",
                backgroundColor: "#f1f0f5",
                paddingBottom: 13
              }}
              onChange={(e) => handleChangeTab(e)}
            >
              <TabPane
                tab="Posts"
                key="1"
                style={{ width: "96%", height: "100%", margin: "auto" }}
              >
                <List dataSource={this.state.postList} className="home-posts" renderItem={this.renderPost} />
                {this.state.postList?.length > 0 && this.state.showLoadMore && <> {this.state.showLoadMoreLoading ? <Spin /> : <div
                  style={{
                    cursor: 'pointer',
                    marginTop: "10px"
                  }}
                  onClick={async () => {
                    await this.setState({
                      perpageCountForPost: this.state.perpageCountForPost + 1
                    })
                    this.getHomeFeedPosts()
                  }}>Load more post</div>
                }
                </>
                }
              </TabPane>
              <TabPane
                tab="Ratings"
                key="2"
                style={{
                  width: "96%",
                  height: "100%",
                  margin: "auto"
                }}
              >
                <Card
                  data-testid="rating_tab"
                  className="rating_receive"
                >
                  <Row gutter={[16, 16]}>
                    {
                      progressCircleBarData.map((item: any, index: number) => {
                        return (<Col
                          xs={{ span: 24 }}
                          md={{ span: 6 }}
                          sm={{ span: 12 }}
                          lg={{ span: 6 }}
                          key={item.id}
                          style={{ backgroundColor: "#f1f0f5", display: 'flex', justifyContent: 'center' }}
                        >
                          <div
                            style={{
                              cursor: "pointer",
                              background: "white",
                              borderRadius: '4px',
                              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                              width: "248px",
                              height: "244px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            data-testid={`view_box${index}`}

                            onClick={() => {
                              this.setState({
                                updateQuestionnaire: { ...item }
                              })
                            }}
                          >
                            <ProfileRatingViewsPercentage
                              title={item.title}
                              value={item.value}
                              subTitle={item.subTitle}
                              color={item?.value < 59 ? "#b24040" : item?.value < 79 ? "#3a87a8" : "#3c9b83"}
                              size={item?.size}
                            />
                          </div>
                        </Col>)
                      })
                    }
                    {this.state.data?.rating &&
                      <Col
                        xs={{ span: 24 }}
                        lg={{ span: 24 }}
                        style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
                      >
                        <div
                          style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
                        >
                          <Title
                            level={5}
                            style={{ fontSize: "14px", color: "#414141" }}
                          >
                            Applied View
                          </Title>
                          <Title
                            level={5}
                            style={{ fontSize: "13px", color: "#414141" }}
                          >
                            Applied Score: {`${Math.round(this.state.data?.rating?.data?.attributes?.rating['Applied View Rating'][this.state.data?.rating?.data?.attributes?.rating?.['Applied View Rating'].length-1]?.score)}%`}
                          </Title>
                        </div>
                        <Card
                          style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                        >
                          {
                            this.state.data?.rating?.data?.attributes?.rating['Applied View Rating']?.map((item: any, id: number) => {
                              if (item.name) {
                                return (
                                  <div key={id} onClick={() => {

                                  }}>
                                    <ProfileRatingAppliedProgressBar
                                      key={id}
                                      description={item.description}
                                      title={item.name}
                                      value={this.getItemScoreValue(item)}
                                      color={this.getItemScoreForColor(item)}
                                    />
                                  </div>
                                )
                              } else {
                                return null
                              }
                            })
                          }


                        </Card>
                      </Col>
                    }
                    {this.state.updateQuestionnaire?.data && this.renderProfileQuestionnaireTabsWeb()}
                  </Row>
                </Card>
                {/* :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                } */}
              </TabPane>
              <TabPane
                tab="Reviews"
                key="3"
                style={{ width: "96%", height: "100%", margin: "auto" }}
              >
                {this.renderReviews()}
              </TabPane>
            </Tabs>
            {/* </Card> */}
            {this.showReportModal()}
          </Col>
          {this.renderFollowingFollowersModal()}
          {this.renderCreatePostPopup()}
          {this.renderEditPostPopup()}
          {this.renderPremiumModal()}
        </Row>
        {this.renderModel()}
      </div >
    );
  }
}
// Customizable Area Start
// Customizable Area End
