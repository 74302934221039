export const invite_mail = require("../assets/mail.png");
export const invite_sms = require("../assets/sms.png");
export const invite_whatsapp = require("../assets/whatsapp.png");
export const invite_friend = require("../assets/invite_friend.png");
export const linkedin = require("../assets/linkedin.png");
export const gmail = require("../assets/gmail.png");
export const facebook = require("../assets/facebook.png");
export const logo = require("../../user-profile-basic/assets/logo1.png")
export const style = require("../../social-media-account/src/login.module.css");
export const NETWORK_PROFILE_ING = require("../assets/createProfile.png");
export const successImg = require("../assets/success.jpeg");
export const failedImg = require("../assets/failed.jpeg");
export const acceptIcon = require("../assets/accept.png");
export const rejectIcon = require("../assets/reject.png");